export type Auth = {
  user: any;
  username: string;
  role: string;
  isActive: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
};

export type AuthStoreAction =
  | { type: 'auth'; auth: Auth }
  | { type: 'updateCurrency'; currency: string };

export const AUTH_INIT = {
  user: {},
  username: '',
  role: '',
  isActive: true,
  isAuthenticated: false,
  isLoading: true,
};
