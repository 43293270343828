export type Player = {
  id: string;
  address: string;
  affiliate_code: string;
  affiliate_user: string;
  balance: string;
  balance_last_update: string;
  cab_cust_ref: string;
  city: string;
  country: string;
  district: string;
  credits: number;
  email: string;
  failed_login_attempts: number;
  fraud_code: number;
  gender: string;
  lastname: string;
  login: string;
  name: string;
  password: string;
  phone: string;
  register_date: string;
  state: string;
  test_user: number;
  zipcode: string;
};

export type GamelogRecord = {
  id: string;
  tableId: string;
  bet: number;
  date: string;
  game: GameType;
  profit: number;
};
export type GameTransaction = {
  _id: string;
  user: string;
  amount: number;
  totalBet: number;
  amountType: string;
  game: string;
  gamelog: string;
  tableId: string;
  createdAt: string;
};

export interface BlackjackGamelog {
  balance: string;
  bet: string;
  cards: string;
  dealer_name: string;
  evaluation1: number;
  evaluation2: number;
  hand: number;
  id_game: string;
  id_game_log: number;
  id_table: number;
  insurance: string;
  is_bet_behind: number;
  is_blackjack: number;
  is_busted: number;
  is_dealer: number;
  is_doubledown: number;
  is_playing: number;
  is_split: number;
  is_surrender: { type: string; data: [] };
  player: string;
  register_date: string;
  result: string;
  seat: number;
  times_dealed: number;
}

type BaccaratBet = {
  bet: number;
  profit: number;
  user: string;
  _id: string;
};

type RouletteBet = {
  bet: number;
  profit: number;
  betType: string;
  user: string;
  _id: string;
  id: string;
};
type RouletteProfit = {
  id: string[];
  _id: string;
  user: string;
  betsType: string;
  profit: number;
};
export interface BaccaratGamelog {
  banker: BaccaratBet[];
  bankerCards: string[];
  bpair: BaccaratBet[];
  createdAt: string;
  dealerName: string;
  player: BaccaratBet[];
  playerCards: string[];
  ppair: BaccaratBet[];
  tableId: string;
  tie: BaccaratBet[];
  _id: string;
  totalBet: number;
  totalProfit: number;
}

export interface RouletteGamelog {
  bets: RouletteBet[];
  profit: RouletteProfit[];
  createdAt: string;
  dealerName: string;
  tableId: string;
  _id: string;
  totalBet: number;
  totalProfit: number;
}
export enum GameType {
  none = '',
  blackjack = 'blackjack',
  baccarat = 'baccarat',
  roulette = 'roulette',
}

export type PlayerDeposit = {
  amount: string;
  deposit_date: string;
  method: string;
  status: string;
};

export type PlayerWithdraw = {
  amount: string;
  deposit_date: string;
  method: string;
  status: string;
};

export type PlayerStoreAction =
  | { type: 'players'; players: Player[] }
  | { type: 'player'; player: Player & { games_played: GameType[] } }
  | { type: 'playerDeposits'; playerDeposits: PlayerDeposit[] }
  | { type: 'playerGamelog'; playerGamelog: GamelogRecord[] }
  | { type: 'playerWithdrawals'; playerWithdrawals: PlayerWithdraw[] };

export const initialPlayer = {
  id: '',
  address: '',
  affiliate_code: '',
  affiliate_user: '',
  balance: '',
  balance_last_update: '',
  cab_cust_ref: '',
  city: '',
  country: '',
  district: '',
  email: '',
  credits: 0,
  failed_login_attempts: 0,
  fraud_code: 0,
  gender: '',
  games_played: [],
  lastname: '',
  login: '',
  name: '',
  password: '',
  phone: '',
  register_date: '',
  state: '',
  test_user: 0,
  zipcode: '',
};
