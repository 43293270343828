import React, { ChangeEventHandler, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';

import GenericTableNew from '../../components/GenericTableNew';
import { Helmet } from 'react-helmet-async';

import { NavLink, RouteComponentProps, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Image as ImageIcon,
  Pageview as PageviewIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';
import {
  DealersContext,
  DealersContextType,
} from '../../context/dealersContext';

import { getListByComponent } from '../../services/genericServicesCasino';
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

interface RouteParams {
  id: string;
}

export const DepositsList: React.FC<any> = (props): JSX.Element => {
  const [gameTable, setGameTable] = React.useState<any>([]);
  const [enabledPage, setEnabledPage] = React.useState(false);
  const { id } = useParams<RouteParams>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (!enabledPage) {
      setPage(newPage);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const paginationPerPage = async (rowsPerPage: number, page: number) => {
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/admin/deposit/${id}?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([
          ...gameTable,
          ...gameTableResponse.slice(0, rowsPerPage),
        ]);
      }
    }
    return gameTableResponse.length;
  };
  const paginationPerRows = async (rowsPerPage: number, page: number) => {
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/admin/deposit/${id}?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([...gameTableResponse.slice(0, rowsPerPage)]);
      }
    }
    return gameTableResponse.length;
  };
  /*
  useEffect(() => {
    (async () => {
      let gameTableResponse = null;
      gameTableResponse = await getListByComponent(
        `/admin/players?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
      );
      if (gameTableResponse.status == 200) {
        gameTableResponse = gameTableResponse.data;
        if (gameTableResponse.length > 0) {
          if (gameTableResponse.length <= rowsPerPage) {
            setEnabledPage(true);
          }
          setGameTable([...gameTable, ...gameTableResponse]);
        }
      }
    })();

    return () => {};
  }, [page, rowsPerPage]);
  */
  return (
    <React.Fragment>
      <Helmet title="Affiliates" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Depositos
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Depositos</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GenericTableNew
            tableName={`Depositos`}
            rowsModel={gameTable}
            headCells={[
              {
                id: 'transactionId',
                alignment: 'center',
                label: 'ID',
                type: 'text',
              },
              {
                id: 'amount',
                alignment: 'center',
                label: 'Deposito',
                type: 'money',
              },
              {
                id: 'status',
                alignment: 'center',
                label: 'Estado',
                type: 'text',
              },

              //{ id: 'actions', alignment: 'center', label: 'Acciones' },
            ]}
            onChangePage={paginationPerPage}
            onChangeRowsPerPage={paginationPerRows}
            disabledEmptyRows={true}
            disabledTableHeader={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
