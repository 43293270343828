import React, { useContext, useState } from 'react';
import CreateForm from '../../../components/CreateForm';
import { PageContainer } from '../../components/PageContainer';
import * as Yup from 'yup';
import { useHistory, NavLink } from 'react-router-dom';
import {
  getAffiliateById,
  updateAffiliateById,
} from '../../../services/usersServices';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
export const UpdateAffiliate: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    newPassword: {
      type: 'text',
      initialValue: '',
      name: 'newPassword',
      label: 'Nueva contraseña',
      propsInput: {},
    },
    username: {
      type: 'text',
      initialValue: '',
      name: 'username',
      label: 'Username',
      propsInput: {},
    },
    title: {
      type: 'text',
      initialValue: '',
      name: 'title',
      label: 'Titulo',
      propsInput: {},
    },
    firstName: {
      type: 'text',
      initialValue: '',
      name: 'firstName',
      label: 'Nombre(s)',
      propsInput: {},
    },
    lastName: {
      type: 'text',
      initialValue: '',
      name: 'lastName',
      label: 'Apellido(s)',
      propsInput: {},
    },
    email: {
      type: 'text',
      initialValue: '',
      name: 'email',
      label: 'email',
      propsInput: {},
    },
    company: {
      type: 'text',
      initialValue: '',
      name: 'company',
      label: 'compañia',
      propsInput: {},
    },
    phone: {
      type: 'text',
      initialValue: '',
      name: 'phone',
      label: 'phone',
      propsInput: {},
    },
    reference: {
      type: 'text',
      initialValue: '',
      name: 'reference',
      label: 'Código de afiliado',
      propsInput: {},
    },
    shareableUrl: {
      type: 'text',
      initialValue: '',
      name: 'shareableUrl',
      label: 'Url',
      propsInput: {},
    },
    userType: {
      type: 'option',
      opts: [
        { value: 'affiliate', label: 'afiliado' },
        { value: 'campaign', label: 'Campaña' },
        { value: 'page', label: 'Pagina' },
        { value: 'influencer', label: 'Influencer' },
      ],
      initialValue: '',
      name: 'userType',
      label: 'Tipo de Afiliado',
      propsInput: {},
    },

    skype: {
      type: 'text',
      initialValue: '',
      name: 'skype',
      label: 'skype',
      propsInput: {},
    },

    address: {
      type: 'text',
      initialValue: '',
      name: 'address',
      label: 'Direccion',
      propsInput: {},
    },

    city: {
      type: 'text',
      initialValue: '',
      name: 'city',
      label: 'Ciudad',
      propsInput: {},
    },

    zipCode: {
      type: 'text',
      initialValue: '',
      name: 'zipCode',
      label: 'Codigo postal',
      propsInput: {},
    },

    country: {
      type: 'text',
      initialValue: '',
      name: 'country',
      label: 'Pais',
      propsInput: {},
    },
    lang: {
      type: 'text',
      initialValue: '',
      name: 'lang',
      label: 'lenguaje',
      propsInput: {},
    },

    commission: {
      type: 'text',
      initialValue: 0,
      name: 'commission',
      label: 'Porcentaje de comision',
      propsInput: {},
    },

    bankName: {
      type: 'text',
      initialValue: 0,
      name: 'bankName',
      label: 'Nombre de banco',
      propsInput: {},
    },

    accountNumber: {
      type: 'text',
      initialValue: 0,
      name: 'accountNumber',
      label: 'cuenta interbancaria',
      propsInput: {},
    },

    accountZipCode: {
      type: 'text',
      initialValue: 0,
      name: 'accountZipCode',
      label: 'Codigo postal de cuenta bancaria',
      propsInput: {},
    },

    ibanCode: {
      type: 'text',
      initialValue: 0,
      name: 'ibanCode',
      label: 'Codigo IBAN',
      propsInput: {},
    },
    swiftCode: {
      type: 'text',
      initialValue: 0,
      name: 'swiftCode',
      label: 'Codigo SWIFT',
      propsInput: {},
    },

    accountCountry: {
      type: 'text',
      initialValue: 0,
      name: 'accountCountry',
      label: 'Pais de Cuenta',
      propsInput: {},
    },

    accountAddress: {
      type: 'text',
      initialValue: 0,
      name: 'accountAddress',
      label: 'Direccion de Cuenta',
      propsInput: {},
    },
    active: {
      type: 'switch',
      initialValue: false,
      name: 'active',
      label: 'Active',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      title: Yup.string().max(255).required('Se requiere un titulo'),
      reference: Yup.string().max(255).required('Se requiere un Codigo'),
      shareableUrl: Yup.string().max(255).required('Se requiere un url'),
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateAffiliateById(props.match.params.id, values);
      setStatus({ success: true });
      setSubmitting(false);
      history.goBack();
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getAffiliateById(props.match.params.id);
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        console.log({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle={'Afiliados'}
      headerPath={'/affiliates'}
      title={'Actualiza afiliado'}
      path="affiliate/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza afiliado'}
          description={
            'Utiliza esta herramienta para registrar nuevos afiliado'
          }
          submitLabel={'Actualizar Afiliado'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
