import axios from '../utils/axios';

const emptyResponse: APIResponse = {
  error: false,
  errorMsg: '',
  status: 200,
  data: null,
};

export type APIResponse = {
  error: boolean;
  errorMsg: string;
  status?: number;
  data: any;
};

export const getPlayers = async (): Promise<APIResponse> => {
  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get('/admin/players');

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};

export const getPlayerDeposits = async (
  id: string | number
): Promise<APIResponse> => {
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get(`/admin/deposit/player/${id}`);

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};

export const getPlayerWithdrawals = async (
  id: string | number
): Promise<APIResponse> => {
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get(`/admin/withdraw/player/${id}`);

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};

export const getPlayer = async (id: string | number): Promise<APIResponse> => {
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get(`/admin/player/${id}`);

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};

export const getPlayerGamelog = async (
  id: string | number,
  gameType: string,
  limit?: number,
  skip?: number
): Promise<APIResponse> => {
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get(`/admin/gamelog/player/${id}`, {
      params: {
        gameType,
        limit,
        skip,
      },
    });
    console.log(res);

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};
