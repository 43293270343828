import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { string } from 'yup';
export const UpdateCasino: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Nombre',
      propsInput: {},
    },
    url: {
      type: 'text',
      initialValue: '',
      name: 'url',
      label: 'url',
      propsInput: {},
    },
    paymentValidationUrl: {
      type: 'text',
      initialValue: '',
      name: 'paymentValidationUrl',
      label: 'Payment Validation Hook',
      propsInput: {},
    },
    paymentValidation: {
      type: 'switch',
      initialValue: true,
      name: 'paymentValidation',
      label: 'Active Payment Validation Hook',
      propsInput: {},
    },
    withdrawValidationUrl: {
      type: 'text',
      initialValue: '',
      name: 'withdrawValidationUrl',
      label: 'Payment Validation Hook',
      propsInput: {},
    },
    withdrawValidation: {
      type: 'switch',
      initialValue: true,
      name: 'withdrawValidation',
      label: 'Active Payment Validation Hook',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      name: Yup.string().max(255).required('Se requiere un alias'),
      url: Yup.string().max(255).required('Se requiere un nombre'),
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(
        `/admin/casino/${props.match.params.id}`,
        values
      );
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/casino/list');
    } catch (error: any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getListByComponent(
        `/admin/casino/${props.match.params.id}`
      );
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="casino"
      headerPath="/casino/list"
      title={'Actualiza Casino'}
      path="table/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza Casino'}
          description={'Utiliza esta herramienta para actualizar un Casino'}
          submitLabel={'Actualizar Casino'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
