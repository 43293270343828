import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import DateFnsUtils from '@date-io/date-fns';

import { ThemeProvider } from 'styled-components/macro';
import { create } from 'jss';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from '@material-ui/core/styles';
import createTheme from './theme';
import Routes from './routes/Routes';
import { AppStateType } from './redux/reducers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!,
});

// Create a client
const queryClient = new QueryClient();

function App(props: any) {
  const theme = useSelector((state: AppStateType) => state.themeReducer);

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | LVV Admin"
          defaultTitle="LVV Admin - Admin & Dashboard"
        />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <Routes />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
