import React, { ReactNode } from 'react';
import CSS from 'csstype';

const svgContainer: CSS.Properties = {
  display: 'flex',
  flex: 1,
};
interface ButtonProps {
  cardId: string;
  style?: CSS.Properties;
  onClick(cardForServer: string): void;
}

type RankCoordinates = {
  x: number;
  y: number;
};

export const PokerCard: React.FC<ButtonProps> = ({
  style = {},
  cardId,
  onClick,
}): JSX.Element => {
  let shapePaths: React.ReactNode = <></>;
  let value: string;
  const shape: number = parseInt(cardId.toString().split('')[0]);
  const denomination: number = parseInt(
    cardId.toString().split('')[1] + cardId.toString().split('')[2]
  );
  const color = shape === 2 || shape === 3 ? '#CD0C05' : '#000';
  let topDenominationCoords: RankCoordinates = { x: 2, y: 18 };
  let bottomDenominationCoords: RankCoordinates = { x: 20, y: 33.5 };
  // 0 16 18 31.5

  switch (denomination) {
    case 1:
      value = 'A';
      break;
    case 10:
      value = denomination.toString();
      topDenominationCoords = { x: 0, y: 16 };
      bottomDenominationCoords = { x: 18, y: 31.5 };
      break;
    case 11:
      value = 'J';
      topDenominationCoords = { x: 6, y: 18 };
      bottomDenominationCoords = { x: 24, y: 33.5 };
      break;
    case 12:
      value = 'Q';
      break;
    case 13:
      value = 'K';
      break;

    default:
      value = denomination.toString();
      break;
  }

  switch (shape) {
    case 1:
      shapePaths = shapeClubs;
      break;
    case 2:
      shapePaths = shapeDiamonds;
      break;
    case 3:
      shapePaths = shapeHearts;
      break;
    case 4:
      shapePaths = shapeSpades;
      break;
    default:
      break;
  }

  return (
    <svg
      style={{ ...svgContainer, ...style }}
      viewBox="0 0 40 55"
      onClick={() => {
        onClick(cardId);
      }}
    >
      <defs>
        <path
          id="spadesA"
          d="M0 0.000186776472L13.6128029 0.000186776472 13.6128029 15.063896 0 15.063896z"
        />
        <path
          id="spadesC"
          d="M0 0.000186776472L13.6128029 0.000186776472 13.6128029 15.063896 0 15.063896z"
        />
        <path
          id="clubsA"
          d="M0 0.0000302400003L13.9375902 0.0000302400003 13.9375902 14.8977059 0 14.8977059z"
        />
        <path
          id="clubsC"
          d="M0 0.0000302400003L13.9375902 0.0000302400003 13.9375902 14.8977059 0 14.8977059z"
        />
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <rect
          stroke="silver"
          strokeWidth={1}
          fill="#FFF"
          x={0}
          y={0}
          width={40}
          height={55}
          rx={3}
        />
        <text
          letterSpacing={'-2px'}
          fontFamily="Jost"
          fontSize={value === '10' ? 18 : 20}
          fontWeight={700}
          fill={color}
        >
          <tspan x={topDenominationCoords.x} y={topDenominationCoords.y}>
            {value}
          </tspan>
        </text>
        <text
          transform="rotate(-180 29 35.5)"
          fontFamily="Jost"
          fontSize={value === '10' ? 18 : 20}
          fontWeight={700}
          fill={color}
          letterSpacing={'-2px'}
        >
          <tspan x={bottomDenominationCoords.x} y={bottomDenominationCoords.y}>
            {value}
          </tspan>
        </text>
        {shapePaths}
      </g>
    </svg>
  );
};

const shapeHearts: React.ReactNode = (
  <>
    <path
      d="M33.375 3.002c-2.973-.073-3.657 2.238-3.815 3.35-.157-1.112-.842-3.423-3.815-3.35-3.91.096-4.428 4.555-3.062 6.809 1.366 2.253 2.073 2.972 3.439 4.41 1.286 1.356 2.823 2.88 3.391 3.815 0 0 .03.108.047.079.017.03.047-.08.047-.08.568-.934 2.105-2.458 3.392-3.813 1.366-1.439 2.072-2.158 3.438-4.411 1.366-2.254.848-6.713-3.062-6.81"
      fill="#CD0C05"
    />
    <path
      d="M13.375 37.002c-2.973-.073-3.657 2.238-3.815 3.35-.157-1.112-.842-3.423-3.815-3.35-3.91.096-4.428 4.555-3.062 6.809 1.366 2.253 2.073 2.972 3.439 4.41 1.286 1.356 2.823 2.88 3.391 3.815 0 0 .03.108.047.079.017.03.047-.08.047-.08.568-.934 2.105-2.458 3.392-3.813 1.366-1.439 2.072-2.158 3.438-4.411 1.366-2.254.848-6.713-3.062-6.81"
      fill="#CD0C05"
      transform="rotate(-180 10.56 44.56)"
    />
  </>
);

const shapeDiamonds: React.ReactNode = (
  <>
    <path
      fill="#CD0C05"
      d="M31.2192854 3L25 10.4998413 31.2192854 18.1199995 37.5999996 10.5599998z"
    />
    <path
      fill="#CD0C05"
      d="M9.21928538 37L3 44.4998413 9.21928538 52.1199995 15.5999996 44.5599998z"
    />
  </>
);

const shapeSpades: React.ReactNode = (
  <>
    <g transform="translate(23 3)">
      <mask id="spadesB" fill="#fff">
        <use xlinkHref="#spadesA" />
      </mask>
      <path
        d="M12.1 5.676c-1.555-1.695-3.36-3.35-4.117-4.217A43.698 43.698 0 016.848.106V0l-.042.053L6.764 0v.106C6.6.313 6.197.807 5.63 1.459c-.756.868-2.563 2.522-4.117 4.217C-.042 7.37-.167 9.066.127 10.472.42 11.878 1.89 13.2 4.202 12.91a2.575 2.575 0 001.642-.873c-.26 1.99-1.335 3.026-1.335 3.026h4.482c-.818-.914-1.108-2.209-1.212-3.014.386.429.913.771 1.632.861 2.31.29 3.781-1.033 4.075-2.44.294-1.405.169-3.1-1.386-4.795"
        fill="#000000"
        mask="url(#spadesB)"
      />
    </g>
    <g transform="rotate(-180 8 26)">
      <mask id="spadesD" fill="#fff">
        <use xlinkHref="#spadesC" />
      </mask>
      <path
        d="M12.1 5.676c-1.555-1.695-3.36-3.35-4.117-4.217A43.698 43.698 0 016.848.106V0l-.042.053L6.764 0v.106C6.6.313 6.197.807 5.63 1.459c-.756.868-2.563 2.522-4.117 4.217C-.042 7.37-.167 9.066.127 10.472.42 11.878 1.89 13.2 4.202 12.91a2.575 2.575 0 001.642-.873c-.26 1.99-1.335 3.026-1.335 3.026h4.482c-.818-.914-1.108-2.209-1.212-3.014.386.429.913.771 1.632.861 2.31.29 3.781-1.033 4.075-2.44.294-1.405.169-3.1-1.386-4.795"
        fill="#000000"
        mask="url(#spadesD)"
      />
    </g>
  </>
);

const shapeClubs: React.ReactNode = (
  <>
    <g transform="translate(23 3)">
      <mask id="clubsb" fill="#fff">
        <use xlinkHref="#clubsA" />
      </mask>
      <path
        d="M13.042 6.445c-1.511-1.651-3.834-.671-3.834-.671.65-.694 1.083-1.246 1.001-2.384a3.275 3.275 0 10-6.479.549c.075.787.464 1.262 1 1.835 0 0-2.323-.98-3.835.671C-.616 8.096.14 10.055.615 10.7c.476.644 1.19 1.725 2.87 1.6 1.486-.11 1.705-.364 2.728-1.18-.122 2.245-1.483 3.779-1.483 3.779h4.478c-.823-.866-1.552-2.852-1.457-3.758 1 .8 1.229 1.05 2.702 1.16 1.68.124 2.393-.957 2.869-1.6.476-.644 1.232-2.604-.28-4.255"
        fill="#000000"
        mask="url(#clubsb)"
      />
    </g>
    <g transform="rotate(-180 8.182 25.924)">
      <mask id="clubsd" fill="#fff">
        <use xlinkHref="#clubsC" />
      </mask>
      <path
        d="M13.042 6.445c-1.511-1.651-3.834-.671-3.834-.671.65-.694 1.083-1.246 1.001-2.384a3.275 3.275 0 10-6.479.549c.075.787.464 1.262 1 1.835 0 0-2.323-.98-3.835.671C-.616 8.096.14 10.055.615 10.7c.476.644 1.19 1.725 2.87 1.6 1.486-.11 1.705-.364 2.728-1.18-.122 2.245-1.483 3.779-1.483 3.779h4.478c-.823-.866-1.552-2.852-1.457-3.758 1 .8 1.229 1.05 2.702 1.16 1.68.124 2.393-.957 2.869-1.6.476-.644 1.232-2.604-.28-4.255"
        fill="#000000"
        mask="url(#clubsd)"
      />
    </g>
  </>
);
