import React, { useContext } from 'react';
import { GenericTable } from '../../components/GenericTable';
import {
  TransactionsContext,
  TransactionsContextType,
} from '../../context/transactionsContext';
import { HeadCell } from '../../components/GenericTable';
import { TableCell } from '@material-ui/core';

interface withdrawalsProps {}

export const Withdrawals: React.FC<withdrawalsProps> = (props): JSX.Element => {
  const {
    withdrawals,
    updateWithdrawalsList,
  } = useContext<TransactionsContextType>(TransactionsContext);
  React.useEffect(() => {
    (async () => {
      await updateWithdrawalsList();
    })();

    return () => {};
  }, []);

  const withdrawalsHeader: HeadCell[] = [
    { id: 'id', alignment: 'left', label: 'ID de retiro' },
    { id: 'amount', alignment: 'left', label: 'Cantidad' },
    { id: 'method', alignment: 'left', label: 'Método' },
    { id: 'name', alignment: 'left', label: 'Nombre' },
    {
      id: 'request_date',
      alignment: 'left',
      label: 'Fecha de petición',
    },
    { id: 'status', alignment: 'left', label: 'Estatus' },
  ];

  return (
    <React.Fragment>
      <GenericTable
        tableName="Retiros"
        rowsModel={withdrawals}
        headCells={withdrawalsHeader}
        tableCells={(row: number) => {
          return (
            <>
              <TableCell align="left"># {withdrawals[row].id}</TableCell>
              <TableCell align="left">{withdrawals[row].amount}</TableCell>
              <TableCell align="left">{withdrawals[row].method}</TableCell>
              <TableCell align="left">{withdrawals[row].name}</TableCell>
              <TableCell align="left">
                {withdrawals[row].request_date}
              </TableCell>
              <TableCell align="left">{withdrawals[row].status}</TableCell>
            </>
          );
        }}
      />
    </React.Fragment>
  );
};
