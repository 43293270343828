import { useQuery } from 'react-query';
import { getListByComponent } from '../services/genericServices';
import { useQueryClient, useMutation } from 'react-query';
import axios from '../utils/axios';

// Gets all the affiliates of type affiliate from the database
const getAffiliates = async () => {
  const { data } = await getListByComponent(`/admin/affiliate/table/affiliate`);
  return data;
};

export function useAffiliates() {
  return useQuery<[], Error>('affiliates', getAffiliates);
}

export function useCreateAffiliate() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) => axios.post('/admin/influencer', values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('affiliates');
      },
    }
  );
}
