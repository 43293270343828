import React from 'react';
import { Auth, AuthStoreAction, AUTH_INIT } from './types';

export const AUTH_INITIAL_STATE: AuthContextType = {
  user: {},
  username: '',
  isActive: true,
  role: '',
  isAuthenticated: false,
  gameSessionId: '',
  isLoading: false,
  userLoggedSomewhereElse: false,
  currency: 'USD',
  dispatch: (value: AuthStoreAction) => {},
  login: async (username: string, password: string, recaptchaValue?: string) =>
    AUTH_INIT,
  fetchUser: async () => {},
  signOut: async () => {},
  updateCurrency: async (currency: string) => {},
  hasRole: (rolesAllowed: Array<string>) => true,
};

export type AuthContextType = {
  user: any;
  username: string;
  role: string;
  isActive: boolean;
  isAuthenticated: boolean;
  gameSessionId: string;
  isLoading: boolean;
  userLoggedSomewhereElse: boolean;
  currency: string;
  dispatch: React.Dispatch<AuthStoreAction>;
  login(
    username: string,
    password: string,
    recaptchaValue?: string
  ): Promise<Auth>;
  updateCurrency: (currency: string) => void;
  fetchUser: () => Promise<any>;
  signOut: () => void;
  hasRole: (rolesAllowed: Array<string>) => boolean;
};

export const AuthContext = React.createContext<AuthContextType>(
  AUTH_INITIAL_STATE
);

export * from './AuthStore';
export * from './types';
