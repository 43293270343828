import axios from '../utils/axios';

import { ResetPasswordType, SignInType, SignUpType } from '../types/auth';
const emptyResponse: APIResponse = {
  error: false,
  errorMsg: '',
  status: 200,
  data: null,
};

export type APIResponse = {
  error: boolean;
  errorMsg: string;
  status?: number;
  data: any;
};
export function setJWT(token: string) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
export function removeJWT() {
  delete axios.defaults.headers.common['Authorization'];
}

export async function signIn(credentials: SignInType): Promise<APIResponse> {
  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);

  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.post('/admin/user/login', credentials);

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
}

export function signUp(credentials: SignUpType) {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/auth/sign-up', credentials)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function getUser(): Promise<APIResponse> {
  const url = `/admin/user`;
  let result = null;

  try {
    const res = await axios.get(url);

    // console.log('res', res.headers);
    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);
    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
}

export function resetPassword(credentials: ResetPasswordType) {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/auth/reset-password', credentials)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
