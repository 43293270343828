import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContextType, AuthContext } from '../../context/authContext';
import { PageContainer } from '../components/PageContainer';
import { CheckBox } from '@material-ui/icons';
import { PokerCard } from '../../components/PokerCard';
import GenericTableNew from '../../components/GenericTableNew';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
function RouletteDetail(props: any) {
  const history = useHistory();
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card
        style={{
          minWidth: 275,
          margin: 20,
        }}
      >
        <CardContent>
          <Box pt={2} pb={2}>
            <Typography variant="h5" component="h2">
              Resultado
            </Typography>
          </Box>
          <Divider />
          <Box pt={2} pb={2}>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '35px',
                textShadow:
                  '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
                color:
                  [
                    1,
                    3,
                    5,
                    7,
                    9,
                    12,
                    14,
                    16,
                    18,
                    19,
                    21,
                    23,
                    25,
                    27,
                    30,
                    32,
                    34,
                    36,
                  ].indexOf(parseInt(props.detailGameLog.result)) >= 0
                    ? 'red'
                    : 'black',
              }}
              variant="h5"
              component="h1"
            >
              {props.detailGameLog.result}
            </Typography>
          </Box>

          <Box pt={2} pb={2}>
            <GenericTableNew
              tableName={`Detalle de Partida Ruleta`}
              rowsModel={props.detailGameLog.bets.filter(
                (item: any) => item.user == props.playerId
              )}
              headCells={[
                {
                  id: 'betType',
                  alignment: 'center',
                  label: 'Tipo de Apuesta',
                  type: 'text',
                },
                {
                  id: 'id',
                  alignment: 'left',
                  label: 'Numeros de apuesta',
                  type: 'text',
                },
                {
                  id: 'bet',
                  alignment: 'center',
                  label: 'monto de apuesta',
                  type: 'number',
                },
                {
                  id: 'profit',
                  alignment: 'center',
                  label: 'monto de ganancia',
                  type: 'number',
                },
                //{ id: 'actions', alignment: 'center', label: 'Acciones' },
              ]}
              serverPagination={true}
              disabledEmptyRows={true}
              disabledTableHeader={true}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default RouletteDetail;
