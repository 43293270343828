import { Button as MuiButton } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import styled from 'styled-components/macro';
import { spacing, SpacingProps } from '@material-ui/system';

type ButtonProps = {
  // ref: React.MutableRefObject<null>;
  onClick: (e: any) => void;
};

const Button = styled(MuiButton)(spacing);
const SectionActionButton: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <Button mr={2} variant="contained" color="primary" onClick={onClick}>
      <AddIcon />
      Agregar
    </Button>
  );
};

export default SectionActionButton;
