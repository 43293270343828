import React, { PropsWithChildren, useContext, useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import styled, { withTheme, ThemeProps } from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { green, orange, red, blue } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { spacing, SpacingProps } from '@material-ui/system';
// import {
//   PlayersContext,
//   PlayersContextType,
// } from '../../context/playersContext';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

interface ChipPropstype extends SpacingProps {
  shipped?: number;
  processing?: number;
  cancelled?: number;
}

const Chip = styled(MuiChip)<ChipPropstype>`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export type RowTypeWithTables = {
  [key: string]: string | number | boolean | RowType[];
  id: string;
  tables: RowType[];
};

const rowHasTables = (value: RowType): value is RowTypeWithTables => {
  return (value as RowType).tables !== undefined;
};

// export type TableType = {
//   [key: string]: string | number | boolean | RowType[];
//   id: string;
// };

export type RowType = {
  [key: string]: string | number | boolean | (RowType[] | undefined);
  id: string;
  tables?: RowType[];
};

function descendingComparator(orderBy: string, a?: any, b?: any) {
  if (!b && !a) return 0;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order: 'desc' | 'asc', orderBy: string) {
  return order === 'desc'
    ? (a: RowType, b: RowType) => descendingComparator(orderBy, a, b)
    : (a: RowType, b: RowType) => -descendingComparator(orderBy, a, b);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  console.log('stabilizedThis', stabilizedThis);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  console.log('stabilizedThis2', stabilizedThis);
  return stabilizedThis.map((element) => element.el);
}

export type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  type:
    | 'text'
    | 'image'
    | 'money'
    | 'number'
    | 'videoGameLog'
    | 'subtable'
    | 'date'
    | 'chip'
    | 'actions'
    | 'array';
  disablePadding?: boolean;
};

type EnhancedTableHeadPropsType = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  disabledCheckboxColumn: boolean;
  disabledCollapseColumn: boolean;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
};
const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    disabledCheckboxColumn,
    disabledCollapseColumn,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {disabledCheckboxColumn ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        ) : null}
        {disabledCollapseColumn ? <TableCell></TableCell> : null}
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarPropsType = {
  numSelected: number;
  tableName: string;
  allowTableFilter: boolean;
};
const EnhancedTableToolbar = (props: EnhancedTableToolbarPropsType) => {
  // Here was 'let'
  const { numSelected, tableName, allowTableFilter } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {tableName}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        )}
        {allowTableFilter && numSelected === 0 && (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};
function formatNumbers(number: number) {
  let isNegative = number < 0 ? '-' : '';
  let numberAbs = Math.abs(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return isNegative + '$' + numberAbs;
}
export const EnhancedTable: React.FC<{
  allowTableFilter?: boolean;
  rowsModel: RowType[];
  subTableRowModel?: boolean;
  headCells: HeadCell[];
  tableName: string;
  disabledEmptyRows?: boolean;
  disabledCheckboxColumn?: boolean;
  selectAllCheckbox?: boolean;
  serverPagination?: boolean;
  tableType?: string;
  onChangePage?(rowsPerPage: number, page: number): Promise<number>;
  onChangeRowsPerPage?(rowsPerPage: number, page: number): Promise<number>;
  setSelectedRows?(selectedRows: number): void;
  tableCells?(rowNumber: number): JSX.Element;
  actionsCells?(rowNumber: string | number | Array<any>): JSX.Element;
  onCheckboxClick?(checkboxes: string[]): void;
}> = ({
  allowTableFilter = true,
  rowsModel,
  subTableRowModel = false,
  headCells,
  tableName,
  tableCells,
  selectAllCheckbox = false,
  disabledEmptyRows = false,
  disabledCheckboxColumn = false,
  serverPagination = false,
  tableType = '',
  onChangePage = (rowsPerPage: number, page: number) => 0,
  onChangeRowsPerPage = (rowsPerPage: number, page: number) => 0,
  actionsCells = (id: string | number | Array<any>) => null,
  setSelectedRows = (selectedRows: number) => {},
  onCheckboxClick = (checkboxes: string[]) => {},
}): JSX.Element => {
  const [enabledPage, setEnabledPage] = React.useState(false);
  const checkboxAll = React.useRef(null);
  const [rowsDataTable, setRowsDataTable] = React.useState<RowType[]>([]);
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('customer');
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [lastPage, setLastPage] = React.useState(0);
  const [visitedPages, setVisitedPages] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();
  useEffect(() => {
    if (selectAllCheckbox) {
      const newSelecteds: Array<string> = rowsModel.map((n: RowType) => n.id);
      setSelected(newSelecteds);
      onCheckboxClick(newSelecteds);
      setSelectedRows(newSelecteds.length);
    }
  }, [rowsModel.length > 0]);
  useEffect(() => {
    (async () => {
      if (lastPage == page) {
        setEnabledPage(true);
      }
      console.log('nextPage', page, visitedPages, lastPage, page, enabledPage);
      if (!serverPagination) {
        if (page > visitedPages && lastPage <= page && !enabledPage) {
          let rowsLength = await onChangePage(rowsPerPage, page);
          console.log('onChangePage', rowsLength, rowsPerPage);
          if (rowsLength <= rowsPerPage) {
            setEnabledPage(true);
            setLastPage(page);
          }
          setVisitedPages(page);
        }
      }
    })();
  }, [page]);
  useEffect(() => {
    (async () => {
      setEnabledPage(false);
      setVisitedPages(0);
      setLastPage(0);
      if (!serverPagination) {
        let rowsLength = await onChangeRowsPerPage(rowsPerPage, page);
        console.log(rowsLength <= rowsPerPage);
        if (rowsLength <= rowsPerPage) {
          setEnabledPage(true);
        }
      }
    })();
  }, [rowsPerPage]);
  /*useEffect(() => {
    if (serverPagination) {
      (async () => {
        updateRowsModelByPagination(rowsPerPage, page);
      })();
    }
    return () => {};
  }, [page, rowsPerPage]);
*/
  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    console.log('handleRequestSort', property);
    setOrderBy(property);
  };
  const [open, setOpen] = React.useState<boolean[]>([]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = rowsModel.map((n: RowType) => n.id);
      setSelected(newSelecteds);
      onCheckboxClick(newSelecteds);
      setSelectedRows(newSelecteds.length);
      return;
    }
    setSelectedRows(0);
    setSelected([]);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (newSelected.length == 0) {
      const newSelecteds: Array<string> = rowsModel.map((n: RowType) => n.id);
      setSelectedRows(newSelecteds.length);
      onCheckboxClick(newSelected);
    } else {
      setSelectedRows(newSelected.length);
      onCheckboxClick(newSelected);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (!enabledPage || newPage < page) {
      setPage(newPage);
      setEnabledPage(false);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowsModel.length - page * rowsPerPage);
  const cellToRender = (
    typeOfCell: string,
    value?: string | number | boolean | Array<string>
  ) => {
    if (typeof value === 'undefined') return;
    switch (typeOfCell) {
      case 'image':
        return (
          <TableCell align="left">
            {/* <img
              // style={}
              alt="dealer"
              src={typeof value === 'string' ? value : ''}
            ></img> */}
            <Avatar alt="dealer" src={typeof value === 'string' ? value : ''} />
          </TableCell>
        );
      case 'text':
        return (
          <TableCell align="left">
            <Typography variant="subtitle1">{value}</Typography>
          </TableCell>
        );
      case 'number':
        console.log(value);
        return (
          <TableCell
            style={{
              color: theme.palette.secondary.main,
            }}
            align="left"
          >
            <Typography variant="h6">
              {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>
          </TableCell>
        );
      case 'money':
        return (
          <TableCell
            style={{
              color: value < 0 ? red[900] : theme.palette.secondary.main,
            }}
            align="left"
          >
            <Typography variant="h6">
              {formatNumbers(
                typeof value === 'number'
                  ? value
                  : typeof value === 'string'
                  ? parseFloat(value)
                  : 0
              )}
            </Typography>
          </TableCell>
        );
      case 'date':
        return (
          <TableCell align="left">
            <Typography variant="subtitle1">
              {moment(typeof value === 'string' ? value : '').format(
                'YYYY/MM/DD HH:mm:ss'
              )}
            </Typography>
          </TableCell>
        );
      case 'videoGameLog':
        return (
          <TableCell align="left">
            <video
              style={{
                width: '300px',
              }}
              controls
            >
              <source
                src={
                  'https://lasvegasenvivo-videos.s3.amazonaws.com/' +
                  value +
                  '.mp4'
                }
                type="video/mp4"
              />
            </video>
          </TableCell>
        );
      case 'chip':
        return (
          <TableCell align="left">
            {value <= 0 ? (
              <Chip
                color="primary"
                size="small"
                mr={1}
                mb={1}
                label="No"
                cancelled={-1}
              />
            ) : (
              <Chip
                color="primary"
                size="small"
                mr={1}
                mb={1}
                label="Si"
                shipped={1}
              />
            )}
          </TableCell>
        );
      case 'array':
        return (
          <TableCell align="left">
            {Array.isArray(value) &&
              value.map((item: string) => {
                <Typography variant="subtitle1">{item}</Typography>;
              })}
          </TableCell>
        );
      case 'actions':
        return (
          <TableCell align="left">
            {typeof value === 'string' ||
            typeof value === 'number' ||
            Array.isArray(value)
              ? actionsCells(value)
              : ''}
          </TableCell>
        );
    }
  };
  const valueIsNotArray = (
    value: string | number | boolean | RowType[] | undefined
  ): value is string | number | boolean => {
    return (
      typeof (value as string | number | boolean | Array<any>) !== 'undefined'
    );
  };
  const renderTablesRows = (rowData: RowType) => {
    return (
      <>
        {headCells.map((columnCell) =>
          cellToRender(
            columnCell.type,
            valueIsNotArray(rowData[columnCell.id])
              ? (rowData[columnCell.id] as string | number | boolean)
              : ''
          )
        )}
      </>
    );
  };
  const toogleCollapse = (value: boolean, index: number) => {
    let values = [...open];
    values[index] = value;
    setOpen(values);
  };
  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          tableName={tableName}
          allowTableFilter={allowTableFilter}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsModel.length}
              headCells={headCells}
              disabledCollapseColumn={subTableRowModel}
              disabledCheckboxColumn={disabledCheckboxColumn}
            />
            <TableBody>
              {stableSort(rowsModel, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: RowType, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  console.log(row, labelId);
                  return (
                    <React.Fragment>
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        {disabledCheckboxColumn ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                        ) : null}
                        {subTableRowModel ? (
                          <TableCell align="left">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                toogleCollapse(!open[index], index)
                              }
                            >
                              {open[index] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        ) : null}
                        {renderTablesRows(row)}
                      </TableRow>
                      {subTableRowModel ? (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`subTable-${row.id}-${index}`}
                        >
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={10}
                          >
                            <Collapse
                              in={open[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <GenericTableNew
                                  allowTableFilter={allowTableFilter}
                                  tableName="Mesas"
                                  rowsModel={
                                    rowHasTables(row) ? row.tables : []
                                  }
                                  headCells={headCells}
                                  disabledEmptyRows={true}
                                  disabledTableHeader={true}
                                />
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              {!disabledEmptyRows && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={serverPagination ? rowsModel.length : -1}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export interface GenericTableProps<T extends RowType> {
  allowTableFilter?: boolean;
  rowsModel: T[];
  subTableRowModel?: boolean;
  tableName: string;
  headCells: HeadCell[];
  disabledEmptyRows?: boolean;
  disabledTableHeader?: boolean;
  disabledCheckboxColumn?: boolean;
  selectAllCheckbox?: boolean;
  serverPagination?: boolean;
  onChangePage?(rowsPerPage: number, page: number): Promise<number>;
  onChangeRowsPerPage?(rowsPerPage: number, page: number): Promise<number>;
  setSelectedRows?(rowsNumber: number): void;
  tableCells?(rowNumber: number): JSX.Element;
  onCheckboxClick?(checkboxes: string[]): void;
  actionsCells?(rowNumber: string | number | Array<any>): JSX.Element;
}

const GenericTableNew = <T extends RowType>(
  props: PropsWithChildren<GenericTableProps<T>>
) => {
  return (
    <React.Fragment>
      <Helmet title="Players" />
      {!props.disabledTableHeader ? (
        <React.Fragment>
          <Grid justify="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                {props.tableName}
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} exact to="/">
                  Dashboard
                </Link>
                <Typography>{props.tableName}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Button mr={2} variant="contained" color="primary">
                <AddIcon />
                Agregar
              </Button>
              <Button mr={2} variant="contained" color="primary">
                <EditIcon />
                Editar
              </Button>
              <Button variant="contained" color="default">
                <DeleteIcon />
                Borrar
              </Button>
            </Grid>
          </Grid>

          <Divider my={6} />
        </React.Fragment>
      ) : null}
      <Grid
        container
        spacing={6}
        style={{
          marginBottom: 0,
        }}
      >
        <Grid item xs={12}>
          <EnhancedTable {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GenericTableNew;
