import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppStateType } from '../redux/reducers';
import { AuthContext, AuthContextType } from '../context/authContext';

interface AuthGuardType {
  children: React.ReactNode;
  roles: Array<string> | null;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ roles = null, children }: AuthGuardType) {
  const { username, role } = useContext<AuthContextType>(AuthContext);
  const auth = useSelector((state: AppStateType) => state.authReducer);

  if (roles) {
    if (!roles.some((roleAllowed) => roleAllowed === role)) {
      return <Redirect to="/auth/sign-in" />;
    }
  }
  if (!username && localStorage.getItem('casino_backoffice_token')) {
    return <Redirect to="/auth/sign-in" />;
  }

  return children;
}

export default AuthGuard;
