import axios from '../utils/axios';

const emptyResponse: APIResponse = {
  error: false,
  errorMsg: '',
  status: 200,
  data: null,
};

export type APIResponse = {
  error: boolean;
  errorMsg: string;
  status?: number;
  data: any;
};

export const getDealers = async (): Promise<APIResponse> => {
  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get('/admin/dealers');

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};
export const getDealersByTable = async (id: string[]): Promise<APIResponse> => {
  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get('/admin/analytics/table/dealers', {
      params: {
        type: 'today',
      },
    });

    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};
