import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink, Link as RouterLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';

import { getListByComponent } from '../../services/genericServices';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

interface ChipPropstype extends SpacingProps {
  shipped?: number;
  processing?: number;
  cancelled?: number;
}

const Chip = styled(MuiChip)<ChipPropstype>`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

type RowType = {
  [key: string]: string | number | boolean;
  _id: string;
  dealer: string;
  tableNumber: number;
  game: string;
  gameType: string;
  tablePicture: string;
  isOpen: boolean;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'desc' | 'asc', orderBy: string) {
  return order === 'desc'
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  disablePadding?: boolean;
};

const headCells: Array<HeadCell> = [
  { id: '_id', alignment: 'center', label: 'ID de Mesa de Juego' },
  { id: 'dealer', alignment: 'left', label: 'Alias' },
  { id: 'isOpen', alignment: 'center', label: 'Estado' },
  { id: 'game', alignment: 'center', label: 'Tipo de juego' },
  { id: 'gameType', alignment: 'center', label: 'Categoria de juego' },
  { id: 'tableNumber', alignment: 'center', label: 'Numero de Mesa' },
  { id: 'tablePicture', alignment: 'center', label: 'Imagen' },
  { id: 'actions', alignment: 'center', label: 'Acciones' },
];

type EnhancedTableHeadPropsType = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarPropsType = { numSelected: number };
const EnhancedTableToolbar = (props: EnhancedTableToolbarPropsType) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Mesas
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

const EnhancedTable: React.FC<{ rows: RowType[] }> = ({
  rows,
}): JSX.Element => {
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('customer');
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = rows.map((n: RowType) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: RowType, index: number) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row._id)}
                        />
                      </TableCell>
                      <TableCell align="center">{row._id}</TableCell>
                      <TableCell align="center">{row.dealer}</TableCell>
                      <TableCell>
                        {row.isOpen && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Activo"
                            shipped={+true}
                          />
                        )}
                        {!row.isOpen && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Inactivo"
                            cancelled={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">{row.game}</TableCell>
                      <TableCell align="center">{row.gameType}</TableCell>
                      <TableCell align="center">{row.tableNumber}</TableCell>
                      <TableCell align="center">
                        <img src={row.tablePicture} height={150} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/table/${row.game}/${row._id}`}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

function Dealers() {
  const [gameTable, setGameTable] = React.useState<any>([]);
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      let gameTableResponse = null;
      gameTableResponse = await getListByComponent('/admin/tables');
      if (gameTableResponse.status == 200) {
        gameTableResponse = gameTableResponse.data;
        setGameTable([...gameTableResponse]);

        console.log('gameTable', gameTable);
      }
    })();

    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Mesas de juego" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Mesas de juego
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Mesas de juego</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            mr={2}
            variant="contained"
            color="primary"
            ref={ref}
            onClick={handleOpen}
          >
            <AddIcon />
            Agregar
          </Button>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            anchorEl={ref.current}
            onClose={handleClose}
            open={isOpen}
          >
            <Box display="flex" justifyContent="center">
              <ListItem button component={RouterLink} to="/table/baccarat">
                Crear Mesa de Baccarat
              </ListItem>
            </Box>
            <Box display="flex" justifyContent="center">
              <ListItem button component={RouterLink} to="/table/blackjack">
                Crear Mesa de Blackjack
              </ListItem>
            </Box>
            <Box display="flex" justifyContent="center">
              <ListItem button component={RouterLink} to="/table/roulette">
                Crear Mesa de Roulette
              </ListItem>
            </Box>
          </Popover>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable rows={gameTable} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dealers;
