import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory, NavLink } from 'react-router-dom';
import {
  getPromotionById,
  updatePromotionById,
} from '../../services/usersServices';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
export const UpdateBonus: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Nombre de bono',
      propsInput: {},
    },
    code: {
      type: 'text',
      initialValue: '',
      name: 'code',
      label: 'Codigo de bono',
      propsInput: {},
    },
    expirationDays: {
      type: 'text',
      initialValue: '',
      name: 'expirationDays',
      label: 'Dias de expiracion',
      propsInput: {},
    },
    credits: {
      type: 'text',
      initialValue: '',
      name: 'credits',
      label: 'Creditos',
      propsInput: {},
    },
    minDeposit: {
      type: 'text',
      initialValue: '',
      name: 'minDeposit',
      label: 'Deposito Minimo',
      propsInput: {},
    },
    maxDeposit: {
      type: 'text',
      initialValue: '',
      name: 'maxDeposit',
      label: 'Deposito Maximo',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      name: Yup.string()
        .max(255)
        .required('Se requiere un nombre para el bono'),
      code: Yup.string()
        .max(255)
        .required('Se requiere un codigo de promocions'),
      expirationDays: Yup.string()
        .max(255)
        .required('Se requieren dias de expiracion'),
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updatePromotionById(props.match.params.id, values);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/promotion');
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getPromotionById(props.match.params.id);
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        console.log({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Promociones"
      headerPath="/promotion"
      title={'Actualiza Bono'}
      path="affiliate/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza Bono'}
          description={'Utiliza esta herramienta para actualizar Bonos'}
          submitLabel={'Actualizar Afiliado'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
