import React, { ChangeEventHandler, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink, Link as LinkRouter, useHistory } from 'react-router-dom';

import GenericTableNew from '../../components/GenericTableNew';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Image as ImageIcon,
  Pageview as PageviewIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';
import {
  DealersContext,
  DealersContextType,
} from '../../context/dealersContext';

import { getListByComponent } from '../../services/genericServices';
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

export const BonusList: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [gameTable, setGameTable] = React.useState<any>([]);
  const [enabledPage, setEnabledPage] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (!enabledPage) {
      setPage(newPage);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const createBonusHandler = () => {
    history.push('/promotion/create');
  };
  const paginationPerPage = async (rowsPerPage: number, page: number) => {
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/admin/promotion?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([
          ...gameTable,
          ...gameTableResponse.slice(0, rowsPerPage),
        ]);
      }
    }
    return gameTableResponse.length;
  };
  const paginationPerRows = async (rowsPerPage: number, page: number) => {
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/admin/promotion?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([...gameTableResponse.slice(0, rowsPerPage)]);
      }
    }
    return gameTableResponse.length;
  };
  /*
  useEffect(() => {
    (async () => {
      let gameTableResponse = null;
      gameTableResponse = await getListByComponent(
        `/admin/players?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
      );
      if (gameTableResponse.status == 200) {
        gameTableResponse = gameTableResponse.data;
        if (gameTableResponse.length > 0) {
          if (gameTableResponse.length <= rowsPerPage) {
            setEnabledPage(true);
          }
          setGameTable([...gameTable, ...gameTableResponse]);
        }
      }
    })();

    return () => {};
  }, [page, rowsPerPage]);
  */
  return (
    <React.Fragment>
      <Helmet title="Affiliates" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Bonos
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Bonos</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={createBonusHandler}
          >
            crear promocion
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GenericTableNew
            tableName={`Bonos`}
            rowsModel={gameTable}
            headCells={[
              {
                id: 'name',
                alignment: 'center',
                label: 'Nombre de Bono',
                type: 'text',
              },
              {
                id: 'code',
                alignment: 'center',
                label: 'Codigo de bono',
                type: 'text',
              },
              {
                id: 'limit',
                alignment: 'center',
                label: 'Email',
                type: 'number',
              },
              {
                id: 'expirationDays',
                alignment: 'center',
                label: 'Dias de expiracion',
                type: 'number',
              },
              {
                id: 'rollOverTofree',
                alignment: 'center',
                label: 'RollOver',
                type: 'text',
              },

              {
                id: 'minDeposit',
                alignment: 'center',
                label: 'Deposito minimo',
                type: 'number',
              },
              {
                id: 'maxDeposit',
                alignment: 'center',
                label: 'Deposito maximo',
                type: 'number',
              },
              {
                id: '_id',
                alignment: 'center',
                label: 'Acciones',
                type: 'actions',
              },
              //{ id: 'actions', alignment: 'center', label: 'Acciones' },
            ]}
            onChangePage={paginationPerPage}
            onChangeRowsPerPage={paginationPerRows}
            disabledEmptyRows={true}
            disabledTableHeader={true}
            actionsCells={(id: string) => {
              return (
                <>
                  <IconButton
                    aria-label="details"
                    component={NavLink}
                    to={`/promotion/${id}`}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
