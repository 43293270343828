import React, { ChangeEventHandler, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';

import { GenericServerTable } from '../../components/GenericServerTable';
import GenericTableNew from '../../components/GenericTableNew';
import { Helmet } from 'react-helmet-async';

import { NavLink, RouteComponentProps, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Image as ImageIcon,
  Pageview as PageviewIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';
import {
  DealersContext,
  DealersContextType,
} from '../../context/dealersContext';

import { getListByComponent } from '../../services/genericServicesCasino';
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface RouteParams {
  id: string;
}

export const WithdrawList: React.FC<any> = (props): JSX.Element => {
  const [gameTable, setGameTable] = React.useState<any>([]);
  const [enabledPage, setEnabledPage] = React.useState(false);
  const { id } = useParams<RouteParams>();
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [searched, setSearched] = React.useState<string>('');
  const [order, setOrder] = React.useState<'desc' | 'asc'>('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);

  useEffect(() => {
    (async () => {
      console.log('Se dispara el useEffect');
      let gameTableResponse = null;
      let countResonse = null;
      gameTableResponse = await getListByComponent(
        `/v2/admin/withdraw?skip=${
          page * rowsPerPage
        }&limit=${rowsPerPage}&search=${searched}&orderBy${orderBy}&orderType=${order}`
      );
      if (gameTableResponse.status == 200) {
        countResonse = gameTableResponse.data.rows;
        gameTableResponse = gameTableResponse.data.table;
        setGameTable([...gameTable, ...gameTableResponse]);
        setCount(countResonse);
        console.log(gameTable);
      }
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      console.log('Se dispara el useEffect');
      let gameTableResponse = null;
      let countResonse = null;
      gameTableResponse = await getListByComponent(
        `/v2/admin/withdraw?skip=${
          page * rowsPerPage
        }&limit=${rowsPerPage}&search=${searched}&orderBy${orderBy}&orderType=${order}`
      );
      if (gameTableResponse.status == 200) {
        countResonse = gameTableResponse.data.rows;
        gameTableResponse = gameTableResponse.data.table;
        setGameTable([...gameTableResponse]);
        setCount(countResonse);
        console.log(gameTable);
      }
    })();
  }, [rowsPerPage]);

  const searchdData = async (searched: string) => {
    console.log('searchdData');
    console.log(page);
    let gameTableResponse = null;
    let countResonse = null;
    gameTableResponse = await getListByComponent(
      `/v2/admin/withdraw?skip=${
        page * rowsPerPage
      }&limit=${rowsPerPage}&search=${searched}&orderBy=${orderBy}&orderType=${order}`
    );
    if (gameTableResponse.status == 200) {
      countResonse = gameTableResponse.data.rows;
      gameTableResponse = gameTableResponse.data.table;
      setGameTable([...gameTableResponse]);
      setCount(countResonse);
      setSearched(searched);
    }
  };

  const OrderDataBy = async (orderBy: string, order: string, page: number) => {
    console.log('OrderBy');
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/v2/admin/withdraw?skip=${
        page * rowsPerPage
      }&limit=${rowsPerPage}&search=${searched}&orderBy=${orderBy}&orderType=${order}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data.table;
      console.log(gameTableResponse);
      setGameTable([...gameTableResponse]);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Affiliates" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Retiros
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Retiros</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GenericServerTable
            tableName={`Retiros`}
            rowsModel={gameTable}
            headCells={[
              /*{
                id: '_id',
                alignment: 'center',
                label: 'ID',
                type: 'text',
              },*/
              {
                id: 'user',
                alignment: 'center',
                label: 'Usuario',
                type: 'text',
              },
              {
                id: 'amount',
                alignment: 'center',
                label: 'Monto',
                type: 'money',
              },
              {
                id: 'status',
                alignment: 'center',
                label: 'Estado',
                type: 'text',
              },
              {
                id: '_id',
                alignment: 'center',
                label: 'Acciones',
                type: 'actions',
              },
            ]}
            count={count}
            rowPerPageOptions={[100, 250, 500]}
            rowsPerPageCurrent={rowsPerPage}
            onChangeRowsPerPage={(rowsPerPage: number, page: number) => {
              setRowsPerPage(rowsPerPage);
              setPage(page);
            }}
            onChangePage={(page: number) => {
              setRowsPerPage(rowsPerPage);
              setPage(page);
            }}
            onChangeSearch={(searched: string, page: number) => {
              searchdData(searched);
              setPage(page);
            }}
            onChangeSort={(orderBy: string, order: string, page: number) => {
              OrderDataBy(orderBy, order, page);
            }}
            actionsCells={(id: string) => {
              return (
                <>
                  <IconButton
                    aria-label="details"
                    component={NavLink}
                    to={`/player/withdraw/update/${id}`}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
