import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';

import { spacing, SpacingProps } from '@material-ui/system';
import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import CreateForm from '../../components/CreateForm';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonPropstype>(spacing);

function Private() {
  const [validationSchema, setValidationSchema] = React.useState<any>(
    Yup.object().shape({
      username: Yup.string()
        .max(255)
        .required('Se requiere un nombre de usuario'),
      name: Yup.string().max(255).required('Se requiere un nombre'),
      email: Yup.string()
        .email('Debe ser email válido')
        .max(255)
        .required('Un email es requerido'),
      phone: Yup.number()
        .min(10, 'Teléfono debe tener al menos 10 digitos')
        .integer('Solo números. Debe ser un telefono válido.')
        .required('Un telefono es requerido'),
    })
  );
  const [form, setForm] = React.useState<any>({
    username: {
      type: 'text',
      initialValue: '',
      name: 'username',
      label: 'Username',
      propsInput: {},
    },
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Name',
      propsInput: {},
    },
    email: {
      type: 'text',
      initialValue: '',
      name: 'email',
      label: 'Email',
      propsInput: {},
    },
    phone: {
      type: 'text',
      initialValue: '',
      name: 'phone',
      label: 'Phone',
      propsInput: {},
    },
  });
  React.useEffect(() => {
    (async () => {
      let { data, status } = await getListByComponent('/admin/user');
      if (status == 200) {
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: data[current],
            };
            return acc;
          }, {}),
        });
      }
    })();
  }, []);
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(`/admin/user`, values);
      setStatus({ success: true });
      setSubmitting(false);
    } catch (error: any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Private info
        </Typography>

        <CreateForm
          header={'Actualiza tu informacion'}
          description={
            'Utiliza esta herramienta para actualizar tu informacion'
          }
          submitLabel={'Actualizar tu informacion'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  );
}
function PrivateKey() {
  const [form, setForm] = React.useState<any>({
    key: {
      type: 'password',
      initialValue: '***************************************',
      name: 'name',
      label: 'Name',
      propsInput: {},
    },
  });

  const handleClickShowPassword = async () => {
    let { data, status } = await getListByComponent('/admin/casino/key');
    if (status === 200) {
      setForm({
        ...form,
        key: {
          ...form.key,
          initialValue: data.key,
          type: form.key.type == 'text' ? 'password' : 'text',
        },
      });
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          API KEY
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Aqui podras visualizar el API KEY de tu casino, No compartas esta
          informacion con nadie.
        </Typography>

        <Input
          type={form.key.type === 'text' ? 'text' : 'password'}
          value={form.key.initialValue}
          disabled
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {form.key.type === 'password' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </CardContent>
    </Card>
  );
}
function Settings() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Settings
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Settings</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PrivateKey />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
