import React from 'react';
import { AnalyticsPeriod } from '../../pages/dashboards/Analytics/types';
import {
  Withdraw,
  Deposit,
  TransactionStoreAction,
  AnalyticsDataTable,
} from './types';

export const TRANSACTIONS_INITIAL_STATE: TransactionsContextType = {
  activePlayers: 0,
  activePlayersPercentage: '',
  balance: 0,
  balanceData: [],
  balanceLabels: [],
  balancePercentage: '',
  deposits: [],
  depositsAnnual: 0,
  depositsMonthly: 0,
  depositsToday: 0,
  dispatch: (value: TransactionStoreAction) => {},
  getDashboardTansactionsData: async () => {},
  newPlayers: 0,
  newPlayersPercentage: '',
  pendingWithdrawals: 0,
  playerEffectiveness: '',
  playerEffectivenessPercentage: '',
  tables: [],
  trafficData: [],
  trafficLabels: [],
  updateDashboardAnalyticsData: async (period: AnalyticsPeriod) => {},
  updateDepositsList: async () => [],
  updateWithdrawalsList: async () => [],
  withdrawals: [],
};

export type TransactionsContextType = {
  activePlayers: number;
  balanceLabels: string[];
  balanceData: number[];
  balancePercentage: string;
  playerEffectivenessPercentage: string;
  activePlayersPercentage: string;
  newPlayersPercentage: string;
  balance: number;
  deposits: Deposit[];
  depositsToday: number;
  depositsMonthly: number;
  depositsAnnual: number;
  newPlayers: number;
  pendingWithdrawals: number;
  playerEffectiveness: string;
  tables: AnalyticsDataTable[];
  trafficLabels: string[];
  trafficData: number[];
  withdrawals: Withdraw[];
  dispatch: React.Dispatch<TransactionStoreAction>;
  getDashboardTansactionsData(): Promise<void>;
  updateWithdrawalsList(): Promise<Withdraw[]>;
  updateDepositsList(): Promise<Deposit[]>;
  updateDashboardAnalyticsData(period: AnalyticsPeriod): Promise<void>;
};

export const TransactionsContext = React.createContext<TransactionsContextType>(
  TRANSACTIONS_INITIAL_STATE
);

export * from './TransactionsStore';
export * from './types';
