import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { Helmet } from 'react-helmet-async';

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from '@material-ui/core';

import { spacing } from '@material-ui/system';

import { green, red } from '@material-ui/core/colors';

import Actions from './Actions';
import BarChart from './BarChart';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';
import Stats from './Stats';
import Table from './Table';
import {
  TransactionsContext,
  TransactionsContextType,
} from '../../../context/transactionsContext';
import {
  getDepositsMonth,
  getDepositsToday,
  getDepositsYear,
  getPendingWithdrawals,
} from '../../../services/transactionsServices';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

export const Default: React.FC<{}> = (props) => {
  const {
    getDashboardTansactionsData,
    depositsToday,
    depositsMonthly,
    depositsAnnual,
    pendingWithdrawals,
  } = useContext<TransactionsContextType>(TransactionsContext);

  React.useEffect(() => {
    (async () => {
      await getDashboardTansactionsData();
    })();

    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Default Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome back, Lucy! We've missed you.{' '}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Depositos del día"
            amount={`$ ${depositsToday}`}
            chip="Today"
            percentageText="+26%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Depósitos del mes"
            amount={`$ ${depositsMonthly}`}
            chip="Monthly"
            percentageText="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Depósitos del año"
            amount={`$ ${depositsAnnual}`}
            chip="Annual"
            percentageText="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Retiro pendientes"
            amount={`${pendingWithdrawals}`}
            chip="Total"
            percentageText="-9%"
            percentagecolor={red[500]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Table />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
