import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';

import { spacing } from '@material-ui/system';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface DepositProps {
  title: string;
  path: string;
  headerTitle: string;
  headerPath: string;
  children: React.ReactNode;
}

export const PageContainer: React.FC<DepositProps> = (props): JSX.Element => {
  return (
    <React.Fragment>
      <Helmet title={props.title} />
      <Typography variant="h3" gutterBottom display="inline">
        {props.title}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to={props.headerPath}>
          {props.headerTitle}
        </Link>
        <Typography>{props.title}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
