import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContextType, AuthContext } from '../../context/authContext';
import { PageContainer } from '../components/PageContainer';
import { CheckBox } from '@material-ui/icons';
import { PokerCard } from '../../components/PokerCard';
import GenericTableNew from '../../components/GenericTableNew';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
function RouletteDetail(props: any) {
  const history = useHistory();
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card
        style={{
          minWidth: 275,
          margin: 20,
        }}
      >
        <CardContent>
          <Box pt={2} pb={2}>
            <Typography variant="h5" component="h2">
              Dealer
            </Typography>
          </Box>
          <Divider />
          <Box pt={2} pb={2}>
            <Grid
              container
              direction="row"
              style={{ justifyContent: 'center' }}
            >
              {props.detailGameLog.dealer.hand.playerCards.map(
                (card: string, index: number) => (
                  <Grid>
                    <PokerCard
                      cardId={card}
                      style={{ margin: '10', width: '2.5em' }}
                      onClick={() => {}}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          <Box pt={2} pb={2}>
            <GenericTableNew
              tableName={`Detalle de Partida Blackjack`}
              rowsModel={props.detailGameLog.bets.filter((item: any) => {
                return item.main == props.playerId;
              })}
              headCells={[
                {
                  id: 'type',
                  alignment: 'center',
                  label: 'Tipo de apuesta',
                  type: 'text',
                },
                {
                  id: 'playerCards',
                  alignment: 'center',
                  label: 'Cartas',
                  type: 'actions',
                },
                {
                  id: 'bet',
                  alignment: 'center',
                  label: 'monto de apuesta',
                  type: 'number',
                },
                {
                  id: 'profit',
                  alignment: 'center',
                  label: 'ganacia',
                  type: 'number',
                },
                {
                  id: 'result',
                  alignment: 'center',
                  label: 'resultado',
                  type: 'text',
                },
                {
                  id: 'seatId',
                  alignment: 'center',
                  label: 'asiento',
                  type: 'text',
                },

                {
                  id: 'insurance',
                  alignment: 'center',
                  label: 'seguro',
                  type: 'text',
                },

                {
                  id: 'split',
                  alignment: 'center',
                  label: 'split',
                  type: 'text',
                },

                {
                  id: 'doubleDown',
                  alignment: 'center',
                  label: 'doblar',
                  type: 'text',
                },

                {
                  id: 'hand',
                  alignment: 'center',
                  label: 'Mano',
                  type: 'text',
                },
                {
                  id: '21_3Bet',
                  alignment: 'center',
                  label: '21+3 Apuesta',
                  type: 'text',
                },
                {
                  id: '21_3Profit',
                  alignment: 'center',
                  label: '21+3 Ganancia',
                  type: 'text',
                },
                {
                  id: 'ppBet',
                  alignment: 'center',
                  label: 'PP Apuesta',
                  type: 'text',
                },
                {
                  id: 'ppProfit',
                  alignment: 'center',
                  label: 'PP Ganancia',
                  type: 'text',
                },
              ]}
              serverPagination={true}
              disabledEmptyRows={true}
              disabledTableHeader={true}
              actionsCells={(playerCards: any) => {
                return playerCards.map((card: string, index: number) => (
                  <Grid>
                    <PokerCard
                      cardId={card}
                      style={{ margin: '10', width: '2.5em' }}
                      onClick={() => {}}
                    />
                  </Grid>
                ));
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default RouletteDetail;
