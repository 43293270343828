import React, { useReducer } from 'react';
import { UsersContext, UsersContextType, USERS_INITIAL_STATE } from '.';
import { getUsers } from '../../services/usersServices';
import { User, UserStoreAction } from './types';

type TablesProps = {
  children: React.ReactNode;
};

const reducer: React.Reducer<UsersContextType, UserStoreAction> = (
  state: UsersContextType,
  action: UserStoreAction
): UsersContextType => {
  switch (action.type) {
    case 'users':
      return { ...state, users: action.users };
    default:
      throw new Error();
  }
};

export const UsersStore: React.FC<TablesProps> = ({
  children,
}): JSX.Element => {
  const [context, dispatch] = useReducer(reducer, USERS_INITIAL_STATE);

  const updateUsersList = async (): Promise<User[]> => {
    let users: User[] = [];
    try {
      const { data, error } = await getUsers();
      if (!error) {
        users = data.map((user: any) => {
          return { ...user, id: user.id_user.toString() };
        });
        dispatch({ type: 'users', users });
      }
    } catch (error) {}

    return users;
  };

  return (
    <UsersContext.Provider
      value={{
        ...context,
        dispatch,
        updateUsersList,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
