//Deprecated
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';
// import {
//   PlayersContext,
//   PlayersContextType,
// } from '../../context/playersContext';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

interface ChipPropstype extends SpacingProps {
  shipped?: number;
  processing?: number;
  cancelled?: number;
}

const Chip = styled(MuiChip)<ChipPropstype>`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export type RowType = {
  [key: string]: string | number | boolean;
  id: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'desc' | 'asc', orderBy: string) {
  return order === 'desc'
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

export type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  disablePadding?: boolean;
};

type EnhancedTableHeadPropsType = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  disabledCheckboxColumn: boolean;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
};
const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    disabledCheckboxColumn,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {disabledCheckboxColumn ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        ) : null}
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarPropsType = { numSelected: number; tableName: string };
const EnhancedTableToolbar = (props: EnhancedTableToolbarPropsType) => {
  // Here was 'let'
  const { numSelected, tableName } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {tableName}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

export const EnhancedTable: React.FC<{
  rowsModel: RowType[];
  headCells: HeadCell[];
  tableName: string;
  disabledEmptyRows?: boolean;
  disabledCheckboxColumn?: boolean;
  selectAllCheckbox?: boolean;
  serverPagination?: boolean;
  updateRowsModelByPagination?(rows: number, page: number): void;
  setSelectedRows?(selectedRows: number): void;
  tableCells(rowNumber: number): JSX.Element;
  onCheckboxClick?(checkboxes: string[]): void;
}> = ({
  rowsModel,
  headCells,
  tableName,
  tableCells,
  selectAllCheckbox = false,
  disabledEmptyRows = false,
  disabledCheckboxColumn = false,
  serverPagination = false,
  updateRowsModelByPagination = (rows: number, page: number) => {},
  setSelectedRows = (selectedRows: number) => {},
  onCheckboxClick = (checkboxes: string[]) => {},
}): JSX.Element => {
  const checkboxAll = React.useRef(null);
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('customer');
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = rowsModel.map((n: RowType) => n.id);
      setSelected(newSelecteds);
      onCheckboxClick(newSelecteds);
      setSelectedRows(newSelecteds.length);
      return;
    }
    setSelectedRows(0);
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (newSelected.length == 0) {
      const newSelecteds: Array<string> = rowsModel.map((n: RowType) => n.id);
      setSelectedRows(newSelecteds.length);
      onCheckboxClick(newSelected);
    } else {
      setSelectedRows(newSelected.length);
      onCheckboxClick(newSelected);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rowsModel.length - page * rowsPerPage);

  useEffect(() => {
    if (selectAllCheckbox) {
      const newSelecteds: Array<string> = rowsModel.map((n: RowType) => n.id);
      setSelected(newSelecteds);
      onCheckboxClick(newSelecteds);
      setSelectedRows(newSelecteds.length);
    }
  }, [rowsModel.length > 0]);
  useEffect(() => {
    if (serverPagination) {
      (async () => {
        updateRowsModelByPagination(rowsPerPage, page);
      })();
    }
    return () => {};
  }, [page, rowsPerPage]);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          tableName={tableName}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsModel.length}
              headCells={headCells}
              disabledCheckboxColumn={disabledCheckboxColumn}
            />
            <TableBody>
              {stableSort(rowsModel, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: RowType, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      {disabledCheckboxColumn ? (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                      ) : null}
                      {tableCells(index)}
                    </TableRow>
                  );
                })}
              {!disabledEmptyRows && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsModel.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export interface GenericTableProps<T extends RowType> {
  rowsModel: T[];
  tableName: string;
  headCells: HeadCell[];
  disabledEmptyRows?: boolean;
  disabledTableHeader?: boolean;
  disabledCheckboxColumn?: boolean;
  selectAllCheckbox?: boolean;
  setSelectedRows?(rowsNumber: number): void;
  tableCells(rowNumber: number): JSX.Element;
  onCheckboxClick?(checkboxes: string[]): void;
}

export const GenericTable = <T extends RowType>(
  props: PropsWithChildren<GenericTableProps<T>>
) => {
  return (
    <React.Fragment>
      <Helmet title="Players" />
      {!props.disabledTableHeader ? (
        <React.Fragment>
          <Grid justify="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                {props.tableName}
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} exact to="/">
                  Dashboard
                </Link>
                <Typography>{props.tableName}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Button mr={2} variant="contained" color="primary">
                <AddIcon />
                Agregar
              </Button>
              <Button mr={2} variant="contained" color="primary">
                <EditIcon />
                Editar
              </Button>
              <Button variant="contained" color="default">
                <DeleteIcon />
                Borrar
              </Button>
            </Grid>
          </Grid>

          <Divider my={6} />
        </React.Fragment>
      ) : null}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
