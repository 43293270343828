import React, { useReducer } from 'react';
import { TablesContext, TablesContextType, TABLES_INITIAL_STATE } from '.';
import { getTables } from '../../services/tablesService';
import { GameTable, TableStoreAction } from './types';

type TablesProps = {
  children: React.ReactNode;
};

const reducer: React.Reducer<TablesContextType, TableStoreAction> = (
  state: TablesContextType,
  action: TableStoreAction
): TablesContextType => {
  switch (action.type) {
    case 'gameTables':
      return { ...state, gameTables: action.gameTables };
    default:
      throw new Error();
  }
};

export const TablesStore: React.FC<TablesProps> = ({
  children,
}): JSX.Element => {
  const [context, dispatch] = useReducer(reducer, TABLES_INITIAL_STATE);

  const normalizeTableModel = (table: any): GameTable => {
    // server models are not yet defined. Thus making an interface is still a waste
    return {
      id: table._id || table.id_table || '',
      dealer: table.dealer || '',
      isOpen: table.isOpen || false,
      tableName: table.tableName || '',
      tableNumber: table.tableNumber || table.table_code || 0,
      tablePicture: table.tablePicture || '',
      gameType: table.gameType || '',
      category: table.category || '',
      createdAt: table.createdAt || table.register_date || '',
      updatedAt: table.updatedAt || table.modified_date || '',
      version: table.version || 0,
      viewers: table.viewers || 0,
      timerBets: table.timerBets || table.timer_bets,
      timerDecision: table.timerDecision || table.timer_decision,
      timerInsurance: table.timerInsurance || table.timer_insurance,
      timerResetGame: table.timerResetGame || table.timer_resetGame,
    } as GameTable;
  };

  const updateTablesList = async (): Promise<GameTable[]> => {
    let gamesTables: GameTable[] = [];
    try {
      const { data, error } = await getTables();
      if (!error) {
        const gameTables = data.map((raw: any) => normalizeTableModel(raw));
        dispatch({ type: 'gameTables', gameTables });
      }
    } catch (error) {}

    return gamesTables;
  };

  return (
    <TablesContext.Provider
      value={{
        ...context,
        dispatch,
        updateTablesList,
      }}
    >
      {children}
    </TablesContext.Provider>
  );
};
