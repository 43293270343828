import React from 'react';
import { Dealer, DealerStoreAction, DealerAnalytics } from './types';

export const DEALERS_INITIAL_STATE: DealersContextType = {
  dealers: [],
  dealerAnalyticsList: [],
  dispatch: (value: DealerStoreAction) => {},
  updateDealersList: async () => [],
  getDealersAnalytics: async (id: string[]) => [],
};

export type DealersContextType = {
  dealers: Dealer[];
  dealerAnalyticsList: DealerAnalytics[];
  dispatch: React.Dispatch<DealerStoreAction>;
  updateDealersList(): Promise<Dealer[]>;
  getDealersAnalytics(id: string[]): Promise<DealerAnalytics[]>;
};

export const DealersContext = React.createContext<DealersContextType>(
  DEALERS_INITIAL_STATE
);

export * from './DealersStore';
export * from './types';
