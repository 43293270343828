/* eslint-disable import/first */
import React from 'react';

import async from '../components/Async';

import {
  BookOpen,
  // Briefcase,
  // Calendar as CalendarIcon,
  // CheckSquare,
  // CreditCard,
  // Grid,
  // Heart,
  Layout,
  List,
  Monitor,
  // ShoppingCart,
  // PieChart,
  Sliders,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  User as UserIcon,
  Box,
  Archive as TableIcon,
  Smile as DealerIcon,
  Dribbble as PlayerIcon,
  DollarSign as DollarSignIcon,
} from 'react-feather';

import { Casino as CasinoIcon } from '@material-ui/icons';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from '../components/AuthGuard';

// Auth components
import SignIn from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import Settings from '../pages/settings/Settings';
import { CreateDealer } from '../pages/dealers/CreateDealer';
import { CreateUser } from '../pages/users/CreateUser';
import GameTable from '../pages/gameTables/GameTable';
import Casino from '../pages/casino/Casino';
import Staff from '../pages/staff/Staff';
import ResetPassword from '../pages/auth/ResetPassword';
import Page404 from '../pages/auth/Page404';
import Page500 from '../pages/auth/Page500';

// Components components
// import Accordion from "../pages/components/Accordion";
// import Alerts from "../pages/components/Alerts";
// import Avatars from "../pages/components/Avatars";
// import Badges from "../pages/components/Badges";
// import Buttons from "../pages/components/Buttons";
// import Cards from "../pages/components/Cards";
// import Chips from "../pages/components/Chips";
// import Dialogs from "../pages/components/Dialogs";
// import Lists from "../pages/components/Lists";
// import Menus from "../pages/components/Menus";
// import Pagination from "../pages/components/Pagination";
// import Progress from "../pages/components/Progress";
// import Snackbars from "../pages/components/Snackbars";
// import Tooltips from "../pages/components/Tooltips";

// Dashboards components
import { Default } from '../pages/dashboards/Default';
// const Default = async(() => import('../pages/dashboards/Default'));
const Analytics = async(() => import('../pages/dashboards/Analytics'));
const Marketing = async(() => import('../pages/dashboards/Marketing'));
const SaaS = async(() => import('../pages/dashboards/SaaS'));

// Forms components
// import SelectionCtrls from '../pages/forms/SelectionControls';
// import Selects from '../pages/forms/Selects';
// import TextFields from '../pages/forms/TextFields';
// const Pickers = async(() => import('../pages/forms/Pickers'));
// const Dropzone = async(() => import('../pages/forms/Dropzone'));
// const Editors = async(() => import('../pages/forms/Editors'));
// const Formik = async(() => import('../pages/forms/Formik'));

// Icons components
// import MaterialIcons from '../pages/icons/MaterialIcons';
// const FeatherIcons = async(() => import('../pages/icons/FeatherIcons'));

// Pages components
import Blank from '../pages/pages/Blank';
// import InvoiceDetails from "../pages/pages/InvoiceDetails";
// import InvoiceList from "../pages/pages/InvoiceList";
// import Orders from '../pages/pages/Orders';
import Pricing from '../pages/pages/Pricing';
import adminSettings from '../pages/settings/Settings';
// import Projects from "../pages/pages/Projects";
import Chat from '../pages/pages/Chat';
import { DepositsList } from '../pages/deposits/DepositsList';
import { WithdrawList } from '../pages/withdraw/WithdrawList';
import { WithdrawUpdate } from '../pages/withdraw/WithdrawUpdate';
import { BonusPlayerList } from '../pages/players/BonusPlayerList';
import { PlayerProfile } from '../pages/players/PlayerProfile';
// const PlayerProfile = async(() => import('../pages/players/PlayerProfile'));
// const Tasks = async(() => import("../pages/pages/Tasks"));
// const Calendar = async(() => import("../pages/pages/Calendar"));

// // Tables components
// import SimpleTable from '../pages/tables/SimpleTable';
// import AdvancedTable from '../pages/tables/AdvancedTable';

// // Chart components
// const Chartjs = async(() => import("../pages/charts/Chartjs"));

// // Maps components
// const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
import Welcome from '../pages/docs/Welcome';
import GettingStarted from '../pages/docs/GettingStarted';
import EnvironmentVariables from '../pages/docs/EnvironmentVariables';
import Deployment from '../pages/docs/Deployment';
import Theming from '../pages/docs/Theming';
import StateManagement from '../pages/docs/StateManagement';
import APICalls from '../pages/docs/APICalls';
import ESLintAndPrettier from '../pages/docs/ESLintAndPrettier';
import Support from '../pages/docs/Support';
import Changelog from '../pages/docs/Changelog';

// Landing
import Landing from '../pages/presentation/Landing';

// Protected routes
import ProtectedPage from '../pages/protected/ProtectedPage';
import Users from '../pages/users/Users';
import { Players } from '../pages/players/Players';
import GameDetailBaccarat from '../pages/players/GameDetailBaccarat';
import GameDetailBlackjack from '../pages/players/GameDetailBlackjack';
import GameDetailRoulette from '../pages/players/GameDetailRoulette';
import Dealers from '../pages/dealers/Dealers';
import { UpdateAffiliate } from '../pages/references/affiliates/UpdateAffiliate';
import { AffiliateDetail } from '../pages/references/affiliates/AffiliateDetail';
import { BonusList } from '../pages/promotion/BonusList';
import { UpdateBonus } from '../pages/promotion/UpdateBonus';
import { CreateBonus } from '../pages/promotion/CreateBonus';
import { UpdatePlayer } from '../pages/players/UpdatePlayer';
import { UpdateDealer } from '../pages/dealers/UpdateDealer';
import { UpdateUser } from '../pages/users/UpdateUser';
import { UpdateGameTable } from '../pages/gameTables/UpdateGameTable';
import { UpdateCasino } from '../pages/casino/UpdateCasino';
import { UpdateStaff } from '../pages/staff/UpdateStaff';
import UpdateBaccaratTable from '../pages/gameTables/UpdateBaccaratTable';
import UpdateBlackjackTable from '../pages/gameTables/UpdateBlackjackTable';
import UpdateRouletteTable from '../pages/gameTables/UpdateRouletteTable';

import CreateBaccaratTable from '../pages/gameTables/CreateBaccaratTable';
import CreateBlackjackTable from '../pages/gameTables/CreateBlackjackTable';
import CreateRouletteTable from '../pages/gameTables/CreateRouletteTable';
import { Deposits } from '../pages/financial/Deposits';
import { Withdrawals } from '../pages/financial/Withdrawals';
import { UserSignUp } from '../pages/users/UserSignUp';
import { CreateCasino } from '../pages/casino/CreateCasino';
import { CreateStaff } from '../pages/staff/CreateStaff';
import { Influencers } from '../pages/references/influencers/Influencers';
import { Pages } from '../pages/references/pages/pages';
import { Campaign } from '../pages/references/campaign/campaign';
import { Affiliates } from '../pages/references/affiliates/Affiliates';

// Sidebar Routes in order
// 1. Dashboard
const dashboardsRoutes = {
  id: 'Dashboard',
  path: '/dashboard',
  header: 'Pages',
  icon: <Sliders />,
  containsHome: true,
  meta: {
    requiresAuth: true,
    allowedRoles: ['superadmin'],
  },
  children: null,
  component: Analytics,
};

// 2. Marketing
const dashboardsmktRoutes = {
  id: 'Marketing',
  path: '/marketing',
  //header: 'Pages',
  icon: <Sliders />,
  containsHome: true,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
  component: Marketing,
};

// 3. Influencers
const influencerRoutes = {
  id: 'Infuencers',
  path: '/influencers',
  icon: <UserIcon />,
  component: Influencers,
  guard: AuthGuard,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
};

// 4. Campañas de Redes Sociales
const campaignRoutes = {
  id: 'Campañas de Redes',
  path: '/campaigns',
  icon: <UserIcon />,
  component: Campaign,
  guard: AuthGuard,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
};

// 5. Paginas de referencia
const referencePageRoutes = {
  id: 'Páginas de Referencia',
  path: '/pages',
  icon: <UserIcon />,
  component: Pages,
  guard: AuthGuard,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
};

// 4. Afiliados
const affiliateRoutes = {
  id: 'Afiliados',
  path: '/affiliates',
  icon: <UserIcon />,
  component: Affiliates,
  guard: AuthGuard,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
};

// 4. Administradores
const usersRoutes = {
  id: 'Administradores',
  path: '/users',
  icon: <UsersIcon />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
  component: Users,
  guard: AuthGuard,
};

// 5. Mesas de Juego
const gameTablesRoutes = {
  id: 'Mesas de Juego',
  path: '/tables',
  icon: <TableIcon />,
  component: GameTable,
  guard: AuthGuard,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null,
};

// 6. Dealers
const dealersRoutes = {
  id: 'Dealers',
  path: '/dealers',
  icon: <DealerIcon />,
  component: Dealers,
  guard: AuthGuard,
  meta: {
    requiresAuth: true,
    allowedRoles: ['superadmin'],
  },
  children: null,
};

// 7. Jugadores
const pagesRoutes = {
  id: 'Pages',
  path: '/pages',
  icon: <Layout />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  children: [
    {
      path: '/pages/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/pages/pricing',
      name: 'Pricing',
      component: Pricing,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/pages/chat',
      name: 'Chat',
      component: Chat,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/pages/blank',
      name: 'Blank Page',
      component: Blank,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
  ],
  component: null,
  guard: AuthGuard,
};

// 8. Jugadores
const playersRoutes = {
  id: 'Jugadores',
  path: '/players',
  icon: <PlayerIcon />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  component: Players,
  children: null,
};

// 9. Bonos
const bonusRoutes = {
  id: 'Bonos',
  path: '/promotion',
  icon: <PlayerIcon />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  component: BonusList,
  children: null,
};

// 10. Retiros
const withdrawalsRoutes = {
  id: 'Retiros',
  path: '/withdrawals',
  icon: <DollarSignIcon />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  component: WithdrawList,
  children: null,
};

// THIS ROUTE WILL BE HIDDEN UNTIL WE SEPARATE CASINO FROM LVV
// 11. Casinos
/*const casinoRoutes = {
  id: 'Casinos',
  path: '/tables',
  icon: <CasinoIcon />,
  header: 'Administrador',
  component: null,
  meta: {
    requiresAuth: true,
    allowedRoles: ['superadmin'],
  },
  children: [
    {
      path: '/casino/create',
      name: 'Crear Casino',
      component: CreateCasino,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['superadmin'],
      },
    },
    {
      path: '/casino/list',
      name: 'Lista de Casinos',
      component: Casino,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['superadmin'],
      },
    },
  ],
};*/

/*
const staffRoutes = {
  id: 'Users',
  path: '/staff/list',
  icon: <UsersIcon />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: null [
    {
      path: '/staff/create',
      name: 'Crear administrador',
      component: CreateStaff,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/staff/list',
      name: 'Lista de administradores',
      component: Staff,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
  ],,
  component: Staff,
  guard: AuthGuard,
};*/
const playerProfileRoutes = {
  id: 'Perfil del Jugador',
  path: '/playerProfile/:playerId',
  icon: <Box />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  component: PlayerProfile,
  children: null,
};

/*const userRoutes = {
  id: 'User',
  path: '/users',
  icon: <UserIcon />,
  component: null,
  children: [
    {
      path: '/users/create',
      name: 'Crear Dealer',
      component: CreateUser,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/users/list',
      name: 'Lista Dealers',
      component: Dealers,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
  ],
};*/

const dealerRoutesWithParams = {
  id: 'Dealers',
  path: '/dealers/update/:id',
  icon: <UserIcon />,
  component: null,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin', 'superadmin'],
  },
  children: [
    {
      path: '/game/detail/roulette/:id/:idPlayer',
      name: 'Lista Dealers',
      component: GameDetailRoulette,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/game/detail/baccarat/:id/:idPlayer',
      name: 'Lista Dealers',
      component: GameDetailBaccarat,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/game/detail/blackjack/:id/:idPlayer',
      name: 'Lista Dealers',
      component: GameDetailBlackjack,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    //
    {
      path: '/player/update/:id',
      name: 'Lista Dealers',
      component: UpdatePlayer,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/dealer/create',
      name: 'Crear Dealer',
      component: CreateDealer,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/promotion/create',
      name: 'Crear de Promocion',
      component: CreateBonus,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/promotion/:id',
      name: 'Actualizacion de Promociones',
      component: UpdateBonus,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/player/promotion/:id',
      name: 'Lista de Promociones',
      component: BonusPlayerList,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/player/deposits/:id',
      name: 'Lista de Depositos',
      component: DepositsList,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/player/withdraw/:id',
      name: 'Lista de Retiros',
      component: WithdrawList,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/player/withdraw/update/:id',
      name: 'Actualiza Retiro',
      component: WithdrawUpdate,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/dealers/update/:id',
      name: 'Lista Dealers',
      component: UpdateDealer,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['superadmin'],
      },
    },
    {
      path: '/users/update/:id',
      name: 'Actualizar Usuario',
      component: UpdateUser,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/table/update/:id',
      name: 'Lista Dealers',
      component: UpdateGameTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/table/baccarat/:id',
      name: 'Actualizar Mesa de Baccarat',
      component: UpdateBaccaratTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/table/blackjack/:id',
      name: 'Actualizar Mesa de Blackjack',
      component: UpdateBlackjackTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/table/roulette/:id',
      name: 'Actualizar Mesa de Ruleta',
      component: UpdateRouletteTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },

    {
      path: '/table/baccarat',
      name: 'Crear Mesa de Baccarat',
      component: CreateBaccaratTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/table/blackjack',
      name: 'Crear Mesa de Blackjack',
      component: CreateBlackjackTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/table/roulette',
      name: 'Crear Mesa de Ruleta',
      component: CreateRouletteTable,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    //casino
    {
      path: '/casino/:id',
      name: 'Actualizacion de Casino',
      component: UpdateCasino,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['superadmin'],
      },
    },
    //staff
    {
      path: '/staff/:id',
      name: 'Actualizacion de Administrador',
      component: UpdateStaff,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/affiliate/update/:id',
      name: 'Actualizacion de afiliado',
      component: UpdateAffiliate,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
    {
      path: '/affiliate/:id',
      name: 'Detalle de afiliado',
      component: AffiliateDetail,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin', 'superadmin'],
      },
    },
  ],
};

const transactionsRoutes = {
  id: 'Transacciones',
  path: '/transactions',
  icon: <DollarSignIcon />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  children: [
    {
      path: '/transactions/deposits',
      name: 'Depósitos',
      component: Deposits,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/transactions/withdrawals',
      name: 'Retiros',
      component: Withdrawals,
      guard: AuthGuard,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
  ],
  component: null,
};

const authRoutes = {
  id: 'Auth',
  path: '/auth',
  icon: <UsersIcon />,
  meta: {
    requiresAuth: false,
    allowedRoles: [],
  },
  children: [
    {
      path: '/auth/sign-in',
      name: 'Sign In',
      component: SignIn,
      meta: {
        requiresAuth: false,
        allowedRoles: [],
      },
    },
    {
      path: '/auth/sign-up',
      name: 'Sign Up',
      component: SignUp,
      meta: {
        requiresAuth: false,
        allowedRoles: [],
      },
    },
    {
      path: '/auth/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        allowedRoles: [],
      },
    },
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404,
      meta: {
        requiresAuth: false,
        allowedRoles: [],
      },
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500,
      meta: {
        requiresAuth: false,
        allowedRoles: [],
      },
    },
  ],
  component: null,
};

const landingRoutes = {
  id: 'Landing Page',
  path: '/',
  header: 'Docs',
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  icon: <Monitor />,
  component: Landing,
  children: null,
  guard: AuthGuard,
};

const settingsRoutes = {
  id: 'Settings',
  path: '/settings',
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  icon: <Monitor />,
  component: adminSettings,
  children: null,
  guard: AuthGuard,
};
const documentationRoutes = {
  id: 'Documentation',
  path: '/documentation',
  header: 'Material App',
  icon: <BookOpen />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  children: [
    {
      path: '/documentation/welcome',
      name: 'Welcome',
      component: Welcome,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/getting-started',
      name: 'Getting Started',
      component: GettingStarted,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/environment-variables',
      name: 'Environment Variables',
      component: EnvironmentVariables,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/deployment',
      name: 'Deployment',
      component: Deployment,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/theming',
      name: 'Theming',
      component: Theming,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/state-management',
      name: 'State Management',
      component: StateManagement,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/api-calls',
      name: 'API Calls',
      component: APICalls,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/eslint-and-prettier',
      name: 'ESLint & Prettier',
      component: ESLintAndPrettier,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
    {
      path: '/documentation/support',
      name: 'Support',
      component: Support,
      meta: {
        requiresAuth: true,
        allowedRoles: ['admin'],
      },
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: 'Changelog',
  path: '/changelog',
  badge: 'v2.0.3',
  icon: <List />,
  meta: {
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  component: Changelog,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: 'Private',
  path: '/private',
  meta: {
    requiresAuth: false,
    allowedRoles: [],
  },
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export let dashboardLayoutRoutes = [
  dashboardsRoutes,
  dashboardsmktRoutes,
  pagesRoutes,
  settingsRoutes,
  gameTablesRoutes,
  // staffRoutes,
  influencerRoutes,
  campaignRoutes,
  referencePageRoutes,
  affiliateRoutes,
  usersRoutes,
  playerProfileRoutes,
  dealersRoutes,
  playersRoutes,
  bonusRoutes,
  transactionsRoutes,
  // casinoRoutes, <- HIDDEN UNTIL SEPARARTION OF CASINO FROM LVV
  withdrawalsRoutes,
  documentationRoutes,
  changelogRoutes,
  dealerRoutesWithParams,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  dashboardsmktRoutes,
  pagesRoutes,
  settingsRoutes,
  // projectsRoutes,
  // staffRoutes,
  influencerRoutes,
  campaignRoutes,
  referencePageRoutes,
  affiliateRoutes,
  usersRoutes,
  gameTablesRoutes,
  dealersRoutes,
  playersRoutes,
  bonusRoutes,
  transactionsRoutes,
  // casinoRoutes, <- HIDDEN UNTIL SEPARARTION OF CASINO FROM LVV,
  withdrawalsRoutes,
  documentationRoutes,
  changelogRoutes,
];
