import React, { ChangeEventHandler, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

//import GenericTableNew from '../../components/GenericTableNew';
import { GenericServerTable } from '../../components/GenericServerTable';
import { Helmet } from 'react-helmet-async';
import axios from '../../utils/axios';

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Typography,
} from '@material-ui/core';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Image as ImageIcon,
  Pageview as PageviewIcon,
} from '@material-ui/icons';

import { spacing } from '@material-ui/system';
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Players: React.FC<any> = (props): JSX.Element => {
  const [gameTable, setGameTable] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [searched, setSearched] = React.useState<string>('');
  const [order, setOrder] = React.useState<'desc' | 'asc'>('desc');
  const [orderBy, setOrderBy] = React.useState('');

  useEffect(() => {
    (async () => {
      let gameTableResponse = null;
      let countResonse = null;
      gameTableResponse = await axios.get(`/v2/admin/players`, {
        params: {
          skip: page * rowsPerPage,
          limit: rowsPerPage,
          search: searched,
          orderBy: orderBy,
          orderType: order,
        },
      });
      if (gameTableResponse.status == 200) {
        countResonse = gameTableResponse.data.rows;
        gameTableResponse = gameTableResponse.data.table;
        setGameTable([...gameTable, ...gameTableResponse]);
        setCount(countResonse);
        console.log(gameTable);
      }
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      console.log('Se dispara el useEffect');
      let gameTableResponse = null;
      let countResonse = null;
      gameTableResponse = await axios.get(`/v2/admin/players`, {
        params: {
          skip: page * rowsPerPage,
          limit: rowsPerPage,
          search: searched,
          orderBy: orderBy,
          orderType: order,
        },
      });
      if (gameTableResponse.status == 200) {
        countResonse = gameTableResponse.data.rows;
        gameTableResponse = gameTableResponse.data.table;
        setGameTable([...gameTableResponse]);
        setCount(countResonse);
      }
    })();
  }, [rowsPerPage]);

  const searchdData = async (searched: string) => {
    console.log('searchdData');
    console.log(page);
    let gameTableResponse = null;
    let countResonse = null;
    gameTableResponse = await axios.get(`/v2/admin/players`, {
      params: {
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        search: searched,
        orderBy: orderBy,
        orderType: order,
      },
    });
    if (gameTableResponse.status == 200) {
      countResonse = gameTableResponse.data.rows;
      gameTableResponse = gameTableResponse.data.table;
      setGameTable([...gameTableResponse]);
      setCount(countResonse);
      setSearched(searched);
    }
  };

  const OrderDataBy = async (orderBy: string, order: string, page: number) => {
    console.log('OrderBy');
    let gameTableResponse = null;
    gameTableResponse = await axios.get(`/v2/admin/players`, {
      params: {
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        search: searched,
        orderBy: orderBy,
        orderType: order,
      },
    });
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data.table;
      console.log(gameTableResponse);
      setGameTable([...gameTableResponse]);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Jugadores" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Jugadores
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Jugadores</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GenericServerTable
            tableName={`Players`}
            rowsModel={gameTable}
            headCells={[
              {
                id: 'id_player',
                alignment: 'center',
                label: 'Player ID',
                type: 'text',
              },
              {
                id: 'login',
                alignment: 'center',
                label: 'Nombre de Usuario',
                type: 'text',
              },
              {
                id: 'test_user',
                alignment: 'center',
                label: 'Es Usuario Prueba',
                type: 'chip',
              },
              {
                id: 'balance',
                alignment: 'center',
                label: 'Balance',
                type: 'money',
              },
              {
                id: 'address',
                alignment: 'center',
                label: 'Dirección',
                type: 'text',
              },
              {
                id: 'affiliate_code',
                alignment: 'center',
                label: 'Codigo de Afiliación',
                type: 'text',
              },
              {
                id: 'affiliate_user',
                alignment: 'center',
                label: 'Afiliación del usuario',
                type: 'text',
              },
              {
                id: 'balance_last_update',
                alignment: 'center',
                label: 'Última actualización de balance',
                type: 'text',
              },
              {
                id: 'cab_cust_ref',
                alignment: 'center',
                label: 'Cab. Cust. Ref.',
                type: 'text',
              },
              {
                id: 'city',
                alignment: 'center',
                label: 'Ciudad',
                type: 'text',
              },
              {
                id: 'country',
                alignment: 'center',
                label: 'Pais',
                type: 'text',
              },
              {
                id: 'district',
                alignment: 'center',
                label: 'Distrito/Municipio',
                type: 'text',
              },
              {
                id: 'email',
                alignment: 'center',
                label: 'Email',
                type: 'text',
              },
              {
                id: 'failed_login_attempts',
                alignment: 'center',
                label: 'Logins fallidos',
                type: 'text',
              },
              {
                id: 'fraud_code',
                alignment: 'center',
                label: 'Codigo de fraude',
                type: 'text',
              },
              {
                id: 'gender',
                alignment: 'center',
                label: 'Genero',
                type: 'text',
              },
              {
                id: 'lastname',
                alignment: 'center',
                label: 'Apellido',
                type: 'text',
              },
              {
                id: 'name',
                alignment: 'center',
                label: 'Nombre',
                type: 'text',
              },
              {
                id: 'phone',
                alignment: 'center',
                label: 'Telefono',
                type: 'text',
              },
              {
                id: 'register_date',
                alignment: 'center',
                label: 'Fecha Registro',
                type: 'text',
              },
              {
                id: 'state',
                alignment: 'center',
                label: 'Estado',
                type: 'text',
              },
              {
                id: 'zipcode',
                alignment: 'center',
                label: 'Codigo Postal',
                type: 'text',
              },
              {
                id: 'id_player',
                alignment: 'center',
                label: 'Acciones',
                type: 'actions',
              },
              //{ id: 'actions', alignment: 'center', label: 'Acciones' },
            ]}
            count={count}
            rowPerPageOptions={[100, 250, 500]}
            rowsPerPageCurrent={rowsPerPage}
            onChangeRowsPerPage={(rowsPerPage: number, page: number) => {
              setRowsPerPage(rowsPerPage);
              setPage(page);
            }}
            onChangePage={(page: number) => {
              setRowsPerPage(rowsPerPage);
              setPage(page);
            }}
            onChangeSearch={(searched: string, page: number) => {
              searchdData(searched);
              setPage(page);
            }}
            onChangeSort={(orderBy: string, order: string, page: number) => {
              OrderDataBy(orderBy, order, page);
            }}
            actionsCells={(id: string) => {
              return (
                <>
                  <Grid container>
                    <Grid item xs={6}>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={`/playerProfile/${id}`}
                      >
                        <PageviewIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        to={`/player/update/${id}`}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
