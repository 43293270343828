import React, { useReducer } from 'react';
import { PlayersContext, PlayersContextType, PLAYERS_INITIAL_STATE } from '.';
import {
  getPlayer,
  getPlayerDeposits,
  getPlayerGamelog,
  getPlayers,
  getPlayerWithdrawals,
} from '../../services/playersServices';
import {
  BaccaratGamelog,
  BlackjackGamelog,
  GamelogRecord,
  GameType,
  Player,
  PlayerStoreAction,
  RouletteGamelog,
  GameTransaction,
} from './types';

type TablesProps = {
  children: React.ReactNode;
};

const reducer: React.Reducer<PlayersContextType, PlayerStoreAction> = (
  state: PlayersContextType,
  action: PlayerStoreAction
): PlayersContextType => {
  switch (action.type) {
    case 'players':
      return { ...state, players: action.players };
    case 'player':
      return { ...state, player: action.player };
    case 'playerGamelog':
      return { ...state, playerGamelog: action.playerGamelog };
    case 'playerDeposits':
      return { ...state, playerDeposits: action.playerDeposits };
    case 'playerWithdrawals':
      return { ...state, playerWithdrawals: action.playerWithdrawals };
    default:
      throw new Error();
  }
};

export const PlayersStore: React.FC<TablesProps> = ({
  children,
}): JSX.Element => {
  const [context, dispatch] = useReducer(reducer, PLAYERS_INITIAL_STATE);

  const updatePlayersList = async (): Promise<Player[]> => {
    let players: Player[] = [];
    try {
      const { data, error } = await getPlayers();
      if (!error) {
        players = data.map((player: any) => {
          return { ...player, id: player.id_player.toString() };
        });
        dispatch({ type: 'players', players });
      }
    } catch (error) {}

    return players;
  };

  const getPlayerGamelogTable = async (
    playerId: string | number,
    gameType: GameType,
    limit?: number,
    skip?: number
  ): Promise<void> => {
    try {
      const { data, error } = await getPlayerGamelog(
        playerId,
        gameType,
        limit,
        skip
      );
      if (!error) {
        switch (gameType) {
          case GameType.blackjack:
            const bjLogs: GameTransaction[] = data;
            dispatch({
              type: 'playerGamelog',
              playerGamelog: bjLogs.map((log: GameTransaction) => {
                console.log('GameTransaction', log);
                return {
                  id: log.gamelog,
                  tableId: log.tableId,
                  bet: log.totalBet,
                  date: log.createdAt,
                  game: log.game,
                  profit: log.amount,
                } as GamelogRecord;
              }),
            });
            break;
          case GameType.baccarat:
            const baccLogs: GameTransaction[] = data;
            dispatch({
              type: 'playerGamelog',
              playerGamelog: baccLogs.map((log: GameTransaction) => {
                return {
                  id: log.gamelog.toString(),
                  tableId: log.tableId.toString(),
                  bet: log.totalBet,
                  date: log.createdAt,
                  game: log.game,
                  profit: log.amount,
                } as GamelogRecord;
              }),
            });
            break;
          case GameType.roulette:
            const rouletteLogs: GameTransaction[] = data;
            dispatch({
              type: 'playerGamelog',
              playerGamelog: rouletteLogs.map((log: GameTransaction) => {
                return {
                  id: log.gamelog.toString(),
                  tableId: log.tableId.toString(),
                  bet: log.totalBet,
                  date: log.createdAt,
                  game: log.game,
                  profit: log.amount,
                } as GamelogRecord;
              }),
            });
            break;
          default:
            break;
        }
      }
    } catch (error) {}
  };

  const setProfileData = async (
    playerId: number | string
  ): Promise<Player[]> => {
    let players: Player[] = [];
    try {
      const player = await getPlayer(playerId);
      const playerDeposits = await getPlayerDeposits(playerId);
      const playerWithdrawals = await getPlayerWithdrawals(playerId);

      if (!player.error) {
        dispatch({ type: 'player', player: player.data });
        if (player.data.games_played && player.data.games_played.length > 0) {
          await getPlayerGamelogTable(playerId, player.data.games_played[0]);
        }
      }
      if (!playerDeposits.error)
        dispatch({
          type: 'playerDeposits',
          playerDeposits: playerDeposits.data,
        });
      if (!playerWithdrawals.error)
        dispatch({
          type: 'playerWithdrawals',
          playerWithdrawals: playerWithdrawals.data,
        });
    } catch (error) {}

    return players;
  };

  return (
    <PlayersContext.Provider
      value={{
        ...context,
        dispatch,
        updatePlayersList,
        getPlayerGamelogTable,
        setProfileData,
      }}
    >
      {children}
    </PlayersContext.Provider>
  );
};
