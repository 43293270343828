import React from 'react';
import {
  GamelogRecord,
  GameType,
  initialPlayer,
  Player,
  PlayerDeposit,
  PlayerStoreAction,
  PlayerWithdraw,
} from './types';

export const PLAYERS_INITIAL_STATE: PlayersContextType = {
  players: [],
  player: initialPlayer,
  playerDeposits: [],
  playerGamelog: [],
  playerWithdrawals: [],
  dispatch: (value: PlayerStoreAction) => {},
  updatePlayersList: async () => [],
  getPlayerGamelogTable: async (playerId: string, gameType: GameType) => {},
  setProfileData: async (playerId: number | string) => [],
};

export type PlayersContextType = {
  players: Player[];
  player: Player & { games_played: GameType[] };
  playerDeposits: PlayerDeposit[];
  playerGamelog: GamelogRecord[];
  playerWithdrawals: PlayerWithdraw[];
  dispatch: React.Dispatch<PlayerStoreAction>;
  updatePlayersList(): Promise<Player[]>;
  getPlayerGamelogTable(playerId: string, gameType: GameType): Promise<void>;
  setProfileData(playerId: number | string): Promise<Player[]>;
};

export const PlayersContext = React.createContext<PlayersContextType>(
  PLAYERS_INITIAL_STATE
);

export * from './PlayersStore';
export * from './types';
