import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { createPromotion } from '../../services/usersServices';
export const CreateBonus: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [form, setForm] = useState<any>({
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Nombre de bono',
      propsInput: {},
    },
    code: {
      type: 'text',
      initialValue: '',
      name: 'code',
      label: 'Codigo de bono',
      propsInput: {},
    },
    expirationDays: {
      type: 'text',
      initialValue: '',
      name: 'expirationDays',
      label: 'Dias de expiracion',
      propsInput: {},
    },
    credits: {
      type: 'text',
      initialValue: '',
      name: 'credits',
      label: 'Creditos',
      propsInput: {},
    },
    minDeposit: {
      type: 'text',
      initialValue: '',
      name: 'minDeposit',
      label: 'Deposito Minimo',
      propsInput: {},
    },
    maxDeposit: {
      type: 'text',
      initialValue: '',
      name: 'maxDeposit',
      label: 'Deposito Maximo',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      name: Yup.string()
        .max(255)
        .required('Se requiere un nombre para el bono'),
      code: Yup.string()
        .max(255)
        .required('Se requiere un codigo de promocions'),
      expirationDays: Yup.string()
        .max(255)
        .required('Se requieren dias de expiracion'),
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await createPromotion(values);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/promotion');
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  // React.useEffect(() => {
  //   (async () => {
  //     await updateDepositsList();
  //   })();

  //   return () => {};
  // }, []);

  return (
    <PageContainer
      headerTitle="Promociones"
      headerPath="/promotion"
      title={'Promociones'}
      path="promotion/create"
    >
      <CreateForm
        header={'Registra una Promocion'}
        description={'Utiliza esta herramienta para registrar nuevos promocion'}
        submitLabel={'Registar Promocion'}
        form={form}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      />
    </PageContainer>
  );
};
