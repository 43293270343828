import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContext, AuthContextType } from '../../context/authContext';
export const UpdatePlayer: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    login: {
      type: 'text',
      initialValue: '',
      name: 'username',
      label: 'Username',
      propsInput: {},
    },
    lastname: {
      type: 'text',
      initialValue: '',
      name: 'lastname',
      label: 'Apellidos',
      propsInput: {},
    },
    balance: {
      type: 'text',
      initialValue: '',
      name: 'balance',
      label: 'Balance',
      propsInput: {},
    },
    address: {
      type: 'text',
      initialValue: '',
      name: 'address',
      label: 'Direccion',
      propsInput: {},
    },
    city: {
      type: 'text',
      initialValue: '',
      name: 'city',
      label: 'Ciudad',
      propsInput: {},
    },
    country: {
      type: 'text',
      initialValue: '',
      name: 'country',
      label: 'Ciudad',
      propsInput: {},
    },
    district: {
      type: 'text',
      initialValue: '',
      name: 'district',
      label: 'District',
      propsInput: {},
    },
    email: {
      type: 'text',
      initialValue: '',
      name: 'email',
      label: 'Email',
      propsInput: {},
    },
    gender: {
      type: 'option',
      opts: [
        { value: 'M', label: 'Masculino' },
        { value: 'F', label: 'Femenino' },
      ],
      initialValue: '',
      name: 'gender',
      label: 'genero',
      propsInput: {},
    },
    phone: {
      type: 'text',
      initialValue: '',
      name: 'phone',
      label: 'Phone',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      login: Yup.string().max(255).required('Se requiere un nombre de usuario'),
      lastname: Yup.string().max(255).required('Se requiere un nombre'),
      balance: Yup.number(),
      address: Yup.string().max(255).required('Se requiere un nombre'),
      city: Yup.string().max(255).required('Se requiere un nombre'),
      country: Yup.string().max(255).required('Se requiere un nombre'),
      district: Yup.string().max(255).required('Se requiere un nombre'),
      email: Yup.string().max(255).required('Se requiere un nombre'),

      gender: Yup.string()
        .oneOf(['M', 'F'])
        .required('Por favor indique un rol para el usuario'),
      phone: Yup.number()
        .min(10, 'Teléfono debe tener al menos 10 digitos')
        .integer('Solo números. Debe ser un telefono válido.')
        .required('Un telefono es requerido'),
    })
  );
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(
        `/admin/player/${props.match.params.id}`,
        values
      );
      //await updateDealerById(props.match.params.id, values);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/players');
    } catch (error: any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let { data, status }: any = await getListByComponent(
        `/admin/player/${props.match.params.id}`
      );
      if (status == 200) {
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: data[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  React.useEffect(() => {
    if (hasRole(['superadmin'])) {
      (async () => {
        let gameTableResponse: Array<Casino> = [];
        let { data, status } = await getListByComponent('/admin/casino');
        if (status == 200) {
          gameTableResponse = data;
          setForm((form: any) => ({
            ...form,
            casino: {
              type: 'option',
              opts: gameTableResponse.map(({ _id, name }) => ({
                value: _id,
                label: name,
              })),
              initialValue: '',
              name: 'casino',
              label: 'Casino',
              propsInput: {},
            },
          }));
        }
      })();
    }
    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Jugadores"
      headerPath="/players"
      title={'Actualiza Staff'}
      path="staff/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza Jugador'}
          description={'Utiliza esta herramienta para actualizar Jugadores'}
          submitLabel={'Actualizar Staff'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
