import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContextType, AuthContext } from '../../context/authContext';
import { PageContainer } from '../components/PageContainer';
import { CheckBox } from '@material-ui/icons';
import { PokerCard } from '../../components/PokerCard';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
function GameDetailBlackjack(props: any) {
  const history = useHistory();
  const [detailGameLog, setDetailGameLog] = React.useState<any>({});
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const [loading, setLoading] = React.useState<any>(false);
  const classes = useStyles();
  React.useEffect(() => {
    (async () => {
      let formUpdated: any = await getListByComponent(
        `/admin/gamelog/detail/blackjack/${props.match.params.id}/${props.match.params.idPlayer}`
      );
      if (formUpdated.status == 200) {
        formUpdated = formUpdated.data;
        setDetailGameLog({
          ...formUpdated,
        });
        console.log(detailGameLog, formUpdated, 'form');
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Perfil de Jugador"
      headerPath={`/playerProfile/${props.match.params.idPlayer}`}
      title={'Visualizar Detalle de la Partida'}
      path="dealers/create"
    >
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Detalle de la Partida
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Utiliza esta herramienta visualizar el detalle de la partida.
      </Typography>
      <Grid container direction="row">
        {loading
          ? detailGameLog.players.map((seat: any, index: number) => (
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Asiento: {index + 1}
                    </Typography>
                    <Divider />
                    <Box pt={2} pb={2}>
                      <Typography variant="h5" component="h2">
                        Username: {seat.playerName}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        ID Player: {seat.main}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box pb={2}>
                      <Box pt={2} pb={2}>
                        <Typography variant="h5" component="h2">
                          Mano(s)
                        </Typography>
                      </Box>
                      <Divider />
                      {seat.hand.map((handItem: any, index: number) => (
                        <>
                          <Typography variant="h5" component="h2">
                            Mano: {index + 1}
                          </Typography>
                          <Typography variant="h5" component="h2">
                            Cartas
                          </Typography>
                          <Grid container direction="row">
                            {handItem.playerCards.map(
                              (card: string, index: number) => (
                                <Grid>
                                  <PokerCard
                                    cardId={card}
                                    style={{ margin: '10', width: '2.5em' }}
                                    onClick={() => {}}
                                  />
                                </Grid>
                              )
                            )}
                          </Grid>
                          <Typography variant="body2" component="p">
                            seguro:
                            <Checkbox
                              checked={handItem.insurance}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Typography>
                          <Typography variant="body2" component="p">
                            surrender:
                            <Checkbox
                              checked={handItem.surrender}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Typography>
                          <Typography variant="body2" component="p">
                            split:
                            <Checkbox
                              checked={handItem.split}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Typography>
                          <Typography variant="body2" component="p">
                            double down
                            <Checkbox
                              checked={handItem.doubleDown}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </Typography>
                        </>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          : null}
      </Grid>
    </PageContainer>
  );
}

export default GameDetailBlackjack;
