import React, { useReducer } from 'react';
import { AuthContext, AuthContextType, AUTH_INITIAL_STATE, AUTH_INIT } from '.';
import { signIn, setJWT, getUser } from '../../services/authService';
import { Auth, AuthStoreAction } from './types';
import SignIn from '../../pages/auth/SignIn';

type TablesProps = {
  children: React.ReactNode;
};

const reducer: React.Reducer<AuthContextType, AuthStoreAction> = (
  state: AuthContextType,
  action: AuthStoreAction
): AuthContextType => {
  switch (action.type) {
    case 'auth':
      console.log(state, action.auth);
      return { ...state, ...action.auth };
    case 'updateCurrency':
      console.log(state, action.currency);
      return { ...state, currency: action.currency };
    default:
      throw new Error();
  }
};

export const AuthStore: React.FC<TablesProps> = ({ children }): JSX.Element => {
  const [context, dispatch] = useReducer(reducer, AUTH_INITIAL_STATE);

  const login = async (
    username: string,
    password: string,
    recaptchaValue?: string
  ) => {
    let user: Auth = AUTH_INIT;
    try {
      const { data, error } = await signIn({ username, password });
      user = {
        user: { ...data },
        role: data.role,
        username: data.username,
        isActive: data.is_active === 1,
        isLoading: false,
        isAuthenticated: true,
      };
      setJWT(data.token);
      localStorage.setItem('casino_backoffice_token', data.token);
      dispatch({
        type: 'auth',
        auth: {
          user: { ...data },
          role: user.role,
          username: user.username,
          isActive: user.isActive,
          isLoading: true,
          isAuthenticated: true,
        },
      });
    } catch (err) {
      dispatch({
        type: 'auth',
        auth: {
          ...AUTH_INITIAL_STATE,
          isAuthenticated: false,
          isLoading: true,
        },
      });
      localStorage.clear();
    }
    return user;
  };
  const fetchUser = async () => {
    let user: Auth | null = null;
    let token = localStorage.getItem('casino_backoffice_token');
    if (token) {
      setJWT(token);
      try {
        const { data, error } = await getUser();
        user = {
          user: { ...data },
          role: data.role,
          username: data.username,
          isActive: data.is_active === 1,
          isLoading: false,
          isAuthenticated: true,
        };
        dispatch({
          type: 'auth',
          auth: {
            user: { ...user },
            role: user.role,
            username: user.username,
            isActive: user.isActive,
            isLoading: true,
            isAuthenticated: true,
          },
        });
      } catch (err) {
        dispatch({
          type: 'auth',
          auth: {
            ...AUTH_INITIAL_STATE,
            isAuthenticated: false,
            isLoading: true,
          },
        });
        localStorage.clear();
      }
      return user;
    }
  };
  const hasRole = (rolesAllowed: Array<string>) => {
    return rolesAllowed.some((roleAllowed) => roleAllowed === context.role);
  };
  const signOut = async () => {
    localStorage.removeItem('casino_backoffice_token');
    dispatch({
      type: 'auth',
      auth: {
        ...AUTH_INITIAL_STATE,
        isLoading: true,
        isAuthenticated: false,
      },
    });
  };

  const updateCurrency = async (currency: string) => {
    dispatch({
      type: 'updateCurrency',
      currency,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...context,
        dispatch,
        login,
        fetchUser,
        signOut,
        hasRole,
        updateCurrency,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
