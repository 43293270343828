import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { createDealer } from '../../services/usersServices';
export const CreateDealer: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [form, setForm] = useState<any>({
    alias: {
      type: 'text',
      initialValue: '',
      name: 'alias',
      label: 'Alias',
      propsInput: {},
    },
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Name',
      propsInput: {},
    },
    profilePicture: {
      type: 'upload',
      initialValue: '',
      name: 'profilePicture',
      label: 'profilePicture',
      propsInput: {},
    },
    code: {
      type: 'text',
      initialValue: '',
      name: 'code',
      label: 'codigo',
      propsInput: {},
    },
    active: {
      type: 'switch',
      initialValue: true,
      name: 'active',
      label: 'Active',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      alias: Yup.string().max(255).required('Se requiere un alias'),
      name: Yup.string().max(255).required('Se requiere un nombre'),
      code: Yup.string().max(255).required('Se requiere un codigo'),
      profilePicture: Yup.mixed()
        .required('Una imagen del dealer es requerida')
        .test((val) => val && val?.type.includes('image/')),
    })
  );
  const onSubmit = async (
    values: any,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      let data = new FormData();
      data.append('profilePicture', values.profilePicture);
      data.append('alias', values.alias);
      data.append('name', values.name);
      data.append('code', values.code);
      data.append('active', values.active);
      // console.log(values);
      setSubmitting(true);
      await createDealer(data);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/dealers');
    } catch (error: { message?: string } | any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  // React.useEffect(() => {
  //   (async () => {
  //     await updateDepositsList();
  //   })();

  //   return () => {};
  // }, []);

  return (
    <PageContainer
      headerTitle="dealer"
      headerPath="/dealers"
      title={'Dealer Nuevo'}
      path="dealers/create"
    >
      <CreateForm
        header={'Registra un nuevo dealer'}
        description={'Utiliza esta herramienta para registrar nuevos dealers'}
        submitLabel={'Registar Usuario'}
        form={form}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      />
    </PageContainer>
  );
};
