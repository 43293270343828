import React from 'react';
import { User, UserStoreAction } from './types';

export const USERS_INITIAL_STATE: UsersContextType = {
  users: [],
  dispatch: (value: UserStoreAction) => {},
  updateUsersList: async () => [],
};

export type UsersContextType = {
  users: User[];
  dispatch: React.Dispatch<UserStoreAction>;
  updateUsersList(): Promise<User[]>;
};

export const UsersContext = React.createContext<UsersContextType>(
  USERS_INITIAL_STATE
);

export * from './UsersStore';
export * from './types';
