import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

import {
  createListByComponent,
  getListByComponent,
} from '../../services/genericServices';
import { AuthContextType, AuthContext } from '../../context/authContext';
import { Casino } from '../../types/casinos';
import { PageContainer } from '../components/PageContainer';
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const SelectField = styled(MuiSelectField)<{ my?: number }>(spacing);
const ControlField = styled(MuiFormControl)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
interface RouletteTable {
  game: string;
  typeBets: {
    straightUp: { minBet: number; maxBet: number; profit: string };
    split: { minBet: number; maxBet: number; profit: string };
    street: { minBet: number; maxBet: number; profit: string };
    corner: { minBet: number; maxBet: number; profit: string };
    line: { minBet: number; maxBet: number; profit: string };
    column: { minBet: number; maxBet: number; profit: string };
    dozen: { minBet: number; maxBet: number; profit: string };
    redBlack: { minBet: number; maxBet: number; profit: string };
    evenOdd: { minBet: number; maxBet: number; profit: string };
    lowHigh: { minBet: number; maxBet: number; profit: string };
  };
  profit: {
    blackjack: string;
    insurance: string;
    win: string;
  };
  waitingTime: { initGame: number; decision: number; insurance: number };
  chips: Array<number>;
  viewers: number;
  isOpen: boolean;
  dealer: string;
  tablePicture: string;
  streamAuthorizationToken: string;
  streamName: string;
  streamAccountId: string;
  gameType: string;
  tableNumber: number;
  version: number;
  casino?: string;
}
function UpdateRouletteTable(props: any) {
  const history = useHistory();
  const [opts, setOpts] = React.useState<any>([]);
  const [form, setForm] = React.useState<RouletteTable>({
    typeBets: {
      straightUp: { minBet: 0, maxBet: 0, profit: '35:1' },
      split: { minBet: 0, maxBet: 0, profit: '17:1' },
      street: { minBet: 0, maxBet: 0, profit: '11:1' },
      corner: { minBet: 0, maxBet: 0, profit: '8:1' },
      line: { minBet: 0, maxBet: 0, profit: '5:1' },
      column: { minBet: 0, maxBet: 0, profit: '2:1' },
      dozen: { minBet: 0, maxBet: 0, profit: '2:1' },
      redBlack: { minBet: 0, maxBet: 0, profit: '1:1' },
      evenOdd: { minBet: 0, maxBet: 0, profit: '1:1' },
      lowHigh: { minBet: 0, maxBet: 0, profit: '1:1' },
    },
    chips: [1, 10, 50, 100, 500, 1000],
    game: 'roulette',
    profit: { blackjack: '3:2', insurance: '2:1', win: '1:1' },
    waitingTime: { initGame: 20, decision: 20, insurance: 20 },
    dealer: '',
    viewers: 0,
    isOpen: false,
    tablePicture: '',
    streamAuthorizationToken: '',
    streamName: '',
    streamAccountId: '',
    gameType: '',
    tableNumber: 0,
    version: 0,
  });
  const [loading, setLoading] = React.useState<any>(true);
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await createListByComponent(`table/roulette`, values);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/table/list');
    } catch (error: any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };
  React.useEffect(() => {
    if (hasRole(['superadmin'])) {
      (async () => {
        let gameTableResponse: Array<Casino> = [];
        let { data, status } = await getListByComponent('/admin/casino');
        if (status == 200) {
          gameTableResponse = data;
          setOpts(
            gameTableResponse.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }))
          );
          setForm((form) => ({ ...form, casino: '' }));
        }
      })();
    }
    return () => {};
  }, []);
  return (
    <PageContainer
      headerTitle="Mesas de Juego"
      headerPath="/tables"
      title={'Actualiza Mesa de Juego'}
      path="dealers/create"
    >
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Registra una Mesa de Juego
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Utiliza esta herramienta para registraruna Mesa de Juego
      </Typography>
      {loading ? (
        <Formik
          initialValues={form}
          validationSchema={Yup.object().shape({
            game: Yup.string()
              .max(255)
              .required('Se requiere un nombre de juego'),
            gameType: Yup.string()
              .max(255)
              .required('Se requiere un tipo de juego'),
            tableNumber: Yup.string()
              .max(255)
              .required('Se requiere un numero de mesa'),
            typeBets: Yup.object().shape({
              main: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
              }),
              behind: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
              }),
            }),
            dealer: Yup.string().max(255).required('Se requiere un nombre'),
            streamName: Yup.string().max(255).required('Se requiere un nombre'),
            streamAccountId: Yup.string()
              .max(255)
              .required('Se requiere un nombre'),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="game"
                    label="game"
                    value={values.game}
                    error={Boolean(touched.game && errors.game)}
                    fullWidth
                    helperText={touched.game && errors.game}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="dealer"
                    label="Nombre de dealer"
                    value={values.dealer}
                    error={Boolean(touched.dealer && errors.dealer)}
                    fullWidth
                    helperText={touched.dealer && errors.dealer}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="gameType"
                    label="tipo de juego"
                    value={values.gameType}
                    error={Boolean(touched.gameType && errors.gameType)}
                    fullWidth
                    helperText={touched.gameType && errors.gameType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="tableNumber"
                    label="Numero de Mesa"
                    value={values.tableNumber}
                    error={Boolean(touched.tableNumber && errors.tableNumber)}
                    fullWidth
                    helperText={touched.tableNumber && errors.tableNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.straightUp.minBet"
                    label="Apuesta minima straight up"
                    value={values.typeBets?.straightUp?.minBet}
                    error={Boolean(
                      touched.typeBets?.straightUp?.minBet &&
                        errors.typeBets?.straightUp?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.straightUp?.minBet &&
                      errors.typeBets?.straightUp?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.straightUp.maxBet"
                    label="Apuesta maxima straight up"
                    value={values.typeBets?.straightUp?.maxBet}
                    error={Boolean(
                      touched.typeBets?.straightUp?.maxBet &&
                        errors.typeBets?.straightUp?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.straightUp?.maxBet &&
                      errors.typeBets?.straightUp?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.split.minBet"
                    label="Apuesta minima split"
                    value={values.typeBets?.split?.minBet}
                    error={Boolean(
                      touched.typeBets?.split?.minBet &&
                        errors.typeBets?.split?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.split?.minBet &&
                      errors.typeBets?.split?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.split.maxBet"
                    label="Apuesta maxima split"
                    value={values.typeBets?.split?.maxBet}
                    error={Boolean(
                      touched.typeBets?.split?.maxBet &&
                        errors.typeBets?.split?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.split?.maxBet &&
                      errors.typeBets?.split?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.street.minBet"
                    label="Apuesta minima street"
                    value={values.typeBets?.street?.minBet}
                    error={Boolean(
                      touched.typeBets?.street?.minBet &&
                        errors.typeBets?.street?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.street?.minBet &&
                      errors.typeBets?.street?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.street.maxBet"
                    label="Apuesta maxima street"
                    value={values.typeBets?.street?.maxBet}
                    error={Boolean(
                      touched.typeBets?.street?.maxBet &&
                        errors.typeBets?.street?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.street?.maxBet &&
                      errors.typeBets?.street?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.corner.minBet"
                    label="Apuesta minima corner"
                    value={values.typeBets?.corner?.minBet}
                    error={Boolean(
                      touched.typeBets?.corner?.minBet &&
                        errors.typeBets?.corner?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.corner?.minBet &&
                      errors.typeBets?.corner?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.corner.maxBet"
                    label="Apuesta maxima corner"
                    value={values.typeBets?.corner?.maxBet}
                    error={Boolean(
                      touched.typeBets?.corner?.maxBet &&
                        errors.typeBets?.corner?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.corner?.maxBet &&
                      errors.typeBets?.corner?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.line.minBet"
                    label="Apuesta minima line"
                    value={values.typeBets?.line?.minBet}
                    error={Boolean(
                      touched.typeBets?.line?.minBet &&
                        errors.typeBets?.line?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.line?.minBet &&
                      errors.typeBets?.line?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.line.maxBet"
                    label="Apuesta maxima line"
                    value={values.typeBets?.line?.maxBet}
                    error={Boolean(
                      touched.typeBets?.line?.maxBet &&
                        errors.typeBets?.line?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.line?.maxBet &&
                      errors.typeBets?.line?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.column.minBet"
                    label="Apuesta minima column"
                    value={values.typeBets?.column?.minBet}
                    error={Boolean(
                      touched.typeBets?.column?.minBet &&
                        errors.typeBets?.column?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.column?.minBet &&
                      errors.typeBets?.column?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.column.maxBet"
                    label="Apuesta maxima column"
                    value={values.typeBets?.column?.maxBet}
                    error={Boolean(
                      touched.typeBets?.column?.maxBet &&
                        errors.typeBets?.column?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.column?.maxBet &&
                      errors.typeBets?.column?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.dozen.minBet"
                    label="Apuesta minima dozen"
                    value={values.typeBets?.dozen?.minBet}
                    error={Boolean(
                      touched.typeBets?.dozen?.minBet &&
                        errors.typeBets?.dozen?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.dozen?.minBet &&
                      errors.typeBets?.dozen?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.dozen.maxBet"
                    label="Apuesta maxima dozen"
                    value={values.typeBets?.dozen?.maxBet}
                    error={Boolean(
                      touched.typeBets?.dozen?.maxBet &&
                        errors.typeBets?.dozen?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.dozen?.maxBet &&
                      errors.typeBets?.dozen?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.redBlack.minBet"
                    label="Apuesta minima red/black"
                    value={values.typeBets?.redBlack?.minBet}
                    error={Boolean(
                      touched.typeBets?.redBlack?.minBet &&
                        errors.typeBets?.redBlack?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.redBlack?.minBet &&
                      errors.typeBets?.redBlack?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.redBlack.maxBet"
                    label="Apuesta maxima red/black"
                    value={values.typeBets?.redBlack?.maxBet}
                    error={Boolean(
                      touched.typeBets?.redBlack?.maxBet &&
                        errors.typeBets?.redBlack?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.redBlack?.maxBet &&
                      errors.typeBets?.redBlack?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.evenOdd.minBet"
                    label="Apuesta minima even/odd"
                    value={values.typeBets?.evenOdd?.minBet}
                    error={Boolean(
                      touched.typeBets?.evenOdd?.minBet &&
                        errors.typeBets?.evenOdd?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.evenOdd?.minBet &&
                      errors.typeBets?.evenOdd?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.evenOdd.maxBet"
                    label="Apuesta maxima even/odd"
                    value={values.typeBets?.evenOdd?.maxBet}
                    error={Boolean(
                      touched.typeBets?.evenOdd?.maxBet &&
                        errors.typeBets?.evenOdd?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.evenOdd?.maxBet &&
                      errors.typeBets?.evenOdd?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.lowHigh.minBet"
                    label="Apuesta minima low/high"
                    value={values.typeBets?.lowHigh?.minBet}
                    error={Boolean(
                      touched.typeBets?.lowHigh?.minBet &&
                        errors.typeBets?.lowHigh?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.lowHigh?.minBet &&
                      errors.typeBets?.lowHigh?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.lowHigh.maxBet"
                    label="Apuesta maxima low/high"
                    value={values.typeBets?.lowHigh?.maxBet}
                    error={Boolean(
                      touched.typeBets?.lowHigh?.maxBet &&
                        errors.typeBets?.lowHigh?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.lowHigh?.maxBet &&
                      errors.typeBets?.lowHigh?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="streamName"
                    label="Stream Name"
                    value={values.streamName}
                    error={Boolean(touched.streamName && errors.streamName)}
                    fullWidth
                    helperText={touched.streamName && errors.streamName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="streamAccountId"
                    label="stream Account Id"
                    value={values.streamAccountId}
                    error={Boolean(
                      touched.streamAccountId && errors.streamAccountId
                    )}
                    fullWidth
                    helperText={
                      touched.streamAccountId && errors.streamAccountId
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                {hasRole(['superadmin']) ? (
                  <Grid item md={12}>
                    <ControlField my={3} fullWidth variant="outlined">
                      <InputLabel id={'casino'}>Casino</InputLabel>
                      <SelectField
                        labelId={'casino'}
                        id={'casino'}
                        name={'casino'}
                        value={values.casino}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {opts.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </SelectField>
                      {props.error && (
                        <FormHelperText>
                          Por favor indique una opcion
                        </FormHelperText>
                      )}
                    </ControlField>
                  </Grid>
                ) : null}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Actualizar Mesa
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      ) : null}
    </PageContainer>
  );
}

export default UpdateRouletteTable;
