import React from 'react';
import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  FormControlLabel,
  Grid,
  Checkbox,
  Switch,
} from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const SelectField = styled(MuiSelectField)<{ my?: number }>(spacing);
const ControlField = styled(MuiFormControl)<{ my?: number }>(spacing);
interface Props {
  variant: string;
  type: string;
  name: string;
  label: string;
  value: any;
  error: boolean;
  fullWidth: any;
  helperText: boolean;
  onBlur: any;
  onChange: any;
  propsInput: any;
  setFieldValue: any;
  opts: {
    value: string;
    label: string;
  };
}

interface Option {
  value: string;
  label: string;
}
function SwitchInputs(props: any) {
  switch (props.type) {
    case 'password':
    case 'text':
      return <TextField {...props} />;
    case 'switch':
      return (
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              onChange={props.onChange}
              checked={props.value}
              name={props.name}
            />
          }
          name={props.name}
          label={props.label}
        />
      );
    case 'checkbox':
      return <Checkbox inputProps={{ 'aria-label': 'secondary checkbox' }} />;
    case 'upload':
      return (
        <Button variant="contained" component="label">
          Upload File
          <input
            type="file"
            id={props.name}
            name={props.name}
            onChange={(event: any) => {
              props.setFieldValue(props.name, event?.currentTarget?.files[0]);
            }}
          />
          {props.error && (
            <FormHelperText>Una imagen válida es requerida</FormHelperText>
          )}
        </Button>
      );
    case 'option':
      return (
        <ControlField my={3} fullWidth error={props.error} variant="outlined">
          <InputLabel id={props.name}>{props.label}</InputLabel>
          <SelectField
            labelId={props.name}
            id={props.name}
            name={props.name}
            value={props.value}
            fullWidth
            onBlur={props.onBlur}
            onChange={props.onChange}
            {...props}
          >
            {props.opts.map((option: Option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectField>
          {props.error && (
            <FormHelperText>Por favor indique una opcion</FormHelperText>
          )}
        </ControlField>
      );
    default:
      return <TextField {...props} />;
  }
}

export default SwitchInputs;
