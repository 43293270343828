export type Table = {
  _id: string;
  TableId: string;
  TableName: string;
  Type: string;
  Players: string;
  MinBet: string;
  MaxBet: string;
  Dealer: string;
  DealerPicture: string;
  Viewers: string;
  IsOpen: string;
  OccupiedSeats: string;
  TablePicture: string;
  MinBetBehind: string;
  MaxBetBehind: string;
  StreamAccountId: string;
  StreamAuthorizationToken: string;
  StreamName: string;
};

export type IO_Table = {
  dealer: string;
  chips: number[];
  isOpen: boolean;
  limitBet: BaccaratBetLimits;
  streamAccountId: string;
  streamAuthorizationToken: string;
  streamName: string;
  tableName: string;
  tableNumber: number;
  tablePicture: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  viewers: number;
  _id: string;
};

export type GameTable = {
  id: string;
  dealer: string;
  isOpen: boolean;
  tableName: string;
  tableNumber: number;
  tablePicture: string;
  gameType: string;
  category: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  viewers: number;
  timerBets: number;
  timerDecision: number;
  timerInsurance: number;
  timerResetGame: number;
};

export type TableStoreAction = { type: 'gameTables'; gameTables: GameTable[] };

export const initialTable = {
  _id: '',
  TableId: '',
  TableName: '',
  Type: '',
  Players: '',
  MinBet: '',
  MaxBet: '',
  Dealer: '',
  DealerPicture: '',
  Viewers: '',
  IsOpen: '',
  OccupiedSeats: '',
  TablePicture: '',
  MinBetBehind: '',
  MaxBetBehind: '',
  StreamAuthorizationToken: '',
  StreamName: '',
  StreamAccountId: '',
};

export const initialBaccaratBetLimits = {
  player: {
    minBet: 0,
    maxBet: 0,
  },
  banker: {
    minBet: 0,
    maxBet: 0,
  },
  tie: {
    minBet: 0,
    maxBet: 0,
  },
  ppair: {
    minBet: 0,
    maxBet: 0,
  },
  bpair: {
    minBet: 0,
    maxBet: 0,
  },
};

export type BaccaratBetLimits = {
  player: {
    minBet: number;
    maxBet: number;
  };
  banker: {
    minBet: number;
    maxBet: number;
  };
  tie: {
    minBet: number;
    maxBet: number;
  };
  ppair: {
    minBet: number;
    maxBet: number;
  };
  bpair: {
    minBet: number;
    maxBet: number;
  };
};
