import axios from '../utils/axios';

const emptyResponse: APIResponse = {
  error: false,
  errorMsg: '',
  status: 200,
  data: null,
};

export type APIResponse = {
  error: boolean;
  errorMsg: string;
  status?: number;
  data: any;
};

export const getTables = async (): Promise<APIResponse> => {
  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get('/admin/tables');
    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};

export const getVideo = async (videoId: string): Promise<APIResponse> => {
  // let bodyFormData = new FormData();
  // bodyFormData.append("username", credentials.username);
  // bodyFormData.append("password", credentials.password);
  let result: APIResponse = emptyResponse;

  try {
    const res = await axios.get('/video/game/' + videoId);
    if (res.status === 200) {
      result = { ...emptyResponse, data: res.data };
    } else {
      result = {
        error: true,
        errorMsg: 'No hay resultados',
        status: res.status,
        data: null,
      };
    }
  } catch (e) {
    console.log(e);

    result = {
      error: true,
      errorMsg: 'Error al conectarse con el servidor.',
      data: null,
    };
    throw result;
  }
  return result;
};

// category: "speed Baccarat"
// chips: (6) [1, 10, 50, 100, 500, 1000]
// createdAt: "2021-02-27T01:17:52.803Z"
// dealer: "Jacobo"
// gameType: "baccarat"
// isOpen: false
// limitBet: {player: {…}, banker: {…}, tie: {…}, ppair: {…}, bpair: {…}}
// streamAccountId: "sanZE3"
// streamAuthorizationToken: ""
// streamName: "kdaihneo"
// tableName: ""
// tableNumber: 6000
// tablePicture: "https://demo.lasvegasenvivo.com/TableScreenshots/8888.jpg"
// timer_bets: 12
// timer_decision: 12
// timer_insurance: 8
// timer_reset_game: 5
// type: "Baccarat"
// updatedAt: "2021-02-27T01:17:52.803Z"
// version: 0
// viewers: 0
// _id: "60399dc0523e5e1ba3855cc0"

// betbehind_maximum: "500.00"
// betbehind_minimum: "3.00"
// category: "speed Baccarat"
// description: "US DOLLARS"
// gameType: "blackjack"
// id_server: 2
// id_table: 1
// is_active: 1
// maximum: "1000.00"
// minimum: "5.00"
// modified_date: "2012-05-29T23:59:08.000Z"
// register_date: "2011-11-03T00:00:00.000Z"
// stream_1_accountId: "sanZE3"
// stream_1_bitrate: 1000
// stream_1_name: "kdaihneo"
// stream_1_token: ""
// stream_2_bitrate: 1000
// stream_2_name: ""
// stream_3_bitrate: 1000
// stream_3_name: "/iglobal/secure/live/feed01?e={0}&d=US"
// stream_host: ""
// table_code: 9901
// timer_bets: 12
// timer_decision: 12
// timer_insurance: 8
// timer_reset_game: 5
// type: "Regular"
