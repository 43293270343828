import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import {
  FileCopy as FileCopyIcon,
  Edit as EditIcon,
  Pageview as PageviewIcon,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const getTitle = (params: GridValueGetterParams) => {
  return `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }`;
};

// GridComuns are enhanced GridColDef
const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Nombre',
    width: 230,
    valueGetter: getTitle,
  },
  {
    field: 'reference',
    headerName: 'Codigo',
    width: 100,
  },
  {
    field: 'numRegistrations',
    headerName: 'Jugadores',
    type: 'number',
    width: 120,
  },
  {
    field: 'numDeposits',
    headerName: '# Depositos',
    type: 'number',
    width: 120,
  },
  {
    field: 'amountDeposits',
    headerName: '$ Depositos',
    type: 'number',
    width: 120,
  },
  {
    field: 'shareableUrl',
    headerName: 'Url',
    headerAlign: 'center',
    renderCell: (cellValues) => {
      return (
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(
              typeof cellValues.value == 'string' ? cellValues.value : ''
            );
          }}
        >
          <FileCopyIcon />
        </IconButton>
      );
    },
    width: 50,
  },
  {
    field: '_id',
    headerName: 'Acciones',
    width: 100,
    renderCell: (cellValues) => {
      return (
        <>
          <IconButton
            aria-label="details"
            component={NavLink}
            to={`/affiliate/${cellValues.value}`}
          >
            <PageviewIcon />
          </IconButton>
          <IconButton
            aria-label="details"
            component={NavLink}
            to={`/affiliate/update/${cellValues.value}`}
          >
            <EditIcon />
          </IconButton>
        </>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Miembro desde (Hora Mty)',
    width: 250,
    valueFormatter: (params: GridValueFormatterParams) => {
      const dateValue = new Date(params.value as string);

      return `${dateValue.toLocaleString()}`;
    },
  },
];

type RowType = {
  [key: string]: string | number | boolean;
  _id: string;
  title: string;
  reference: string;
  referrals: number;
  balance: number;
};

type TableProps = {
  rows: RowType[];
};

const SectionDataGrid: React.FC<TableProps> = ({ rows }): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm')); // sm and up > 600px

  const footerHeight = 61.5;
  const sectionHeaderHeight = 48;
  const appHeaderHeight = 64;

  const outherHeight = footerHeight + sectionHeaderHeight + appHeaderHeight;

  return (
    <Box
      sx={{
        height: `calc(100vh - ${outherHeight}px - ${
          matches ? '64px' : '56px'
        } - 30px)`,
        marginBottom: 0,
      }}
    >
      <DataGrid
        getRowId={(row) => row._id}
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{
          color: '#FFF',
          backgroundColor: '#222',
          boxShadow: 2,
          border: 1,
          borderColor: '#222',
          borderRadius: 2,
          marginBottom: 0,
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid rgba(81, 81, 81, 1)',
          },
          '& .MuiIconButton-root': {
            color: 'white',
          },
        }}
        density={'compact'}
        rows={rows}
        columns={columns}
        checkboxSelection
        hideFooter
        rowSpacingType={'border'}
      />
    </Box>
  );
};

export default SectionDataGrid;
