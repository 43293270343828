import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink, Link as RouterLink } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GenericTableNew from '../../components/GenericTableNew';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Image as ImageIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';

import { getListByComponent } from '../../services/genericServices';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

function Users() {
  const [gameTable, setGameTable] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (row_id: string) => {
    console.log(row_id);
    setOpen(true);
  };

  const handleClose = (e: object, r: string) => {
    if ((r === 'backdropClick' || r === 'escapeKeyDown') === false) {
      setOpen(false);
    }
  };

  useEffect(() => {
    (async () => {
      let { data, status } = await getListByComponent('/admin/users');
      if (status == 200) {
        setGameTable(data.map((item: any) => ({ id: item._id, ...item })));
      }
    })();

    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Casino" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Administadores
          </Typography>

          {/*
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Users</Typography>
          </Breadcrumbs>
           */}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GenericTableNew
            tableName={`Administadores`}
            rowsModel={gameTable}
            headCells={[
              {
                id: 'username',
                alignment: 'left',
                label: 'Nombre de usuario',
                type: 'text',
              },
              {
                id: 'firstName',
                alignment: 'left',
                label: 'Nombre(s)',
                type: 'text',
              },
              {
                id: 'lastName',
                alignment: 'left',
                label: 'Apellidos',
                type: 'text',
              },
              { id: 'role', alignment: 'left', label: 'Rol', type: 'text' },
              { id: 'email', alignment: 'left', label: 'Correo', type: 'text' },
              {
                id: 'phone',
                alignment: 'left',
                label: 'Celular',
                type: 'text',
              },
              {
                id: 'id',
                alignment: 'left',
                label: 'Casino ID',
                type: 'text',
              },
              //{ id: 'actions', alignment: 'center', label: 'Acciones' },
            ]}
            disabledEmptyRows={true}
            disabledTableHeader={true}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose({}, 'cancel');
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose({}, 'save');
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Users;
