import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContext, AuthContextType } from '../../context/authContext';
export const UpdateStaff: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    username: {
      type: 'text',
      initialValue: '',
      name: 'username',
      label: 'Username',
      propsInput: {},
    },
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Name',
      propsInput: {},
    },
    role: {
      type: 'option',
      opts: [
        { value: 'admin', label: 'administrador' },
        { value: 'mlro', label: 'Operador' },
        { value: 'finanzas', label: 'Analista' },
      ],
      initialValue: '',
      name: 'role',
      label: 'Role',
      propsInput: {},
    },
    email: {
      type: 'text',
      initialValue: '',
      name: 'email',
      label: 'Email',
      propsInput: {},
    },
    phone: {
      type: 'text',
      initialValue: '',
      name: 'phone',
      label: 'Phone',
      propsInput: {},
    },
    is_active: {
      type: 'switch',
      initialValue: true,
      name: 'is_active',
      label: 'activo',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      username: Yup.string()
        .max(255)
        .required('Se requiere un nombre de usuario'),
      name: Yup.string().max(255).required('Se requiere un nombre'),
      role: Yup.string()
        .oneOf(['admin', 'mlro', 'finanzas'])
        .required('Por favor indique un rol para el usuario'),
      email: Yup.string()
        .email('Debe ser email válido')
        .max(255)
        .required('Un email es requerido'),
      phone: Yup.number()
        .min(10, 'Teléfono debe tener al menos 10 digitos')
        .integer('Solo números. Debe ser un telefono válido.')
        .required('Un telefono es requerido'),
    })
  );
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(
        `/admin/user/${props.match.params.id}`,
        values
      );
      //await updateDealerById(props.match.params.id, values);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/staff/list');
    } catch (error: any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getListByComponent(
        `/admin/user/${props.match.params.id}`
      );
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  React.useEffect(() => {
    if (hasRole(['superadmin'])) {
      (async () => {
        let gameTableResponse: Array<Casino> = [];
        let { data, status } = await getListByComponent('/admin/casino');
        if (status == 200) {
          gameTableResponse = data;
          setForm((form: any) => ({
            ...form,
            casino: {
              type: 'option',
              opts: gameTableResponse.map(({ _id, name }) => ({
                value: _id,
                label: name,
              })),
              initialValue: '',
              name: 'casino',
              label: 'Casino',
              propsInput: {},
            },
          }));
        }
      })();
    }
    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Promociones"
      headerPath="/promotion"
      title={'Actualiza Staff'}
      path="staff/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza Staff'}
          description={'Utiliza esta herramienta para registrar nuevos staff'}
          submitLabel={'Actualizar Staff'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
