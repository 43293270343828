import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink, useHistory } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import GenericTableNew from '../../components/GenericTableNew';
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Image as ImageIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';
import {
  DealersContext,
  DealersContextType,
} from '../../context/dealersContext';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

function Dealers() {
  const { dealers, updateDealersList } = useContext<DealersContextType>(
    DealersContext
  );
  const history = useHistory();
  useEffect(() => {
    (async () => {
      await updateDealersList();
    })();
    console.log(dealers);
    return () => {};
  }, [dealers.length > 0]);

  return (
    <React.Fragment>
      <Helmet title="Dealers" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Dealers
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Dealers</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/dealer/create');
            }}
          >
            crear dealer
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <GenericTableNew
            allowTableFilter={false}
            serverPagination
            tableName={`Dealers`}
            rowsModel={dealers}
            headCells={[
              {
                id: 'profilePicture',
                alignment: 'left',
                label: 'Imagen',
                type: 'image',
              },
              { id: 'alias', alignment: 'left', label: 'Alias', type: 'text' },
              { id: 'name', alignment: 'left', label: 'Nombre', type: 'text' },
              {
                id: 'active',
                alignment: 'center',
                label: 'activo',
                type: 'chip',
              },
              {
                id: '_id',
                alignment: 'left',
                label: 'Editar Dealer',
                type: 'actions',
              },
            ]}
            disabledEmptyRows={true}
            disabledTableHeader={true}
            actionsCells={(id: string) => {
              return (
                <>
                  <IconButton
                    aria-label="details"
                    component={NavLink}
                    to={`/dealers/update/${id}`}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dealers;
