import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Box,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContextType, AuthContext } from '../../context/authContext';
import { PageContainer } from '../components/PageContainer';
import { CheckBox } from '@material-ui/icons';
import { PokerCard } from '../../components/PokerCard';
import GenericTableNew from '../../components/GenericTableNew';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
function GameDetailRoulette(props: any) {
  const history = useHistory();
  const [detailGameLog, setDetailGameLog] = React.useState<any>({});
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const [loading, setLoading] = React.useState<any>(false);
  const classes = useStyles();
  React.useEffect(() => {
    console.log(props.match.params);
    (async () => {
      let formUpdated: any = await getListByComponent(
        `/admin/gamelog/detail/roulette/${props.match.params.id}/${props.match.params.idPlayer}`
      );
      if (formUpdated.status == 200) {
        formUpdated = formUpdated.data;

        setDetailGameLog({
          ...formUpdated,
        });
        console.log(detailGameLog, formUpdated, 'form');
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Perfil de Jugador"
      headerPath={`/playerProfile/${props.match.params.idPlayer}`}
      title={'Visualizar Detalle de la Partida'}
      path="dealers/create"
    >
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Detalle de la Partida
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Utiliza esta herramienta visualizar el detalle de la partida.
      </Typography>
      <Grid container direction="row">
        {loading ? (
          <>
            <Grid item xs={12}>
              <Card className={classes.root}>
                <CardContent>
                  <Box pt={2} pb={2}>
                    <Typography variant="h5" component="h2">
                      Resultado
                    </Typography>
                  </Box>
                  <Divider />
                  <Box pt={2} pb={2}>
                    <Typography
                      style={{
                        textAlign: 'center',
                        fontSize: '35px',
                        textShadow:
                          '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
                        color:
                          [
                            1,
                            3,
                            5,
                            7,
                            9,
                            12,
                            14,
                            16,
                            18,
                            19,
                            21,
                            23,
                            25,
                            27,
                            30,
                            32,
                            34,
                            36,
                          ].indexOf(detailGameLog.result) >= 0
                            ? 'red'
                            : 'black',
                      }}
                      variant="h5"
                      component="h1"
                    >
                      {detailGameLog.result}
                    </Typography>
                  </Box>

                  <Box pt={2} pb={2}>
                    <GenericTableNew
                      tableName={`Apuestas`}
                      rowsModel={detailGameLog.bets.reduce(
                        (acc: any, item: any) => {
                          if (item.user === props.match.params.idPlayer) {
                            detailGameLog.winBets.forEach((element: any) => {
                              if (item.user === element.user) {
                                if (item.betType === element.betType) {
                                  acc.push({
                                    ...item,
                                    profit: element.profit,
                                  });
                                } else {
                                  acc.push({
                                    ...item,
                                    profit: 0,
                                  });
                                }
                              }
                            });
                          }
                        },
                        []
                      )}
                      headCells={[
                        {
                          id: 'betType',
                          alignment: 'center',
                          label: 'Tipo de Apuesta',
                          type: 'text',
                        },
                        {
                          id: 'id',
                          alignment: 'left',
                          label: 'Id de apuesta',
                          type: 'text',
                        },
                        {
                          id: 'bet',
                          alignment: 'center',
                          label: 'monto de apuesta',
                          type: 'number',
                        },
                        //{ id: 'actions', alignment: 'center', label: 'Acciones' },
                      ]}
                      serverPagination={true}
                      disabledEmptyRows={true}
                      disabledTableHeader={true}
                    />
                  </Box>
                  <Box pt={4} pb={2}>
                    <GenericTableNew
                      tableName={`Ganancias`}
                      rowsModel={detailGameLog.winBets.filter(
                        (item: any) => item.user == props.match.params.idPlayer
                      )}
                      headCells={[
                        {
                          id: 'betsType',
                          alignment: 'center',
                          label: 'Tipo de Apuesta',
                          type: 'text',
                        },
                        {
                          id: 'id',
                          alignment: 'left',
                          label: 'Id de apuesta',
                          type: 'array',
                        },
                        {
                          id: 'profit',
                          alignment: 'center',
                          label: 'Ganacia',
                          type: 'number',
                        },
                        //{ id: 'actions', alignment: 'center', label: 'Acciones' },
                      ]}
                      serverPagination={true}
                      disabledEmptyRows={true}
                      disabledTableHeader={true}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : null}
      </Grid>
    </PageContainer>
  );
}

export default GameDetailRoulette;
