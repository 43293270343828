import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { Casino } from '../../types/casinos';
import { AuthContext, AuthContextType } from '../../context/authContext';
import { PageContainer } from '../components/PageContainer';
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const SelectField = styled(MuiSelectField)<{ my?: number }>(spacing);
const ControlField = styled(MuiFormControl)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
interface BaccaratTable {
  game: string;
  typeBets: {
    player: { minBet: number; maxBet: number; profit: number };
    banker: { minBet: number; maxBet: number; profit: number };
    tie: { minBet: number; maxBet: number; profit: number };
    ppair: { minBet: number; maxBet: number; profit: number };
    bpair: { minBet: number; maxBet: number; profit: number };
  };
  chips: Array<number>;
  viewers: number;
  isOpen: boolean;
  dealer: string;
  tablePicture: string;
  streamAuthorizationToken: string;
  streamName: string;
  streamAccountId: string;
  gameType: string;
  tableNumber: number;
  version: number;
  createdAt: string;
  updatedAt: string;
  casino?: string;
}
function UpdateBaccaratTable(props: any) {
  const history = useHistory();
  const [opts, setOpts] = React.useState<any>([]);
  const [form, setForm] = React.useState<BaccaratTable>({
    typeBets: {
      player: { minBet: 100, maxBet: 0, profit: 2 },
      banker: { minBet: 0, maxBet: 0, profit: 2 },
      tie: { minBet: 0, maxBet: 1000, profit: 8 },
      ppair: { minBet: 0, maxBet: 1000, profit: 11 },
      bpair: { minBet: 100, maxBet: 1000, profit: 11 },
    },
    chips: [1, 10, 50, 100, 500, 1000],
    game: 'baccarat',
    dealer: 'Jose',
    viewers: 0,
    isOpen: false,
    tablePicture: 'https://demo.lasvegasenvivo.com/TableScreenshots/8888.jpg',
    streamAuthorizationToken: '',
    streamName: 'kdaihneo',
    streamAccountId: 'sanZE3',
    gameType: 'baccarat',
    tableNumber: 2000,
    version: 0,
    createdAt: '2021-02-27T01:17:52.803Z',
    updatedAt: '2021-02-27T01:17:52.803Z',
  });
  const { hasRole } = React.useContext<AuthContextType>(AuthContext);
  const [loading, setLoading] = React.useState<any>(false);
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(
        `table/baccarat/${props.match.params.id}`,
        values
      );
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/table/list');
    } catch (error: any) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let formUpdated: any = await getListByComponent(
        `/admin/table/${props.match.params.id}`
      );
      if (formUpdated.status == 200) {
        formUpdated = formUpdated.data;
        setForm({
          ...formUpdated,
        });
        console.log(form, formUpdated, 'form');
        setLoading(true);
      }
    })();

    return () => {};
  }, []);
  React.useEffect(() => {
    if (hasRole(['superadmin'])) {
      (async () => {
        let gameTableResponse: Array<Casino> = [];
        let { data, status } = await getListByComponent('/admin/casino');
        if (status == 200) {
          gameTableResponse = data;
          setOpts(
            gameTableResponse.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }))
          );
          setForm((form) => ({ ...form, casino: '' }));
        }
      })();
    }
    return () => {};
  }, []);
  return (
    <PageContainer
      headerTitle="Mesas de Juego"
      headerPath="/tables"
      title={'Actualiza Mesa de Juego'}
      path="dealers/create"
    >
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Actualiza una Mesa de Juego
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Utiliza esta herramienta para actualizar una Mesa de Juego
      </Typography>
      {loading ? (
        <Formik
          enableReinitialize
          initialValues={form}
          validationSchema={Yup.object().shape({
            game: Yup.string()
              .max(255)
              .required('Se requiere un nombre de juego'),
            gameType: Yup.string()
              .max(255)
              .required('Se requiere un tipo de juego'),
            tableNumber: Yup.string()
              .max(255)
              .required('Se requiere un numero de mesa'),
            typeBets: Yup.object().shape({
              player: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
                profit: Yup.string()
                  .max(255)
                  .required('Se requiere un nombre de juego'),
              }),
              banker: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
                profit: Yup.string()
                  .max(255)
                  .required('Se requiere un nombre de juego'),
              }),
              tie: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
                profit: Yup.string()
                  .max(255)
                  .required('Se requiere un nombre de juego'),
              }),
              ppair: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
                profit: Yup.string()
                  .max(255)
                  .required('Se requiere un nombre de juego'),
              }),
              bpair: Yup.object().shape({
                minBet: Yup.number()
                  .min(1, 'la apuesta minuma debe sermayor a 1')
                  .integer('Solo números. Debe ser un telefono válido.'),
                maxBet: Yup.number()
                  .min(2, 'la apuesta minuma debe sermayor a 2')
                  .integer('Solo números. Debe ser un telefono válido.'),
                profit: Yup.string()
                  .max(255)
                  .required('Se requiere un nombre de juego'),
              }),
            }),
            dealer: Yup.string().max(255).required('Se requiere un nombre'),
            streamName: Yup.string().max(255).required('Se requiere un nombre'),
            streamAccountId: Yup.string()
              .max(255)
              .required('Se requiere un nombre'),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="game"
                    label="game"
                    value={values.game}
                    error={Boolean(touched.game && errors.game)}
                    fullWidth
                    helperText={touched.game && errors.game}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="dealer"
                    label="Nombre de dealer"
                    value={values.dealer}
                    error={Boolean(touched.dealer && errors.dealer)}
                    fullWidth
                    helperText={touched.dealer && errors.dealer}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="gameType"
                    label="tipo de juego"
                    value={values.gameType}
                    error={Boolean(touched.gameType && errors.gameType)}
                    fullWidth
                    helperText={touched.gameType && errors.gameType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="tableNumber"
                    label="Numero de Mesa"
                    value={values.tableNumber}
                    error={Boolean(touched.tableNumber && errors.tableNumber)}
                    fullWidth
                    helperText={touched.tableNumber && errors.tableNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.player.minBet"
                    label="Apuesta minima player"
                    value={values.typeBets?.player?.minBet}
                    error={Boolean(
                      touched.typeBets?.player?.minBet &&
                        errors.typeBets?.player?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.player?.minBet &&
                      errors.typeBets?.player?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.player.maxBet"
                    label="Apuesta maxima player"
                    value={values.typeBets?.player?.maxBet}
                    error={Boolean(
                      touched.typeBets?.player?.maxBet &&
                        errors.typeBets?.player?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.player?.maxBet &&
                      errors.typeBets?.player?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.player.profit"
                    label="Apuesta profit player"
                    value={values.typeBets?.player?.profit}
                    error={Boolean(
                      touched.typeBets?.player?.profit &&
                        errors.typeBets?.player?.profit
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.player?.profit &&
                      errors.typeBets?.player?.profit
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.banker.minBet"
                    label="Apuesta minima banker"
                    value={values.typeBets?.banker?.minBet}
                    error={Boolean(
                      touched.typeBets?.banker?.minBet &&
                        errors.typeBets?.banker?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.banker?.minBet &&
                      errors.typeBets?.banker?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.banker.maxBet"
                    label="Apuesta maxima banker"
                    value={values.typeBets?.banker?.maxBet}
                    error={Boolean(
                      touched.typeBets?.banker?.maxBet &&
                        errors.typeBets?.banker?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.banker?.maxBet &&
                      errors.typeBets?.banker?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.banker.profit"
                    label="Apuesta profit banker"
                    value={values.typeBets?.banker?.profit}
                    error={Boolean(
                      touched.typeBets?.banker?.profit &&
                        errors.typeBets?.banker?.profit
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.banker?.profit &&
                      errors.typeBets?.banker?.profit
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.tie.minBet"
                    label="Apuesta minima tie"
                    value={values.typeBets?.tie?.minBet}
                    error={Boolean(
                      touched.typeBets?.tie?.minBet &&
                        errors.typeBets?.tie?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.tie?.minBet &&
                      errors.typeBets?.tie?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.tie.maxBet"
                    label="Apuesta maxima tie"
                    value={values.typeBets?.tie?.maxBet}
                    error={Boolean(
                      touched.typeBets?.tie?.maxBet &&
                        errors.typeBets?.tie?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.tie?.maxBet &&
                      errors.typeBets?.tie?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.tie.profit"
                    label="Apuesta profit tie"
                    value={values.typeBets?.tie?.profit}
                    error={Boolean(
                      touched.typeBets?.tie?.profit &&
                        errors.typeBets?.tie?.profit
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.tie?.profit &&
                      errors.typeBets?.tie?.profit
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.bpair.minBet"
                    label="Apuesta minima bpair"
                    value={values.typeBets?.bpair?.minBet}
                    error={Boolean(
                      touched.typeBets?.bpair?.minBet &&
                        errors.typeBets?.bpair?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.bpair?.minBet &&
                      errors.typeBets?.bpair?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.bpair.maxBet"
                    label="Apuesta maxima bpair"
                    value={values.typeBets?.bpair?.maxBet}
                    error={Boolean(
                      touched.typeBets?.bpair?.maxBet &&
                        errors.typeBets?.bpair?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.bpair?.maxBet &&
                      errors.typeBets?.bpair?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.bpair.profit"
                    label="Apuesta profit bpair"
                    value={values.typeBets?.bpair?.profit}
                    error={Boolean(
                      touched.typeBets?.bpair?.profit &&
                        errors.typeBets?.bpair?.profit
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.bpair?.profit &&
                      errors.typeBets?.bpair?.profit
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.ppair.minBet"
                    label="Apuesta minima ppair"
                    value={values.typeBets?.ppair?.minBet}
                    error={Boolean(
                      touched.typeBets?.ppair?.minBet &&
                        errors.typeBets?.ppair?.minBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.ppair?.minBet &&
                      errors.typeBets?.ppair?.minBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.ppair.maxBet"
                    label="Apuesta maxima ppair"
                    value={values.typeBets?.ppair?.maxBet}
                    error={Boolean(
                      touched.typeBets?.ppair?.maxBet &&
                        errors.typeBets?.ppair?.maxBet
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.ppair?.maxBet &&
                      errors.typeBets?.ppair?.maxBet
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="typeBets.ppair.profit"
                    label="Apuesta profit ppair"
                    value={values.typeBets?.ppair?.profit}
                    error={Boolean(
                      touched.typeBets?.ppair?.profit &&
                        errors.typeBets?.ppair?.profit
                    )}
                    fullWidth
                    helperText={
                      touched.typeBets?.ppair?.profit &&
                      errors.typeBets?.ppair?.profit
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="streamName"
                    label="Stream Name"
                    value={values.streamName}
                    error={Boolean(touched.streamName && errors.streamName)}
                    fullWidth
                    helperText={touched.streamName && errors.streamName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="streamAccountId"
                    label="stream Account Id"
                    value={values.streamAccountId}
                    error={Boolean(
                      touched.streamAccountId && errors.streamAccountId
                    )}
                    fullWidth
                    helperText={
                      touched.streamAccountId && errors.streamAccountId
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                {hasRole(['superadmin']) ? (
                  <Grid item md={12}>
                    <ControlField my={3} fullWidth variant="outlined">
                      <InputLabel id={'casino'}>Casino</InputLabel>
                      <SelectField
                        labelId={'casino'}
                        id={'casino'}
                        name={'casino'}
                        value={values.casino}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {opts.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </SelectField>
                      {props.error && (
                        <FormHelperText>
                          Por favor indique una opcion
                        </FormHelperText>
                      )}
                    </ControlField>
                  </Grid>
                ) : null}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Actualizar Mesa
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      ) : null}
    </PageContainer>
  );
}

export default UpdateBaccaratTable;
