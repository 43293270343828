import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory, NavLink } from 'react-router-dom';
import {
  getListByComponent,
  createListByComponent,
  updateListByComponent,
} from '../../services/genericServicesCasino';
import { Box, Button, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
export const WithdrawUpdate: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    amount: {
      type: 'text',
      initialValue: '',
      name: 'amount',
      label: 'Deposito',
      propsInput: {},
    },
    bankAccount: {
      type: 'text',
      initialValue: '',
      name: 'bankAccount',
      label: 'Cuenta Bancaria',
      propsInput: {},
    },
    accountType: {
      type: 'text',
      initialValue: '',
      name: 'accountType',
      label: 'Tipo de Cuenta',
      propsInput: {},
    },
    status: {
      type: 'option',
      initialValue: '',
      name: 'status',
      label: 'Estado del deposito',
      opts: [
        { value: 'FAILED', label: 'FAILED' },
        { value: 'SUCCESS', label: 'SUCCESS' },
        { value: 'PENDING', label: 'PENDING' },
        { value: 'INVALID', label: 'INVALID' },
      ],
      propsInput: {
        disabled: true,
      },
    },
    makePayment: {
      type: 'switch',
      initialValue: '',
      name: 'makePayment',
      label: 'Realizar el pago',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      amount: Yup.string().max(255).required('Se requiere un monto'),
      bankAccount: Yup.string()
        .max(255)
        .required('Se requiere una cuenta bancaria'),
      accountType: Yup.string()
        .max(255)
        .required('Se requiere un tipo de cuenta'),
      status: Yup.string().max(255).required('Se requieren dias de expiracion'),
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(
        `/admin/player/withdraw/${props.match.params.id}`,
        values
      );
      setStatus({ success: true });
      setSubmitting(false);
      history.push(`/players`);
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  const submitPayout = async () => {
    try {
      await createListByComponent(
        `/directa24/payout/${props.match.params.id}`,
        {}
      );
      history.push(`/players`);
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
    }
  };
  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getListByComponent(
        `/admin/player/withdraw/${props.match.params.id}`
      );
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        console.log({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Promociones"
      headerPath="/promotion"
      title={'Actualiza Bono'}
      path="affiliate/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza Retiros'}
          description={'Utiliza esta herramienta para actualizar Retiros'}
          submitLabel={'Actualizar Retiro'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          renderButton={() => (
            <>
              <Box mb={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={submitPayout}
                >
                  Realizar Deposito
                </Button>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Enviar Retiro
              </Button>
            </>
          )}
        />
      ) : null}
    </PageContainer>
  );
};
