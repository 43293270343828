import React from 'react';
import {
  BaccaratBetLimits,
  GameTable,
  initialBaccaratBetLimits,
  Table,
  TableStoreAction,
} from './types';

export const TABLES_INITIAL_STATE: TablesContextType = {
  tables: [],
  tablesLegacy: [],
  tableState: '',
  gameTables: [],
  connectedUsers: 0,
  baccaratBetLimits: initialBaccaratBetLimits,
  dispatch: (value: TableStoreAction) => {},
  updateTablesList: async () => [],
};

export type TablesContextType = {
  tables: Table[];
  tablesLegacy: Table[];
  tableState: string;
  gameTables: GameTable[];
  connectedUsers: number;
  baccaratBetLimits: BaccaratBetLimits;
  dispatch: React.Dispatch<TableStoreAction>;
  updateTablesList(): Promise<GameTable[]>;
};

export const TablesContext = React.createContext<TablesContextType>(
  TABLES_INITIAL_STATE
);

export * from './TablesStore';
export * from './types';
