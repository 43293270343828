import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

const formFields = [
  { id: 'title', name: 'Titulo' },
  { id: 'reference', name: 'Código de Referencia' },
  /*{ id: 'comission', name: 'Comision' },
    { id: 'username', name: 'Nombre de usuario' },
    { id: 'password', name: 'Contraseña' },

    { id: 'firstName', name: 'Nombre' },
    { id: 'lastName', name: 'Apellido' },
    { id: 'email', name: 'Email' },
    { id: 'codeCountry', name: 'Código Teléfono' },
    { id: 'phone', name: 'Teléfono' },
    { id: 'skype', name: 'Skype' },
    { id: 'address', name: 'Dirección' },
    { id: 'city', name: 'Ciudad' },
    { id: 'zipCode', name: 'Código Postal' },
    { id: 'country', name: 'País' },
    { id: 'lang', name: 'Idioma' },
    { id: 'website', name: 'Pagina Web' },
    { id: 'facebook', name: 'Facebook' },
    { id: 'instagram', name: 'Instagram' },
    { id: 'twitter', name: 'Twitter' },
    { id: 'youtube', name: 'Youtube' },
    { id: 'category', name: 'Categoría' },
    { id: 'accountName', name: 'Nombre de Cuenta' },
    { id: 'paymentMethod', name: 'Método de Pago' },
    { id: 'bankName', name: 'Nombre de Banco' },
    { id: 'accountNumber', name: 'Número de Cuenta' },
    { id: 'accountZipCode', name: 'Código Postal de Cuenta' },
    { id: 'ibanCode', name: 'Código IBAN' },
    { id: 'swiftCode', name: 'Código SWIFT' },
    { id: 'accountCountry', name: 'País de Cuenta' },
    { id: 'accountAddress', name: 'Dirección de Cuenta' },
    { id: 'active', name: 'Activo' },*/
];

type DialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (e: any) => void;
  onModelChange: (model: { id: string; value: string }) => void;
  model: { [key: string]: string };
};

const SectionDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  onSubmit,
  onModelChange,
  model,
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Nuevo Influencer</DialogTitle>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            Favor de ingresar la información del influencer. Te recordamos que
            código y título son obligatorios.
          </DialogContentText>

          {formFields.map((currField: any, index: number) => {
            return (
              <TextField
                key={index}
                autoFocus
                margin="dense"
                id={currField.id}
                label={currField.name}
                type="text"
                fullWidth
                value={model['' + currField.id + ''] || ''}
                onChange={(e) =>
                  onModelChange({ id: currField.id, value: e.target.value })
                }
                required={
                  currField.id === 'reference' || currField.id === 'title'
                }
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              console.log('clicked!!!!');
              onClose();
              // setOpen(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            onClick={() => {
              // var currInfluencer: NewInfluencer;
              // currInfluencer.accountAddress =
              // console.log(newInfluencer);
              // setOpen(false);
            }}
            color="primary"
          >
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SectionDialog;
