import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import { string } from 'yup';
export const UpdateGameTable: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({});
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      alias: Yup.string().max(255).required('Se requiere un alias'),
      name: Yup.string().max(255).required('Se requiere un nombre'),
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateListByComponent(
        `/admin/dealer/${props.match.params.id}`,
        values
      );
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/tables/list');
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getListByComponent(
        `/admin/table/${props.match.params.id}`
      );
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(dealerUser).reduce((acc: any, current: string) => {
            if (typeof dealerUser[current] == 'string') {
              acc[current] = {
                type: 'text',
                name: current,
                label: current,
                propsInput: {},
                initialValue: dealerUser[current],
              };
            } else if (typeof dealerUser[current] == 'object') {
              acc = {
                ...acc[current],
                ...Object.keys(dealerUser[current]).reduce(
                  (acc: any, current: string) => {
                    if (typeof dealerUser[current] == 'string') {
                      acc[current] = {
                        type: 'text',
                        name: current,
                        label: current,
                        propsInput: {},
                        initialValue: dealerUser[current],
                      };
                    }
                    return acc;
                  },
                  {}
                ),
              };
            }
            return acc;
          }, {}),
        });
        console.log(
          {
            ...Object.keys(dealerUser).reduce((acc: any, current: string) => {
              if (typeof dealerUser[current] == 'string') {
                acc[current] = {
                  type: 'text',
                  name: current,
                  label: current,
                  propsInput: {},
                  initialValue: dealerUser[current],
                };
              } else if (typeof dealerUser[current] == 'object') {
                acc = {
                  ...acc[current],
                  ...Object.keys(dealerUser[current]).reduce(
                    (acc: any, current: string) => {
                      if (typeof dealerUser[current] == 'string') {
                        acc[current] = {
                          type: 'text',
                          name: current,
                          label: current,
                          propsInput: {},
                          initialValue: dealerUser[current],
                        };
                      } else if (typeof dealerUser[current] == 'object') {
                        acc = {
                          ...acc[current],
                          ...Object.keys(dealerUser[current]).reduce(
                            (acc: any, current: string) => {
                              if (typeof dealerUser[current] == 'string') {
                                acc[current] = {
                                  type: 'text',
                                  name: current,
                                  label: current,
                                  propsInput: {},
                                  initialValue: dealerUser[current],
                                };
                              } else if (
                                typeof dealerUser[current] == 'object'
                              ) {
                                acc = {
                                  ...acc[current],
                                };
                              }
                              return acc;
                            },
                            {}
                          ),
                        };
                      }
                      return acc;
                    },
                    {}
                  ),
                };
              }
              return acc;
            }, {}),
          },
          'form'
        );
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Mesas de Juego"
      headerPath="/tables"
      title={'Actualiza Mesa de juego'}
      path="table/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza Mesa de juego'}
          description={'Utiliza esta herramienta para registrar nuevos dealers'}
          submitLabel={'Actualizar Usuario'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
