//*START IMPORTING MODULES*//
import React, { useState } from 'react';
import {
  Paper as MuiPaper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Chip as MuiChip,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import styled, { withTheme, ThemeProps } from 'styled-components/macro';
import { spacing, SpacingProps } from '@material-ui/system';
import { green, orange, red, blue } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';
//*END IMPORTING MODULES*//

//*START STYLES TO ALL TABLE ELEMENTS*//
const Paper = styled(MuiPaper)(spacing);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      textAlign: 'center',
      padding: '2px',
    },
  })
)(TableCell);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
});

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;
//*FINISH STYLES TO ALL TABLE ELEMENTS*//

//*START OF EnhancedTableToolbarPropsType FRAGMENT*//
type EnhancedTableToolbarPropsType = {
  tableName: string;
  onChangeSearch?(searched: string, page: number): void;
  setPageTable?(pagina: number): void;
  setPagesVisted?(): void;
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarPropsType) => {
  // Here was 'let'
  const { tableName } = props;
  const { onChangeSearch = (searched: string, page: number) => {} } = props;
  const { setPageTable = (pagina: number) => {} } = props;
  const { setPagesVisted = () => {} } = props;
  const [searched, setSearched] = useState<string>('');

  //Search functions
  const cancelSearch = () => {
    setSearched('');
    setPagesVisted();
    requestSearch(searched);
  };

  const requestSearch = (searchedVal: string) => {
    setPageTable(0);
    setPagesVisted();
    onChangeSearch(searchedVal, 0);
  };

  return (
    <React.Fragment>
      <Toolbar>
        <ToolbarTitle>
          <Typography variant="h6" id="tableTitle">
            {tableName}
          </Typography>
        </ToolbarTitle>
        <SearchBar
          style={{ backgroundColor: 'black', height: 45 }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Toolbar>
    </React.Fragment>
  );
};
//*END OF EnhancedTableToolbarPropsType FRAGMENT*//

//*START OF EnhancedTableHeadPropsType FRAGMENT*//
type EnhancedTableHeadPropsType = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
};
const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <StyledTableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
//*END OF EnhancedTableHeadPropsType FRAGMENT*//

interface ChipPropstype extends SpacingProps {
  shipped?: number;
  processing?: number;
  cancelled?: number;
}

const Chip = styled(MuiChip)<ChipPropstype>`
  ${spacing};
  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  minWidth?: number;
  type:
    | 'text'
    | 'image'
    | 'money'
    | 'number'
    | 'videoGameLog'
    | 'subtable'
    | 'date'
    | 'chip'
    | 'actions'
    | 'array';
  disablePadding?: boolean;
};

type RowType = {
  [key: string]: string | number | boolean | (RowType[] | undefined);
  id: string;
  tables?: RowType[];
};

interface GenericParams {
  tableName: string;
  headCells: HeadCell[];
  rowsModel: RowType[];
  count: number;
  rowPerPageOptions: number[];
  rowsPerPageCurrent: number;
  actionsCells?(rowNumber: string | number | Array<any>): JSX.Element;
  onChangeRowsPerPage?(rowsPerPage: number, page: number): void;
  onChangePage?(page: number): void;
  onChangeSearch?(searched: string, page: number): void;
  onChangeSort?(orderBy: string, order: string, page: number): void;
}

export const GenericServerTable: React.FC<GenericParams> = ({
  tableName,
  headCells,
  rowsModel,
  count,
  rowPerPageOptions = [5, 10, 25],
  rowsPerPageCurrent,
  onChangeRowsPerPage = (rowsPerPage: number, page: number) => {},
  onChangePage = (page: number) => {},
  onChangeSearch = (searched: string, page: number) => {},
  onChangeSort = (orderBy: string, order: string, page: number) => {},
  actionsCells = (id: string | number | Array<any>) => null,
}): JSX.Element => {
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [pagesVisted, setPagesVisted] = React.useState<any>([0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageCurrent);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const classes = useStyles();
  const theme = useTheme();
  console.log(pagesVisted);

  const handleChangePage = (event: unknown, newPage: number) => {
    console.log('page ' + page);
    console.log('newPage ' + newPage);
    setPage(newPage);
    if (!pagesVisted.includes(newPage)) {
      setPagesVisted([...pagesVisted, newPage]);
      onChangePage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPagesVisted([0]);
    onChangeRowsPerPage(parseInt(event.target.value, 10), 0);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    console.log('handleRequestSort: ' + property);
    setPage(0);
    setPagesVisted([0]);
    onChangeSort(property, isAsc ? 'desc' : 'asc', 0);
  };

  const cellToRender = (
    typeOfCell: string,
    value?: string | number | boolean | Array<string>
  ) => {
    if (typeof value === 'undefined') return;
    switch (typeOfCell) {
      case 'text':
        return (
          <TableCell align="left">
            <Typography variant="subtitle1">{value}</Typography>
          </TableCell>
        );
      case 'money':
        return (
          <TableCell
            style={{
              color: value < 0 ? red[900] : theme.palette.secondary.main,
            }}
            align="center"
          >
            <Typography variant="h6">
              {formatNumbers(
                typeof value === 'number'
                  ? value
                  : typeof value === 'string'
                  ? parseFloat(value)
                  : 0
              )}
            </Typography>
          </TableCell>
        );
      case 'chip':
        return (
          <TableCell align="left">
            {value <= 0 ? (
              <Chip
                color="primary"
                size="small"
                mr={1}
                mb={1}
                label="No"
                cancelled={-1}
              />
            ) : (
              <Chip
                color="primary"
                size="small"
                mr={1}
                mb={1}
                label="Si"
                shipped={1}
              />
            )}
          </TableCell>
        );
      case 'actions':
        return (
          <TableCell align="left">
            {typeof value === 'string' ||
            typeof value === 'number' ||
            Array.isArray(value)
              ? actionsCells(value)
              : ''}
          </TableCell>
        );
    }
  };

  function formatNumbers(number: number) {
    let isNegative = number < 0 ? '-' : '';
    let numberAbs = Math.abs(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return isNegative + '$' + numberAbs;
  }

  const valueIsNotArray = (
    value: string | number | boolean | RowType[] | undefined
  ): value is string | number | boolean => {
    return (
      typeof (value as string | number | boolean | Array<any>) !== 'undefined'
    );
  };

  const renderTablesRows = (rowData: RowType) => {
    return (
      <>
        {headCells.map((columnCell) =>
          cellToRender(
            columnCell.type,
            valueIsNotArray(rowData[columnCell.id])
              ? (rowData[columnCell.id] as string | number | boolean)
              : ''
          )
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={6}
        style={{
          marginBottom: 0,
        }}
      >
        <Grid item xs={12}>
          <Paper>
            <EnhancedTableToolbar
              tableName={tableName}
              onChangeSearch={onChangeSearch}
              setPageTable={(page: number) => setPage(page)}
              setPagesVisted={() => setPagesVisted([0])}
            />
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size={'small'}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={count}
                  headCells={headCells}
                />
                <TableBody>
                  {rowsModel
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: RowType, index: number) => {
                      return (
                        <React.Fragment>
                          <TableRow key={row.id} style={{ height: 33 }}>
                            {renderTablesRows(row)}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
