import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled, { withTheme } from 'styled-components/macro';
import { NavLink, RouteComponentProps, useParams } from 'react-router-dom';
import {
  EnhancedTable,
  HeadCell,
  GenericTableProps,
  RowType,
} from '../../components/GenericTable';

import {
  getListByComponent,
  updateListByComponent,
} from '../../services/genericServices';
import GenericTableNew from '../../components/GenericTableNew';
import { Helmet } from 'react-helmet-async';
import '../../vendor/roundedBarCharts';
import { Bar } from 'react-chartjs-2';
import { getVideo } from '../../services/tablesService';
import { red, green, blue } from '@material-ui/core/colors';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Pageview as PageviewIcon,
} from '@material-ui/icons';
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { spacing, SpacingProps } from '@material-ui/system';
import DoneIcon from '@material-ui/icons/Done';

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  Twitter,
  PlusSquare,
  MinusSquare,
  Calendar,
  AlertTriangle,
} from 'react-feather';
import {
  GameType,
  PlayerDeposit,
  PlayersContext,
  PlayersContextType,
  PlayerWithdraw,
} from '../../context/playersContext';
import moment from 'moment';
import RouletteDetail from './RouletteDetail';
import BlackjackDetail from './BlackjackDetail';
import BaccaratDetail from './BaccaratDetail';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

interface TypographyPropsType extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)<{ rgbcolor?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const StyleChip = styled(Chip)`
  border-radius: 16px;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

const Details: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Detalle del Jugador
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Avatar alt={name} src="/static/img/avatars/avatar-1.jpg" />
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightMedium">{name}</Box>
            {/* <Box fontWeight="fontWeightRegular">Lead Developer</Box> */}
          </Typography>

          <Button mr={2} variant="contained" size="small">
            Follow
          </Button>
          <Button mr={2} variant="contained" color="primary" size="small">
            Message
          </Button>
        </Centered>
      </CardContent>
    </Card>
  );
};

const GamesPlayed: React.FC<{
  games: string[];
  selectedGame: string;
  onClick(game: string): void;
}> = ({ games, selectedGame, onClick }): JSX.Element => {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Juegos Jugados
        </Typography>

        <Spacer mb={4} />

        <Centered>
          {games.map((game: string, i: number) => {
            return (
              <StyleChip
                key={i.toString()}
                mr={1}
                mb={1}
                label={
                  <div style={{ textTransform: 'capitalize' }}>{game}</div>
                }
                color={selectedGame === game ? 'primary' : 'default'}
                onClick={() => {
                  if (selectedGame !== game) onClick(game);
                }}
                onDelete={() => {
                  if (selectedGame !== game) onClick(game);
                }}
                deleteIcon={selectedGame === game ? <DoneIcon /> : <></>}
              />
            );
          })}
        </Centered>
      </CardContent>
    </Card>
  );
};

const About: React.FC<{
  city: string;
  country: string;
  lastUpdate: string;
  registrationDate: string;
  isTestUser: boolean;
}> = (props): JSX.Element => {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          About
        </Typography>

        <Spacer mb={4} />

        {props.isTestUser && (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <AlertTriangle color={'red'} />
              </AboutIcon>
            </Grid>
            <Grid item>Es usuario de prueba</Grid>
          </Grid>
        )}
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Home />
            </AboutIcon>
          </Grid>
          <Grid item>
            Reside en{' '}
            <Link href="https://material-app.bootlab.io/">
              {props.city}, {props.country}
            </Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Calendar />
            </AboutIcon>
          </Grid>
          <Grid item>
            Miembro desde: {moment(props.registrationDate).format('l')}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <AboutIcon>
              <DollarSign />
            </AboutIcon>
          </Grid>
          <Grid item>
            Última actualización de balance: {props.lastUpdate || 'desconocido'}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// function Elsewhere() {
//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Elsewhere
//         </Typography>

//         <Spacer mb={4} />

//         <Grid container direction="row" alignItems="center" mb={2}>
//           <Grid item>
//             <AboutIcon>
//               <ExternalLink />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://material-app.bootlab.io/">lucylavender.io</Link>
//           </Grid>
//         </Grid>
//         <Grid container direction="row" alignItems="center" mb={2}>
//           <Grid item>
//             <AboutIcon>
//               <Twitter />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://material-app.bootlab.io/">Twitter</Link>
//           </Grid>
//         </Grid>
//         <Grid container direction="row" alignItems="center" mb={2}>
//           <Grid item>
//             <AboutIcon>
//               <Facebook />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://material-app.bootlab.io/">Facebook</Link>
//           </Grid>
//         </Grid>
//         <Grid container direction="row" alignItems="center">
//           <Grid item>
//             <AboutIcon>
//               <Instagram />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://material-app.bootlab.io/">Instagram</Link>
//           </Grid>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// }

const Deposits: React.FC<{ amount: number; playerId: string }> = ({
  amount,
  playerId,
}): JSX.Element => {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ {amount}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total en depósitos
          </Typography>
          <StatsIcon>
            <IconButton
              aria-label="details"
              component={NavLink}
              to={`/player/deposits/${playerId}`}
            >
              <PageviewIcon />
            </IconButton>
          </StatsIcon>
          {/*<StatsIcon>
              <PlusSquare />
            </StatsIcon>*/}
          <LinearProgress
            variant="determinate"
            value={75}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const Withdrawals: React.FC<{ amount: number; playerId: string }> = ({
  amount,
  playerId,
}): JSX.Element => {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ {amount}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total en retiros
          </Typography>
          <StatsIcon>
            <IconButton
              aria-label="details"
              component={NavLink}
              to={`/player/withdraw/${playerId}`}
            >
              <PageviewIcon />
            </IconButton>
          </StatsIcon>
          {/*<StatsIcon>
            <MinusSquare />
          </StatsIcon>*/}
          <LinearProgress
            variant="determinate"
            value={30}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const Bonus: React.FC<{ amount: number; playerId: string }> = ({
  amount,
  playerId,
}): JSX.Element => {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ {amount}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total en bonus
          </Typography>
          <StatsIcon>
            <IconButton
              aria-label="details"
              component={NavLink}
              to={`/player/promotion/${playerId}`}
            >
              <PageviewIcon />
            </IconButton>
          </StatsIcon>
          {/*<StatsIcon>
            <MinusSquare />
          </StatsIcon>*/}
          <LinearProgress
            variant="determinate"
            value={30}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
const Balance: React.FC<{ amount: number }> = ({ amount }): JSX.Element => {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ {amount}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Balance del jugador
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={50}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const Gamelog = <T extends RowType>({
  rowsModel,
  tableName,
  headCells,
  tableCells,
}: PropsWithChildren<GenericTableProps<T>>) => {
  return (
    <Card mb={6}>
      <CardContent>
        <EnhancedTable
          rowsModel={rowsModel}
          tableName={tableName}
          headCells={headCells}
          tableCells={tableCells}
        />
      </CardContent>
    </Card>
  );
};

// const SalesRevenue = withTheme(({ theme }) => {
//   const data = {
//     labels: [
//       'Jan',
//       'Feb',
//       'Mar',
//       'Apr',
//       'May',
//       'Jun',
//       'Jul',
//       'Aug',
//       'Sep',
//       'Oct',
//       'Nov',
//       'Dec',
//     ],
//     datasets: [
//       {
//         label: 'Sales',
//         backgroundColor: theme.palette.secondary.main,
//         borderColor: theme.palette.secondary.main,
//         hoverBackgroundColor: theme.palette.secondary.main,
//         hoverBorderColor: theme.palette.secondary.main,
//         data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
//         barPercentage: 0.625,
//         categoryPercentage: 0.5,
//       },
//       {
//         label: 'Revenue',
//         backgroundColor: theme.palette.grey[200],
//         borderColor: theme.palette.grey[200],
//         hoverBackgroundColor: theme.palette.grey[200],
//         hoverBorderColor: theme.palette.grey[200],
//         data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
//         barPercentage: 0.625,
//         categoryPercentage: 0.5,
//       },
//     ],
//   };

//   const options = {
//     maintainAspectRatio: false,
//     cornerRadius: 2,
//     legend: {
//       display: false,
//     },
//     scales: {
//       yAxes: [
//         {
//           gridLines: {
//             display: false,
//           },
//           stacked: false,
//           ticks: {
//             stepSize: 20,
//           },
//         },
//       ],
//       xAxes: [
//         {
//           stacked: false,
//           gridLines: {
//             color: 'transparent',
//           },
//         },
//       ],
//     },
//   };

//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Sales / Revenue
//         </Typography>

//         <Spacer mb={6} />

//         <ChartWrapper>
//           <Bar data={data} options={options} />
//         </ChartWrapper>
//       </CardContent>
//     </Card>
//   );
// });

interface RouteParams {
  playerId: string;
}

export const PlayerProfile: React.FC<
  RouteComponentProps<RouteParams>
> = (): JSX.Element => {
  const {
    setProfileData,
    player,
    playerDeposits,
    playerWithdrawals,
    playerGamelog,
    getPlayerGamelogTable,
  } = useContext<PlayersContextType>(PlayersContext);
  const [watchVideo, setWatchVideo] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [detailGameLog, setDetailGameLog] = useState<any>();
  const [videoUrl, setVideoUrl] = useState<string>('');
  const { playerId } = useParams<RouteParams>();
  const [selectedGame, setSelectedGame] = useState<GameType>(GameType.none);
  const [userInteracted, setUserInteracted] = useState(false);

  const [loading, setLoading] = React.useState<any>(false);
  const showVideoHandler = async (id: string) => {
    let videoResponse = await getVideo(id + '.mp4');
    setVideoUrl(videoResponse.data);
    setWatchVideo(true);
  };
  useEffect(() => {
    (async () => {
      await setProfileData(playerId);
    })();

    return () => {};
  }, []);
  const getGameDetail = async (game: string, id: string) => {
    let formUpdated: any = await getListByComponent(
      `/admin/gamelog/detail/${game}/${id}/${playerId}`
    );
    if (formUpdated.status == 200) {
      formUpdated = formUpdated.data;

      setDetailGameLog({
        ...formUpdated,
      });
      console.log(detailGameLog, formUpdated, 'form');
      setLoading(true);
    }
  };
  const SwitchViews = () => {
    switch (selectedGame) {
      case 'roulette':
        return (
          <RouletteDetail detailGameLog={detailGameLog} playerId={playerId} />
        );
      case 'blackjack':
        return (
          <BlackjackDetail detailGameLog={detailGameLog} playerId={playerId} />
        );

      case 'baccarat':
        return (
          <BaccaratDetail detailGameLog={detailGameLog} playerId={playerId} />
        );
      default:
        return <></>;
    }
  };
  useEffect(() => {
    if (!selectedGame && !userInteracted)
      setSelectedGame(player.games_played[0]);
  }, [selectedGame, player]);
  return (
    <React.Fragment>
      <Helmet title="Perfil del Jugador" />

      <Typography variant="h3" gutterBottom display="inline">
        Perfil del Jugador
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Perfil del Jugador</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <Details name={`${player.name} ${player.lastname}`} />
          <GamesPlayed
            games={player.games_played}
            selectedGame={selectedGame}
            onClick={async (game: GameType) => {
              setLoading(false);
              setUserInteracted(true);
              setSelectedGame(game);
              await getPlayerGamelogTable(playerId, game);
            }}
          />
          <About
            city={player.city}
            country={player.country}
            lastUpdate={player.balance_last_update}
            registrationDate={player.register_date}
            isTestUser={!!player.test_user}
          />
          {/* <Elsewhere /> */}
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          {/* <SalesRevenue /> */}
          <Grid container spacing={6}>
            <Grid item xs={12} lg={3}>
              <Deposits
                playerId={playerId}
                amount={playerDeposits.reduce(
                  (acc: number, deposit: PlayerDeposit): number => {
                    return acc + parseFloat(deposit.amount);
                  },
                  0
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Withdrawals
                playerId={playerId}
                amount={playerWithdrawals.reduce(
                  (acc: number, deposit: PlayerWithdraw): number => {
                    return acc + parseFloat(deposit.amount);
                  },
                  0
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Bonus playerId={playerId} amount={player.credits} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Balance amount={parseFloat(player.balance)} />
            </Grid>
          </Grid>
          <Gamelog
            tableName={'Historial de jugadas'}
            rowsModel={playerGamelog}
            headCells={[
              {
                id: 'date',
                alignment: 'center',
                label: 'Fecha de juego',
              },
              //{ id: 'id', alignment: 'center', label: 'ID de partida' },
              {
                id: 'game',
                alignment: 'center',
                label: 'Tipo de juego',
              },
              //{ id: 'id', alignment: 'center', label: 'ID de mesa' },
              {
                id: 'bet',
                alignment: 'center',
                label: 'Apuesta',
              },
              {
                id: 'profit',
                alignment: 'center',
                label: 'Ganancias',
              },
              {
                id: 'id',
                alignment: 'center',
                label: 'Video de la partida',
              },
              {
                id: 'id',
                alignment: 'center',
                label: 'Acciones',
              },
            ]}
            tableCells={(row: number) => {
              return (
                <>
                  <TableCell align="center">
                    {moment(playerGamelog[row].date).format(
                      'YYYY/MM/DD HH:mm:ss'
                    )}{' '}
                  </TableCell>
                  {/*<TableCell align="center">
                    # {playerGamelog[row].id}
                  </TableCell>*/}
                  <TableCell align="center">
                    {playerGamelog[row].game}
                  </TableCell>
                  {/* <TableCell align="center">
                      {playerGamelog[row].tableId}
                    </TableCell>*/}
                  <TableCell align="center">{playerGamelog[row].bet}</TableCell>
                  <TableCell align="center">
                    {playerGamelog[row].profit}
                  </TableCell>
                  <TableCell align="center">
                    <video
                      style={{
                        width: '300px',
                      }}
                      controls
                    >
                      <source
                        src={
                          'https://lasvegasenvivo-videos.s3.amazonaws.com/' +
                          playerGamelog[row].id +
                          '.mp4'
                        }
                        type="video/mp4"
                      />
                    </video>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="details"
                      onClick={() => {
                        getGameDetail(
                          playerGamelog[row].game,
                          playerGamelog[row].id
                        );
                        setShowModal(true);
                      }}
                    >
                      <PageviewIcon />
                    </IconButton>
                  </TableCell>
                </>
              );
            }}
          />
        </Grid>
        <Grid>
          <Dialog
            maxWidth="xl"
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>{loading ? SwitchViews() : null}</DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
