import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import App from './App';
import { AuthStore } from './context/authContext';
import { TablesStore } from './context/tablesContext';
import { UsersStore } from './context/usersContext';
import { PlayersStore } from './context/playersContext';
import { DealersStore } from './context/dealersContext';

import { Provider } from 'react-redux';
import store from './redux/store/index';
import { TransactionsStore } from './context/transactionsContext';

ReactDOM.render(
  <Provider store={store}>
    <PlayersStore>
      <TransactionsStore>
        <DealersStore>
          <UsersStore>
            <TablesStore>
              <AuthStore>
                <App />
              </AuthStore>
            </TablesStore>
          </UsersStore>
        </DealersStore>
      </TransactionsStore>
    </PlayersStore>
  </Provider>,
  document.getElementById('root')
);
