import React, { useReducer } from 'react';
import {
  TransactionsContext,
  TransactionsContextType,
  TRANSACTIONS_INITIAL_STATE,
} from '.';
import { AnalyticsPeriod } from '../../pages/dashboards/Analytics/types';
import {
  getDeposits,
  getDepositsMonth,
  getDepositsToday,
  getDepositsYear,
  getPendingWithdrawals,
  getWithdrawals,
  getDashboardAnalyticsData,
} from '../../services/transactionsServices';
import {
  Deposit,
  DepositRecord,
  TransactionStoreAction,
  Withdraw,
} from './types';

type TablesProps = {
  children: React.ReactNode;
};

const reducer: React.Reducer<
  TransactionsContextType,
  TransactionStoreAction
> = (
  state: TransactionsContextType,
  action: TransactionStoreAction
): TransactionsContextType => {
  switch (action.type) {
    case 'deposits':
      return { ...state, deposits: action.deposits };
    case 'withdrawals':
      return { ...state, withdrawals: action.withdrawals };
    case 'dashboardTransactionsData':
      return { ...state, ...action.data };
    case 'dashboardAnalytics':
      return {
        ...state,
        activePlayers: action.payload.activePlayers,
        balance: action.payload.balance,
        balanceData: action.payload.lineChart.balanceData,
        balanceLabels: action.payload.lineChart.balanceLabels,
        newPlayers: action.payload.newPlayers,
        playerEffectiveness: action.payload.playerEffectiveness,
        trafficData: action.payload.lineChart.trafficData,
        trafficLabels: action.payload.lineChart.trafficLabels,
        balancePercentage: action.payload.balancePercentage,
        playerEffectivenessPercentage:
          action.payload.playerEffectivenessPercentage,
        activePlayersPercentage: action.payload.activePlayersPercentage,
        newPlayersPercentage: action.payload.newPlayersPercentage,
        tables: action.payload.tables,
      };
    default:
      throw new Error();
  }
};

export const TransactionsStore: React.FC<TablesProps> = ({
  children,
}): JSX.Element => {
  const [context, dispatch] = useReducer(reducer, TRANSACTIONS_INITIAL_STATE);

  const updateDepositsList = async (): Promise<Deposit[]> => {
    let deposits: Deposit[] = [];
    try {
      const { data, error } = await getDeposits();
      if (!error) {
        deposits = data.map((deposit: any) => {
          return { ...deposit, id: deposit.id_deposit.toString() };
        });
        dispatch({ type: 'deposits', deposits });
      }
    } catch (error) {}

    return deposits;
  };

  const updateWithdrawalsList = async (): Promise<Withdraw[]> => {
    let withdrawals: Withdraw[] = [];
    try {
      const { data, error } = await getWithdrawals();
      if (!error) {
        withdrawals = data.map((withdraw: any) => {
          return { ...withdraw, id: withdraw.id_withdraw.toString() };
        });
        dispatch({ type: 'withdrawals', withdrawals });
      }
    } catch (error) {}

    return withdrawals;
  };

  const getDashboardTansactionsData = async (): Promise<void> => {
    let dashboardData = {
      depositsToday: 0,
      depositsMonthly: 0,
      depositsAnnual: 0,
      pendingWithdrawals: 0,
    };

    try {
      const today = await getDepositsToday();
      const monthly = await getDepositsMonth();
      const annual = await getDepositsYear();
      const pending = await getPendingWithdrawals();

      if (!today.error) {
        dashboardData = {
          ...dashboardData,
          depositsToday: today.data.reduce(
            (acc: number, item: DepositRecord) => {
              return acc + parseFloat(item.amount || '0');
            },
            0
          ),
        };
      }
      if (!monthly.error) {
        dashboardData = {
          ...dashboardData,
          depositsMonthly: monthly.data.reduce(
            (acc: number, item: DepositRecord) => {
              return acc + parseFloat(item.amount || '0');
            },
            0
          ),
        };
      }
      if (!annual.error) {
        dashboardData = {
          ...dashboardData,
          depositsAnnual: annual.data.reduce(
            (acc: number, item: DepositRecord) => {
              return acc + parseFloat(item.amount || '0');
            },
            0
          ),
        };
      }
      dashboardData = {
        ...dashboardData,
        pendingWithdrawals: pending.data.pendingWithdraw || 0,
      };
      dispatch({ type: 'dashboardTransactionsData', data: dashboardData });
    } catch (error) {}
  };

  const updateDashboardAnalyticsData = async (
    period: AnalyticsPeriod
  ): Promise<void> => {
    try {
      const analytics = await getDashboardAnalyticsData(period);
      if (!analytics.error && analytics.data !== null) {
        dispatch({ type: 'dashboardAnalytics', payload: analytics.data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TransactionsContext.Provider
      value={{
        ...context,
        dispatch,
        updateDepositsList,
        updateWithdrawalsList,
        getDashboardTansactionsData,
        updateDashboardAnalyticsData,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};
