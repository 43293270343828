import * as React from 'react';
import styled from 'styled-components/macro';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from '@material-ui/core';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;
type optsType = {
  value: string;
  label: string;
};
type DropdownPropsType = {
  onClickHandler: (value: string) => void;
  opts: optsType[];
  defaultValue: string;
};
const CurrencyDropdown: React.FC<DropdownPropsType> = ({
  onClickHandler,
  opts,
  defaultValue,
}): JSX.Element => {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    console.log(event.currentTarget);
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = (value: string) => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Languages">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <div> {'$' + defaultValue}</div>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {opts.map(({ value, label }) => (
          <MenuItem
            onClick={() => {
              onClickHandler(value);
              setAnchorMenu(null);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default CurrencyDropdown;
