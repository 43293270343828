import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { NewInfluencer, newInfluencer } from '../../../services/usersServices';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { green, orange, red } from '@material-ui/core/colors';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Pageview as PageviewIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';

import { spacing, SpacingProps } from '@material-ui/system';

import { getListByComponent } from '../../../services/genericServices';
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

interface ChipPropstype extends SpacingProps {
  shipped?: number;
  processing?: number;
  cancelled?: number;
}

const Chip = styled(MuiChip)<ChipPropstype>`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

type RowType = {
  [key: string]: string | number | boolean;
  id: string;
  alias: string;
  is_active: number;
  modified_date: string;
  name: string;
  register_date: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'desc' | 'asc', orderBy: string) {
  return order === 'desc'
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label: string;
  disablePadding?: boolean;
};

const headCells: Array<HeadCell> = [
  {
    id: 'title',
    alignment: 'center',
    label: 'Titulo',
  },
  {
    id: 'reference',
    alignment: 'center',
    label: 'Codigo',
  },
  {
    id: 'referrals',
    alignment: 'center',
    label: 'Jugadores',
  },
  {
    id: 'balance',
    alignment: 'center',
    label: 'balance',
  },

  {
    id: 'shareableUrl',
    alignment: 'center',
    label: 'Url',
  },

  {
    id: 'actions',
    alignment: 'center',
    label: 'Acciones',
  },
  //{ id: 'actions', alignment: 'center', label: 'Acciones' },
];

type EnhancedTableHeadPropsType = {
  numSelected: number;
  order: 'desc' | 'asc';
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarPropsType = { numSelected: number };
const EnhancedTableToolbar = (props: EnhancedTableToolbarPropsType) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Paginas
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

const EnhancedTable: React.FC<{
  rows: RowType[];
  page: number;
  rowsPerPage: number;
  handleChangeRowsPerPage: any;
  handleChangePage: any;
}> = ({
  rows,
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
}): JSX.Element => {
  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');
  const [orderBy, setOrderBy] = React.useState('customer');
  const [selected, setSelected] = React.useState<Array<string>>([]);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = rows.map((n: RowType) =>
        n._id.toString()
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: RowType, index: number) => {
                  const isItemSelected = isSelected(row._id.toString());
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row._id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.reference}</TableCell>

                      <TableCell align="center">{row.referrals}</TableCell>
                      <TableCell align="center">{row.balance}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              typeof row.shareableUrl == 'string'
                                ? row.shareableUrl
                                : ''
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/affiliate/${row._id}`}
                        >
                          <PageviewIcon />
                        </IconButton>
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/affiliate/update/${row._id}`}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export const Pages: React.FC<any> = (props): JSX.Element => {
  const [gameTable, setGameTable] = React.useState<any>([]);
  const [enabledPage, setEnabledPage] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const ref = React.useRef(null);
  // const [isOpen, setOpen] = React.useState(false);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (!enabledPage) {
      setPage(newPage);
    }
  };
  const paginationPerPage = async (rowsPerPage: number, page: number) => {
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/admin/affiliate?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([
          ...gameTable,
          ...gameTableResponse.slice(0, rowsPerPage),
        ]);
      }
    }
    return gameTableResponse.length;
  };
  const paginationPerRows = async (rowsPerPage: number, page: number) => {
    let gameTableResponse = null;
    gameTableResponse = await getListByComponent(
      `/admin/affiliate?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([...gameTableResponse.slice(0, rowsPerPage)]);
      }
    }
    return gameTableResponse.length;
  };
  useEffect(() => {
    (async () => {
      let gameTableResponse = null;
      gameTableResponse = await getListByComponent(
        // `/admin/affiliate?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
        `/admin/affiliate/table/page`
      );
      if (gameTableResponse.status == 200) {
        gameTableResponse = gameTableResponse.data;
        if (gameTableResponse.length > 0) {
          if (gameTableResponse.length <= rowsPerPage) {
            setEnabledPage(true);
          }
          setGameTable([...gameTable, ...gameTableResponse]);
        }
      }
    })();

    return () => {};
  }, [page, rowsPerPage]);

  const [open, setOpen] = React.useState(false);

  const formFields = [
    { id: 'title', name: 'Titulo' },
    { id: 'reference', name: 'Código de Referencia' },
    /*{ id: 'comission', name: 'Comision' },
    { id: 'username', name: 'Nombre de usuario' },
    { id: 'password', name: 'Contraseña' },

    { id: 'firstName', name: 'Nombre' },
    { id: 'lastName', name: 'Apellido' },
    { id: 'email', name: 'Email' },
    { id: 'codeCountry', name: 'Código Teléfono' },
    { id: 'phone', name: 'Teléfono' },
    { id: 'skype', name: 'Skype' },
    { id: 'address', name: 'Dirección' },
    { id: 'city', name: 'Ciudad' },
    { id: 'zipCode', name: 'Código Postal' },
    { id: 'country', name: 'País' },
    { id: 'lang', name: 'Idioma' },
    { id: 'website', name: 'Pagina Web' },
    { id: 'facebook', name: 'Facebook' },
    { id: 'instagram', name: 'Instagram' },
    { id: 'twitter', name: 'Twitter' },
    { id: 'youtube', name: 'Youtube' },
    { id: 'category', name: 'Categoría' },
    { id: 'accountName', name: 'Nombre de Cuenta' },
    { id: 'paymentMethod', name: 'Método de Pago' },
    { id: 'bankName', name: 'Nombre de Banco' },
    { id: 'accountNumber', name: 'Número de Cuenta' },
    { id: 'accountZipCode', name: 'Código Postal de Cuenta' },
    { id: 'ibanCode', name: 'Código IBAN' },
    { id: 'swiftCode', name: 'Código SWIFT' },
    { id: 'accountCountry', name: 'País de Cuenta' },
    { id: 'accountAddress', name: 'Dirección de Cuenta' },
    { id: 'active', name: 'Activo' },*/
  ];

  /*const submitNew = async (rowsPerPage: number, page: number) => {
    

    gameTableResponse = await getListByComponent(
      `/admin/affiliate?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
    );
    if (gameTableResponse.status == 200) {
      gameTableResponse = gameTableResponse.data;
      if (gameTableResponse.length > 0) {
        setGameTable([
          ...gameTable,
          ...gameTableResponse.slice(0, rowsPerPage),
        ]);
      }
    }
    return gameTableResponse.length;
  };*/

  const [influencer, setNewInfluencer] = React.useState<any>({});

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (influencer.title && influencer.reference) {
      console.log('SUBMITTING RECORD!');

      let { status } = await newInfluencer(influencer);
      if (status == 200) {
        let gameTableResponse = await getListByComponent(
          // `/admin/affiliate?skip=${page * rowsPerPage}&limit=${rowsPerPage + 1}`
          `/admin/affiliate/table/page`
        );
        if (gameTableResponse.status == 200) {
          setOpen(false);
          setGameTable([...gameTableResponse.data]);
        }
      }
      console.log('DONE SENDING INFLUENCER!');
    } else {
      console.log('REQUIRED DATA NOT ENTERED');
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Players" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Paginas
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Paginas</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Button
            mr={2}
            variant="contained"
            color="primary"
            ref={ref}
            onClick={() => setOpen(true)}
          >
            <AddIcon />
            Agregar
          </Button>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Nuevo Influencer</DialogTitle>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  Favor de ingresar la información del influencer. Te recordamos
                  que código y título son obligatorios.
                </DialogContentText>

                {formFields.map((currField: any, index: number) => {
                  return (
                    <TextField
                      key={index}
                      autoFocus
                      margin="dense"
                      id={currField.id}
                      label={currField.name}
                      type="text"
                      fullWidth
                      value={influencer['' + currField.id + ''] || ''}
                      onChange={(e) => {
                        setNewInfluencer((prevState: any) => ({
                          ...prevState,
                          [currField.id]: e.target.value,
                        }));
                      }}
                      required={
                        currField.id === 'reference' || currField.id === 'title'
                      }
                    />
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    console.log('clicked!!!!');
                    setOpen(false);
                  }}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    // var currInfluencer: NewInfluencer;
                    // currInfluencer.accountAddress =

                    console.log(newInfluencer);

                    // setOpen(false);
                  }}
                  color="primary"
                >
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable
            rows={gameTable}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
