import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import { dashboardLayoutRoutes, authLayoutRoutes } from './index';
import history from '../utils/index';
import { AuthContext, AuthContextType } from '../context/authContext';
import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import Page404 from '../pages/auth/Page404';
import { RouteType } from '../types/routes';
import Dashboard from '../layouts/Dashboard';

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) => {
  return routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      const Guard = guard || React.Fragment;
      const { role } = React.useContext<AuthContextType>(AuthContext);
      return children ? (
        children.map((element, index: number) => {
          const Guard = element.guard || React.Fragment;
          const ElementComponent = element.component || React.Fragment;
          const requiresAuth = element.meta.requiresAuth || false;
          const roles = element.meta.allowedRoles;
          if (requiresAuth) {
            if (!roles.some((roleAllowed: string) => roleAllowed === role)) {
              return null;
            }
          }

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  {requiresAuth ? (
                    <Guard roles={roles}>
                      <ElementComponent {...props} />
                    </Guard>
                  ) : (
                    <Guard>
                      <ElementComponent {...props} />
                    </Guard>
                  )}
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );
};
// history.tsx
// import {createBrowserHistory} from 'history';

// export default createBrowserHistory({ basename: process.env.PUBLIC_URL });

const Routes = (props: any) => {
  const authContext = React.useContext<AuthContextType>(AuthContext);
  React.useEffect(() => {
    (async () => {
      let user = await authContext.fetchUser();
      if (user) {
        history.push('/dashboard');
      } else {
        history.push('/auth/sign-in');
      }
      //   await refreshToken();
      //   setShowLoading(false);
    })();
  }, []);
  return (
    <Router>
      <Switch>
        <Redirect exact path="/" to="/dashboard" />
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {/*childRoutes(DashboardLayout, protectedRoutes)*/}
        {/*childRoutes(PresentationLayout, presentationLayoutRoutes)*/}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
