import React, { useReducer } from 'react';
import { DealersContext, DealersContextType, DEALERS_INITIAL_STATE } from '.';
import { getDealers, getDealersByTable } from '../../services/dealersServices';
import { Dealer, DealerAnalytics, DealerStoreAction } from './types';

type TablesProps = {
  children: React.ReactNode;
};

const reducer: React.Reducer<DealersContextType, DealerStoreAction> = (
  state: DealersContextType,
  action: DealerStoreAction
): DealersContextType => {
  switch (action.type) {
    case 'dealers':
      return { ...state, dealers: action.dealers };
    case 'dealersAnalytics':
      return { ...state, dealerAnalyticsList: action.dealersAnalyticsList };
    default:
      throw new Error();
  }
};

export const DealersStore: React.FC<TablesProps> = ({
  children,
}): JSX.Element => {
  const [context, dispatch] = useReducer(reducer, DEALERS_INITIAL_STATE);

  const updateDealersList = async (): Promise<Dealer[]> => {
    let dealers: Dealer[] = [];
    try {
      const { data, error } = await getDealers();
      if (!error) {
        dealers = data.map((dealer: any) => {
          return { ...dealer };
        });
        dispatch({
          type: 'dealers',
          dealers,
        });
      }
    } catch (error) {}

    return dealers;
  };
  const getDealersAnalytics = async (
    id: string[]
  ): Promise<DealerAnalytics[]> => {
    let dealersAnalyticsList: DealerAnalytics[] = [];
    try {
      const { data, error } = await getDealersByTable(id);
      if (!error) {
        dealersAnalyticsList = data;
        dispatch({
          type: 'dealersAnalytics',
          dealersAnalyticsList,
        });
      }
    } catch (error) {}

    return dealersAnalyticsList;
  };

  return (
    <DealersContext.Provider
      value={{
        ...context,
        dispatch,
        updateDealersList,
        getDealersAnalytics,
      }}
    >
      {children}
    </DealersContext.Provider>
  );
};
