import React, { useContext, useState } from 'react';
import CreateForm from '../../components/CreateForm';
import { PageContainer } from '../components/PageContainer';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { getAdminUserById, updateUserById } from '../../services/usersServices';
export const UpdateUser: React.FC<any> = (props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<any>(false);
  const [form, setForm] = useState<any>({
    username: {
      type: 'text',
      initialValue: '',
      name: 'alias',
      label: 'Alias',
      propsInput: {},
    },
    name: {
      type: 'text',
      initialValue: '',
      name: 'name',
      label: 'Nombre',
      propsInput: {},
    },
    role: {
      type: 'option',
      opts: [
        { value: 'admin', label: 'administrador' },
        { value: 'mlro', label: 'Operador' },
        { value: 'finanzas', label: 'Analista' },
      ],
      initialValue: '',
      name: 'role',
      label: 'Rol',
      propsInput: {},
    },
    email: {
      type: 'text',
      initialValue: '',
      name: 'email',
      label: 'Correo',
      propsInput: {},
    },
    phone: {
      type: 'text',
      initialValue: '',
      name: 'phone',
      label: 'Celular',
      propsInput: {},
    },
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      username: Yup.string()
        .max(255)
        .required('Se requiere un nombre de usuario'),
      name: Yup.string().max(255).required('Se requiere un nombre'),
      role: Yup.string()
        .oneOf(['admin', 'mlro', 'finanzas'])
        .required('Por favor indique un rol para el usuario'),
      email: Yup.string()
        .email('Debe ser email válido')
        .max(255)
        .required('Un email es requerido'),
      phone: Yup.number()
        .min(10, 'Teléfono debe tener al menos 10 digitos')
        .integer('Solo números. Debe ser un telefono válido.')
        .required('Un telefono es requerido'),
      /*
      password: Yup.string()
        .min(10, 'Contraseña debe tener al menos 10 caracteres')
        .max(50)
        .required('Una contraseña es requirda'),
      confirmPassword: Yup.string().when('password', {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Ambas contraseñas deben coincidir'
        ),
      }),
      */
    })
  );
  const onSubmit = async (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      await updateUserById(props.match.params.id, values);
      setStatus({ success: true });
      setSubmitting(false);
      history.push('/user/list');
    } catch (error) {
      const message = error.message || 'Algo salió mal. Intente nuevamente.';
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      let dealerUser: any = await getAdminUserById(props.match.params.id);
      if (dealerUser.status == 200) {
        dealerUser = dealerUser.data;
        setForm({
          ...Object.keys(form).reduce((acc: any, current: string) => {
            acc[current] = {
              ...form[current],
              initialValue: dealerUser[current],
            };
            return acc;
          }, {}),
        });
        setLoading(true);
      }
    })();

    return () => {};
  }, []);

  return (
    <PageContainer
      headerTitle="Usuarios"
      headerPath="/users"
      title={'Actualiza Usuario'}
      path="users/update/:id"
    >
      {loading ? (
        <CreateForm
          header={'Actualiza usuario'}
          description={'Utiliza esta herramienta para registrar nuevos usuario'}
          submitLabel={'Actualizar Usuario'}
          form={form}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      ) : null}
    </PageContainer>
  );
};
