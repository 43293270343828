import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { signUp } from '../../redux/actions/authActions';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl as MuiFormControl,
  Grid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import { newUser } from '../../services/usersServices';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const SelectField = styled(MuiSelectField)<{ my?: number }>(spacing);
const ControlField = styled(MuiFormControl)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Wrapper>
      <Helmet title="Sign Up" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Registra un nuevo usuario
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Utiliza esta herramienta para registrar nuevos usuarios
      </Typography>

      <Formik
        initialValues={{
          username: '',
          name: '',
          role: '',
          email: '',
          phone: '',
          password: '',
          confirmPassword: '',
          submit: false,
          success: false,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .max(255)
            .required('Se requiere un nombre de usuario'),
          name: Yup.string().max(255).required('Se requiere un nombre'),
          role: Yup.string()
            .oneOf(['admin', 'mlro', 'finanzas'])
            .required('Por favor indique un rol para el usuario'),
          email: Yup.string()
            .email('Debe ser email válido')
            .max(255)
            .required('Un email es requerido'),
          phone: Yup.number()
            .min(10, 'Teléfono debe tener al menos 10 digitos')
            .integer('Solo números. Debe ser un telefono válido.')
            .required('Un telefono es requerido'),
          password: Yup.string()
            .min(10, 'Contraseña debe tener al menos 10 caracteres')
            .max(50)
            .required('Una contraseña es requirda'),
          confirmPassword: Yup.string().when('password', {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              'Ambas contraseñas deben coincidir'
            ),
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setSubmitting(true);
            await newUser({
              username: values.username,
              name: values.name,
              role: values.role,
              email: values.email,
              phone: values.phone,
              password: values.password,
            });
            setStatus({ success: true });
            setSubmitting(false);
          } catch (error) {
            const message =
              error.message || 'Algo salió mal. Intente nuevamente.';
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {values.success && (
              <Alert mt={2} mb={1} severity="success">
                El usuario fue registrado con éxito!
              </Alert>
            )}
            <Grid container spacing={6}>
              <Grid item md={12}>
                <ControlField
                  my={3}
                  fullWidth
                  error={Boolean(touched.role && errors.role)}
                  variant="outlined"
                >
                  <InputLabel id="role-select-field">
                    Tipo de usuario
                  </InputLabel>
                  <SelectField
                    labelId="role-select-field"
                    id="role-select"
                    name="role"
                    value={values.role}
                    fullWidth
                    error={Boolean(touched.role && errors.role)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={'admin'}>Administrador</MenuItem>
                    <MenuItem value={'mlro'}>Operador</MenuItem>
                    <MenuItem value={'finanzas'}>Analista</MenuItem>
                  </SelectField>
                  {touched.role && errors.role && (
                    <FormHelperText>
                      Por favor indique un rol para el usuario
                    </FormHelperText>
                  )}
                </ControlField>
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  name="username"
                  label="Nombre de usuario"
                  value={values.username}
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  name="phone"
                  label="Telefono"
                  value={values.phone}
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  type="password"
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Registar Usuario
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default SignUp;
