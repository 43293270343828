import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import SectionActionButton from './SectionActionButton';
import SectionDialog from './SectionDialog';
import {
  useAffiliates,
  useCreateAffiliate,
} from '../../../hooks/useAffiliates';
import SectionDataGrid from './SectionDataGrid';

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Affiliates: React.FC<any> = (props): JSX.Element => {
  // GETTING CURRENT AFFILIATES
  const affiliatesQuery = useAffiliates();

  // CREATE NEW AFFILIATE
  const createAffiliate = useCreateAffiliate();

  // DIALOG OPEN STATE
  const [open, setOpen] = React.useState(false);
  const [affiliate, setNewAffiliate] = React.useState<any>({});

  const handleModelChange = ({ id, value }: { id: string; value: string }) => {
    setNewAffiliate((prevState: any) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    await createAffiliate.mutateAsync(affiliate);
    if (!createAffiliate.isSuccess) {
      setOpen(false);
      setNewAffiliate({});
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Players" />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Afiliados
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Afiliados</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <SectionActionButton onClick={() => setOpen(true)} />
          <SectionDialog
            open={open}
            onClose={() => setOpen(false)}
            onSubmit={handleSubmit}
            onModelChange={handleModelChange}
            model={affiliate}
          />
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <SectionDataGrid rows={affiliatesQuery.data || []} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
