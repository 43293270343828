import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { Formik, FormikTouched, FormikErrors } from 'formik';

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelectField,
  FormControl as MuiFormControl,
  Grid,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert as MuiAlert } from '@material-ui/lab';
import SwitchInputs from './SwitchInputs';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

type CreateFormPropsType = {
  header: string;
  description: string;
  submitLabel: string;
  form: any;
  validationSchema: Object;
  renderButton?(): JSX.Element;
  onSubmit: (
    values: object,
    { setErrors, setStatus, setSubmitting }: any
  ) => void;
};

const CreateForm: React.FC<CreateFormPropsType> = (props) => {
  const [initialValues, setInitialValues] = useState({
    ...Object.keys(props.form).reduce(
      (previousValue: any, currentValue: string) => {
        previousValue[currentValue] = props.form[currentValue].initialValue;
        return previousValue;
      },
      {}
    ),
  });
  useEffect(() => {
    setInitialValues({
      ...Object.keys(props.form).reduce(
        (previousValue: any, currentValue: string) => {
          previousValue[currentValue] = props.form[currentValue].initialValue;

          return previousValue;
        },
        {}
      ),
    });

    console.log('casinoId', props.form, initialValues);
  }, [props.form]);
  return (
    <Wrapper>
      <Helmet title="Sign Up" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {props.header}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        {props.description}
      </Typography>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
        }) => {
          const tempValues: { [key: string]: string } = values as {
            [key: string]: string;
          };
          const tempTouched: FormikTouched<{
            [key: string]: string;
          }> = touched as FormikTouched<{ [key: string]: string }>;
          const tempErrors: FormikErrors<{
            [key: string]: string;
          }> = errors as FormikErrors<{
            [key: string]: string;
          }>;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                {Object.keys(values).map((key: string, index) => (
                  <Grid item md={6} xs={12} key={key}>
                    {
                      <SwitchInputs
                        variant="outlined"
                        type={props.form[key].type}
                        name={props.form[key].name}
                        label={props.form[key].label}
                        value={tempValues[key]}
                        setFieldValue={setFieldValue}
                        error={Boolean(tempTouched[key] && tempErrors[key])}
                        fullWidth
                        helperText={tempTouched[key] && tempErrors[key]}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                        {...props.form[key].propsInput}
                        opts={
                          props.form[key].opts ? props.form[key].opts : null
                        }
                      />
                    }
                  </Grid>
                ))}
                <Grid item xs={12}>
                  {props.renderButton ? (
                    props.renderButton()
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {props.submitLabel}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default CreateForm;
